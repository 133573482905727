import ClayTable from "@clayui/table";
import { DEFAULT_PAGINATION } from "@constants";
import React, { Fragment, useEffect, useState } from "react";

import TablePagination from "../TablePagination/TablePagination";
import { EmptyTable } from "../empty-table/EmptyTable";
import { TableBody } from "./table-body/TableBody";
import { TableHeader } from "./table-header/TableHeader";

const Table = (props: any) => {
  //props
  const {
    definitions,
    dataSource,
    pagination,
    sort,
    showPagination,
    resetPageOnSourceChange = true,
    uniqueKeyProp,
    handleCheckboxChange,
    filterValue,
    hideFilter,
    entityLink = "",
    entityId = "",
    rowClassName,
  } = props;
  const forwardProps = { ...props };
  const [dataTypeDef, setDataType] = useState<{ [key: string]: any }>({});

  delete forwardProps.definitions;
  delete forwardProps.dataSource;
  delete forwardProps.showPagination;
  delete forwardProps.sort;
  delete forwardProps.pagination;
  delete forwardProps.handleSort;
  delete forwardProps.resetPageOnSourceChange;
  delete forwardProps.uniqueKeyProp;
  delete forwardProps.handleCheckboxChange;
  delete forwardProps.showCheckbox;
  delete forwardProps.filterValue;
  delete forwardProps.onActivePageChange;
  delete forwardProps.handleFilter;
  delete forwardProps.hideFilter;
  delete forwardProps.entityLink;
  delete forwardProps.entityId;
  delete forwardProps.rowClassName;

  //state
  const [sortInfo, setSortInfo] = useState({
    column: "",
    order: "",
  });

  const [paginationInfo, setPaginationInfo] = useState(pagination || DEFAULT_PAGINATION);

  //this set is used to store sorted data
  const [resultSet, setResultSet] = useState([]);
  //this set holds the display record
  const [displaySet, setDisplaySet] = useState([]);

  //dateCompare
  const dateCompare = (val1: string, val2: string) => {
    if (val1 == null) {
      val1 = "1970-01-01";
    }
    if (val2 == null) {
      val2 = "1970-01-01";
    }
    const date1 = Date.parse(val1);
    const date2 = Date.parse(val2);
    return date1 - date2;
  };

  useEffect(() => {
    const obj: any = {};
    definitions.forEach((data: any) => {
      obj[data["field"]] = data["dataType"] === undefined ? "" : data["dataType"];
    });
    setDataType(obj);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //initial load, get data source and set the sets
  useEffect(() => {
    if (props.onActivePageChange) {
      setResultSet(dataSource);
      pageSortedResultSet(sortInfo, paginationInfo, dataSource);
      return;
    }

    let newPaginationInfo = null;

    //pagination enabled, and dataSource changed, set page to first
    if (showPagination) {
      const oldPageInfo = paginationInfo;
      //view doesn't want to reset page on data re-render
      if (!resetPageOnSourceChange && oldPageInfo && oldPageInfo.activeDelta) {
        //re-render
        //check if the new dataSet and Old Dataset changed based on length,
        const resetCurrentPage =
          dataSource && resultSet && dataSource.length === resultSet.length ? false : true;
        newPaginationInfo = {
          activeDelta: oldPageInfo.activeDelta,
          activePage: resetCurrentPage ? 1 : oldPageInfo.activePage,
          ellipsisBuffer: oldPageInfo.ellipsisBuffer,
          totalItems: dataSource ? dataSource.length : 0,
        };
      } else {
        //first render
        newPaginationInfo = {
          activeDelta: 20,
          activePage: 1,
          ellipsisBuffer: 2,
          totalItems: dataSource ? dataSource.length : 0,
        };
      }
      setPaginationInfo(newPaginationInfo);
    }

    setResultSet(dataSource);
    pageSortedResultSet(sortInfo, newPaginationInfo, dataSource);
  }, [dataSource]); // eslint-disable-line react-hooks/exhaustive-deps

  //if the pagination is handled from parent, but display is part of table
  useEffect(() => {
    if (pagination) {
      setPaginationInfo(pagination);
    }
  }, [pagination]);

  //if the sorting is handled from parent, but display is part of table
  useEffect(() => {
    if (sort) {
      setSortInfo(sort);
    }
  }, [sort]);

  //handlers
  const handleSort = (newSortInfo: any) => {
    setSortInfo(newSortInfo);
    if (props.handleSort) {
      props.handleSort(newSortInfo);
    } else {
      pageSortedResultSet(newSortInfo, null, null);
    }
  };

  //To Handle Page number change
  const handlePageChange = (activePage: any) => {
    const newPageInfo = { ...paginationInfo, activePage };
    setPaginationInfo(newPageInfo);
    if (props.onActivePageChange) {
      props.onActivePageChange(activePage, newPageInfo.activeDelta);
    } else {
      pageSortedResultSet(null, newPageInfo, null);
    }
  };

  //TO Handle Active Delta change
  const handleDeltaChange = (activeDelta: number) => {
    const newPageInfo = { ...paginationInfo, activeDelta };
    setPaginationInfo(newPageInfo);
    if (props.onActivePageChange) {
      props.onActivePageChange(newPageInfo.activePage, activeDelta);
    } else {
      pageSortedResultSet(null, newPageInfo, null);
    }
  };

  const pageSortedResultSet = (newSortInfo: any, newPageInfo: any, dataSource: any) => {
    let sortedResult = [] as any;

    //validate if sorting is needed
    if (!props.handleSort && newSortInfo && newSortInfo.column) {
      sortedResult = getSortedData(dataSource || resultSet, newSortInfo);
      setResultSet(sortedResult);
    } else {
      sortedResult = dataSource || resultSet;
    }

    //if pagination is enabled, lets perform it
    if (showPagination) {
      setDisplaySet(getPagedData(sortedResult, newPageInfo || paginationInfo));
    } else {
      setDisplaySet(sortedResult);
    }
  };

  //perform sorting
  const getSortedData = (resultSet: any, sortObject: any) => {
    let sortedList = [];
    if (dataTypeDef[sortObject.column] === "date") {
      if (sortObject.order === "asc") {
        sortedList = [...resultSet].sort((a, b) => {
          return dateCompare(a[sortObject.column], b[sortObject.column]);
        });
      } else if (sortObject.order === "desc") {
        sortedList = [...resultSet].sort((a, b) => {
          return dateCompare(b[sortObject.column], a[sortObject.column]);
        });
      }
    } else {
      if (sortObject.order === "asc") {
        sortedList = [...resultSet].sort((a, b) =>
          (a[sortObject.column] + "").localeCompare(b[sortObject.column] + ""),
        );
      } else if (sortObject.order === "desc") {
        sortedList = [...resultSet].sort((a, b) =>
          (b[sortObject.column] + "").localeCompare(a[sortObject.column] + ""),
        );
      }
    }
    return sortedList;
  };

  //perform pagination
  const getPagedData = (resultSet: any, pageObject: any) => {
    let result = resultSet;
    if (!props.onActivePageChange && pageObject.activeDelta) {
      result = resultSet.slice(
        pageObject.activeDelta * (pageObject.activePage - 1),
        pageObject.activeDelta * pageObject.activePage,
      );
    }
    return result;
  };

  const handleFilter = (filterState: any) => {
    if (props.handleFilter) {
      props.handleFilter(filterState);
    }
  };

  return (
    <Fragment>
      <ClayTable
        className={"scrt-table " + (!resultSet.length ? "scrt-empty-table" : "")}
        {...forwardProps}
      >
        <TableHeader
          info={definitions}
          sortInfo={sortInfo}
          onHandleSort={handleSort}
          onFilterChange={handleFilter}
          filterValue={filterValue}
          hideFilter={hideFilter}
        />

        {resultSet.length ? (
          <TableBody
            rowClassName={rowClassName}
            uniqueKeyProp={uniqueKeyProp}
            info={definitions}
            resultSet={displaySet}
            currentPage={paginationInfo ? paginationInfo.activePage : "x-"}
            handleCheckboxChange={handleCheckboxChange}
            entityLink={entityLink || ""}
            entityId={entityId || ""}
          />
        ) : (
          <ClayTable.Body>
            <EmptyTable />
          </ClayTable.Body>
        )}
      </ClayTable>

      {showPagination && (
        <TablePagination
          pagination={paginationInfo}
          onActivePageChange={handlePageChange}
          onActiveDeltaChange={handleDeltaChange}
        />
      )}
    </Fragment>
  );
};

export { Table };
