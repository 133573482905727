import ClayCard from "@clayui/card";
import ClayLayout from "@clayui/layout";
import { FaqsList, NavTabs, PageTitle } from "@common-components";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import { ROUTE_PATHS } from "@constants";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Documentation from "./Documentation";
import "./Help.scss";
import { FAQ_LIST, HELP_TABS, HELP_TABS_LIST, MESSAGES_LIST, PAGE_TITLES } from "./constants";

function Help(props: any) {
  const pageTitle = `Help`;
  const navTabs = HELP_TABS_LIST;
  const urlParam = new URLSearchParams(props.location.search);
  const envVars = useSelector(getEnvVars);
  const [currentTab, setCurrentTab] = useState(HELP_TABS.faqs);
  const isOktaEnabled = envVars.DISABLE_OKTA !== "true";
  const isValidUser = localStorage.getItem("okta-valid-user") || "";

  useEffect(() => {
    if (isOktaEnabled && isValidUser !== "true") {
      window.location.assign(ROUTE_PATHS.OKTA_REDIRECT);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getTabComponent = () => {
    switch (currentTab) {
      case HELP_TABS.faqs:
        return (
          <FaqsList
            questions={FAQ_LIST(envVars)}
            customAccordionOpen={Number(urlParam.get("tab") || "")}
            showHeader={true}
            headerMessage={PAGE_TITLES["faqs"]}
            className="accordion-summary"
          />
        );
      case HELP_TABS.messages:
        return (
          <FaqsList
            questions={MESSAGES_LIST(envVars)}
            customAccordionOpen={""}
            showHeader={true}
            headerMessage={PAGE_TITLES["messages"]}
            className="accordion-summary"
          />
        );
      case HELP_TABS.documentation:
        return <Documentation />;
    }
  };

  const handleTabChanged = (navPath: any) => {
    setCurrentTab(navPath);
  };

  return (
    <React.Fragment>
      <ClayLayout.ContainerFluid view size={false}>
        <ClayLayout.Row justify="start" className="align-items-center">
          <ClayLayout.Col className="d-flex align-items-center">
            <PageTitle title={pageTitle} />
          </ClayLayout.Col>
        </ClayLayout.Row>
        <ClayLayout.Row>
          <ClayCard className="m-3 w-100">
            <ClayCard.Body className="p-4">
              <ClayLayout.Row className={"mt-4"}>
                <ClayLayout.Col size={6}>
                  <NavTabs
                    tabList={navTabs}
                    disableNav="true"
                    defaultTab={currentTab}
                    tabChanged={handleTabChanged}
                  />
                </ClayLayout.Col>
              </ClayLayout.Row>
              <ClayLayout.Row className={"mt-4"}>
                <ClayLayout.Col>{getTabComponent()}</ClayLayout.Col>
              </ClayLayout.Row>
            </ClayCard.Body>
          </ClayCard>
        </ClayLayout.Row>
      </ClayLayout.ContainerFluid>
    </React.Fragment>
  );
}

export default Help;
