import ClayForm, { ClaySelectWithOption } from "@clayui/form";
import { ErrorMessage, Field } from "formik";
import React from "react";

import TextError from "./TextError";

function Select(props: any) {
  const { label, name, options, validate, onChange = null, required, size = "sm" } = props;
  return (
    <div>
      {label && (
        <label htmlFor={name}>
          {required && <span className="text-red">* </span>}
          {label}
        </label>
      )}
      <Field name={name} validate={validate}>
        {({ form, field }: { form: any; field: any }) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <ClayForm.Group
              className={`form-group-${size} ${
                form.errors[name] && form.touched[name] !== undefined ? "has-error" : ""
              }`}
            >
              <ClaySelectWithOption
                aria-label={label}
                id={name}
                value={value}
                options={options}
                onChange={(e) => {
                  setFieldValue(name, e.target.value);
                  if (onChange) {
                    onChange(e);
                  }
                }}
                {...props}
              />
              <ErrorMessage name={name} component={TextError} />
            </ClayForm.Group>
          );
        }}
      </Field>
    </div>
  );
}

export default Select;
