import { ClayPaginationBarWithBasicItems } from "@clayui/pagination-bar";
import { claySpriteImagePath } from "@common-services/util/claySpriteImagePath";
import { DEFAULT_DELTA, DELTAS } from "@constants";
import React from "react";

function TablePagination(props: any) {
  const paginationParams = props.pagination;

  const setActivePage = function (page: any) {
    props.onActivePageChange(page);
  };

  //TO remove Additional Pagination options if total < size
  let deltas = [...DELTAS];
  if (paginationParams.totalItems > 0) {
    let prevDelta = DEFAULT_DELTA;
    deltas = [...DELTAS].filter((delta, idx) => {
      prevDelta = idx > 0 ? DELTAS[idx - 1].label : delta.label;
      return delta.label === DEFAULT_DELTA || prevDelta < paginationParams.totalItems;
    });
  }

  const onDeltaChange = (delta: number) => {
    props.onActiveDeltaChange(delta);
  };

  if (paginationParams.totalItems > 0) {
    if (props.position === "top") {
      return (
        <div className="mb-3 pagination-top">
          <span>{`Showing ${
            (paginationParams.activePage - 1) * paginationParams.activeDelta + 1
          } - ${paginationParams.activePage * paginationParams.activeDelta} of ${
            paginationParams.totalItems
          }`}</span>
        </div>
      );
    } else {
      return (
        <ClayPaginationBarWithBasicItems
          className={props.elementAttrs ? props.elementAttrs.className : "pt-4"}
          activeDelta={paginationParams.activeDelta}
          onDeltaChange={onDeltaChange}
          showDeltasDropDown={true}
          deltas={deltas}
          activePage={paginationParams.activePage}
          ellipsisBuffer={paginationParams.ellipsisBuffer}
          onPageChange={setActivePage}
          spritemap={claySpriteImagePath()}
          totalItems={paginationParams.totalItems}
          labels={{
            paginationResults: "{0} to {1} of {2} records",
            perPageItems: "{0} ",
            selectPerPageItems: "{0} ",
          }}
        />
      );
    }
  } else {
    return null;
  }
}

export default TablePagination;
