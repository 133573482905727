import ClayCard from "@clayui/card";
import ClayLayout from "@clayui/layout";
import React from "react";

function OktaRedirectFormError(props: any) {
  const {requestType = "SA" } = props;
  const pageTitle = <div> Access denied. This website is dedicated for customer access only.<br/> For SCRT customer data, contact the Business Intelligence team by logging an issue </div>;
  const hereLink = "https://docs.google.com/forms/d/e/1FAIpQLSdpCZfyMJqYuvnxiBypfG-3RRFm0dRQCuCUm7EjeCOKGunupw/viewform";
  return (
    <React.Fragment>
      <ClayLayout.ContainerFluid view size={false}>
            <ClayLayout.Row>
              <ClayCard className="m-3 w-100">
                {requestType === "SA" && (
                  <ClayLayout.Row justify="start" className="align-items-center">
                    <ClayLayout.Col className="d-flex justify-content-center align-items-center text-center pt-4">
                  <p className="scrt-page-title-dafault mb-0">{pageTitle} <a href={hereLink} target="_blank" rel="noreferrer">here</a>. Thank you.</p>    
                </ClayLayout.Col>
                  </ClayLayout.Row>
            )}
            <ClayCard.Body className="px-4">
              <div></div>
            </ClayCard.Body>
              </ClayCard>
            </ClayLayout.Row>
      </ClayLayout.ContainerFluid>
    </React.Fragment>
  );
}

export default OktaRedirectFormError;
