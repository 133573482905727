import ClayForm from "@clayui/form";
import { ErrorMessage, Field } from "formik";
import React, { useRef } from "react";
import { Typeahead } from "react-bootstrap-typeahead";

import TextError from "./TextError";

function AutoComplete(props: any) {
  const { label, name, options, placeholder, required, size } = props;
  const formSize = size ? size : "sm";
  const typeaheadRef: any = useRef(null);

  return (
    <div>
      {label && (
        <label htmlFor={name}>
          {required && <span className="text-red">* </span>}
          {label}
        </label>
      )}
      <Field name={name}>
        {({ form, field }: { form: any; field: any }) => {
          return (
            <ClayForm.Group
              className={`form-group-${formSize} ${
                form.errors[name] && form.touched[name] !== undefined ? "has-error" : ""
              }`}
            >
              <Typeahead
                id={name}
                labelKey="label"
                align="justify"
                options={options}
                placeholder={placeholder}
                ref={typeaheadRef}
                {...props}
              />
              <ErrorMessage name={name} component={TextError} />
            </ClayForm.Group>
          );
        }}
      </Field>
    </div>
  );
}

export default AutoComplete;
