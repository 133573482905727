import ClayButton from "@clayui/button";
import ClayCard from "@clayui/card";
import ClayLayout from "@clayui/layout";
import {
  DropdownFilter,
  FaqsList,
  IconButton,
  PageTitle,
  Table,
  TableFilterCheckbox,
} from "@common-components";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import { DownloadComponent } from "@components/ActionComponent/DownloadComponent";
import TableCellRenderer from "@components/cellRenderer/TableCellRenderer";
import TableCheckBox from "@components/checkbox/TableCheckbox";
import { ResetFilter } from "@components/resetFilter/ResetFilter";
import {
  AUDIT_IBM_OPTIONS,
  DEFAULT_DELTA,
  DONWLOAD_SOURCE,
  ROLE_SUPER_ADMIN,
  ROUTE_PATHS,
} from "@constants";
import { IRepositoryDetails, ISignedinUser } from "@interfaces";
import {
  closeDelteConfirmModal,
  deleteReport,
  getDeleteConfirmReportId,
  getDeleteMessage,
  getPreviewDetails,
  getRepoFilterState,
  getRepoPageState,
  getRepoSortState,
  getReportId,
  getRepositoryData,
  handlePaginationState,
  loadPreviewDetails,
  loadRepositoryDetails,
  resetFilters,
  resetPreview,
  resetRepositoryState,
} from "@store/repositoryDetails/slice";
import { getSignedInUser } from "@store/userDetails/slice";
import { GET_USER_ROLE, isAdmin, isSuperAdmin } from "@utils";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { ActionComponent } from "../../ActionComponent/ActionComponent";
import { Preview } from "../../preview/Preview";
import { DeleteModal } from "./DeleteModal";
import UploadReport from "./UploadReport";
import {
  AUDIT_PAGETITLE,
  AUDIT_QUESTIONS,
  AUDIT_REPOSITORY,
  DROPDOWN_OPTIONS,
  REPOSITORY,
  REPOSITORY_PAGETITLE,
  REPOSITORY_QUESTIONS,
  SCRT_AUDIT,
  SOURCE_OBJ,
  WEBSITE,
} from "./constants";
import "./repository.scss";

function Repository(props: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [downloadReportIdObj, setDownloadReportIdObj] = useState<any>({});
  const [allCheckBoxSelected, setAllCheckBoxSelected] = useState(false);

  const sort = useSelector(getRepoSortState);
  const filterValue = useSelector(getRepoFilterState);
  const paginationInfo = useSelector(getRepoPageState);
  const [data, setData] = useState<IRepositoryDetails[]>([]);
  const reportId = useSelector(getReportId);
  const previewDetails = useSelector(getPreviewDetails);
  const deleteMessageAlert = useSelector(getDeleteMessage);
  const deleteModalReportId = useSelector(getDeleteConfirmReportId);

  const envVars = useSelector(getEnvVars);
  const isOktaEnabled = envVars.DISABLE_OKTA !== "true";
  const isValidUser = localStorage.getItem("okta-valid-user") || "";
  const rowData = useSelector(getRepositoryData);
  const uploadReportRef = useRef<any>("");

  //To fetch signedIn User Details
  const signedInUser: ISignedinUser = useSelector(getSignedInUser);
  const signedInUserName = signedInUser?.name || "";

  const location = useLocation();
  const isAudit = location.pathname === ROUTE_PATHS.AUDIT;

  //Check if Valid user
  useEffect(() => {
    if (isOktaEnabled && isValidUser !== "true") {
      window.location.assign(ROUTE_PATHS.OKTA_REDIRECT);
    }
    //If Route is not Super Admin Redirect to Repository
    if (location.pathname === ROUTE_PATHS.AUDIT && GET_USER_ROLE() !== ROLE_SUPER_ADMIN) {
      window.localStorage.assign(ROUTE_PATHS.REPOSITORY);
    }
    //Resetting State For Repo Details
    return () => {
      dispatch(resetRepositoryState());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let count = 0;
    const newData: IRepositoryDetails[] =
      (rowData &&
        rowData.content &&
        rowData.content.length &&
        rowData.content.map((row: IRepositoryDetails) => {
          !!downloadReportIdObj[row.reportId] && (count += 1);
          return {
            ...row,
            isChecked: !!downloadReportIdObj[row.reportId],
          };
        })) ||
      [];

    // dispatch(repositoryListReceived(newData));
    setData(newData);
    setIsSelectAll(newData.length > 0 && newData.length === count);
    const newPageInfo = {
      activeDelta: rowData?.size || DEFAULT_DELTA,
      activePage: rowData?.number + 1 || 1,
      ellipsisBuffer: 5,
      totalItems: rowData?.totalElements || 0,
    };
    dispatch(handlePaginationState(newPageInfo));
  }, [rowData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reportId && previewDetails) {
      setIsDrawerOpen(true);
    }
  }, [previewDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleteMessageAlert) {
      getRepoDetails();
    }
  }, [deleteMessageAlert]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUploadAlertClose = () => {
    uploadReportRef.current && uploadReportRef.current.handleUploadAlertClose();
  };

  const handleDelete = (reportID: number) => {
    document.body.style.overflow = "visible";
    dispatch(closeDelteConfirmModal(null));
    dispatch(deleteReport(reportID));
  };

  const openDeleteConfirmModal = (id: any) => {
    handleUploadAlertClose();
    document.body.style.overflow = !id ? "visible" : "hidden";
    dispatch(closeDelteConfirmModal(id));
  };

  const handleCheckboxChange = (index: number, value: boolean) => {
    const checkedData: any = [...data];
    const newDownloadReportIdObj: any = { ...downloadReportIdObj };
    checkedData[index].isChecked = value;
    if (value) {
      newDownloadReportIdObj[checkedData[index].reportId] = checkedData[index].reportId;
    } else {
      delete newDownloadReportIdObj[checkedData[index].reportId];
    }
    const len = checkedData.filter((i: any) => {
      return i.isChecked;
    }).length;
    setIsSelectAll(len === checkedData.length);
    setAllCheckBoxSelected(Object.values(newDownloadReportIdObj).length > 1);
    setData(checkedData);
    setDownloadReportIdObj(newDownloadReportIdObj);
  };

  const handleAllCheckboxChange = (value: boolean) => {
    const checkedData: IRepositoryDetails[] = [...data];
    const newDownloadReportIdObj: any = { ...downloadReportIdObj };
    checkedData.map((i: IRepositoryDetails) => {
      i.isChecked = value;
      if (value) {
        newDownloadReportIdObj[i.reportId] = i.reportId;
      } else {
        delete newDownloadReportIdObj[i.reportId];
      }
      return i;
    });
    setAllCheckBoxSelected(value);
    setIsSelectAll(value);
    setData(checkedData);
    setDownloadReportIdObj(newDownloadReportIdObj);
  };

  const getRepoDetails = (
    sortInfo: any = sort,
    filter: any = filterValue,
    pageInfo: any = paginationInfo,
  ) => {
    const sortState = sortInfo && sortInfo.column ? sortInfo : null;
    dispatch(
      loadRepositoryDetails(
        null,
        sortState,
        filter,
        pageInfo,
        signedInUserName,
        !isAudit ? REPOSITORY : AUDIT_REPOSITORY,
      ),
    );
  };

  const handleFilter = (val: any) => {
    const newPagination = { ...paginationInfo, activePage: 1, activeDelta: DEFAULT_DELTA };
    getRepoDetails(null, val, newPagination);
  };

  const handleSort = (val: any) => {
    const newPagination = { ...paginationInfo, activePage: 1 };
    dispatch(
      loadRepositoryDetails(
        null,
        val,
        null,
        newPagination,
        signedInUserName,
        !isAudit ? REPOSITORY : AUDIT_REPOSITORY,
      ),
    );
  };

  const handlePagination = (page: any, delta: number) => {
    const newPagination = { ...paginationInfo, activePage: page, activeDelta: delta };
    getRepoDetails(null, null, newPagination);
  };

  const handleDrawerChange = () => {
    setIsDrawerOpen(false);
    dispatch(resetPreview(null));
  };

  const handlePreview = (rowData: IRepositoryDetails) => {
    handleUploadAlertClose();
    setIsDrawerOpen(false);
    dispatch(loadPreviewDetails(rowData?.reportId));
  };

  const handleResetFilter = () => {
    let isExistingFilter = false;

    // check if any filters are present
    if (Object.keys(filterValue).length === 0) {
      isExistingFilter = false;
    } else {
      for (let key in filterValue) {
        if (filterValue.hasOwnProperty(key)) {
          isExistingFilter = filterValue[key] !== "" ? true : false;
          break;
        }
      }
    }

    if (
      (sort.column !== "" && sort.order !== "") ||
      Object.values(downloadReportIdObj).length > 0
    ) {
      isExistingFilter = true;
    }

    // reset filters only if there are any filters
    if (isExistingFilter) {
      setDownloadReportIdObj({});
      setIsSelectAll(false);
      setAllCheckBoxSelected(false);
      dispatch(resetFilters());
    }
  };

  //Table definitions include header names, customizations with width, sort, renderer
  let REPOSITORY_COLUMNS = [
    {
      headerName: "",
      field: "",
      sortable: false,
      cellRenderer: <TableCheckBox handleCheckboxChange={handleCheckboxChange}></TableCheckBox>,
      filter: { show: data.length > 0, type: "selectCheckbox" },
      filterRenderer: (
        <TableFilterCheckbox
          handleAllCheckboxChange={handleAllCheckboxChange}
          isSelectAll={isSelectAll}
        />
      ),
    },
    {
      headerName: "Source",
      field: "source",
      sortable: false,
      filter: { show: true },
      filterRenderer: <DropdownFilter options={DROPDOWN_OPTIONS} />,
      cellRenderer: (
        <TableCellRenderer fieldName="source" type="icon" obj={SOURCE_OBJ} defaultValue="Website" />
      ),
    },
    {
      headerName: "Uploaded",
      field: "uploadDate",
      sortable: true,
      filter: { show: true, type: "date", range: true },
      cellRenderer: <TableCellRenderer fieldName="uploadDate" type="date" />,
    },
    { headerName: "Uploaded By", field: "uploadedBy", sortable: true, filter: { show: true } },
    { headerName: "Submitted By", field: "reporterName", sortable: true, filter: { show: true } },
    {
      headerName: "Customer Name",
      field: "customerName",
      sortable: true,
      filter: { show: true },
    },
    {
      headerName: "Serial Number",
      field: "serialNumber",
      sortable: true,
      width: "8rem",
      filter: { show: true },
    },
    {
      headerName: "Period Start",
      field: "periodStart",
      sortable: true,
      type: "datePicker",
      filter: { show: true, type: "date", range: true },
      cellRenderer: <TableCellRenderer fieldName="periodStart" type="date" skipTimezone="true" />,
    },
    {
      headerName: "Period End",
      field: "periodEnd",
      sortable: true,
      type: "datePicker",
      filter: { show: true, type: "date", range: true },
      cellRenderer: <TableCellRenderer fieldName="periodEnd" type="date" skipTimezone="true" />,
    },
    {
      headerName: "Action",
      field: "action",
      sortable: false,
      filter: { show: true },
      filterRenderer: <ResetFilter handleResetFilter={handleResetFilter} />,
      cellRenderer: (
        <ActionComponent
          preview={handlePreview}
          deleteRow={(rowData: IRepositoryDetails) => openDeleteConfirmModal(rowData.reportId)}
          rowData={data}
          showDelete={true}
          disabledDownload={false}
          source={DONWLOAD_SOURCE.Repository}
          handleClose={handleUploadAlertClose}
        ></ActionComponent>
      ),
    },
  ];

  if (isAudit) {
    const supportSiteColumn = {
      headerName: "Support Site ID",
      field: "supportSiteId",
      sortable: true,
      width: "8rem",
      filter: { show: true },
    };
    const ibmValidationColumn: any = {
      headerName: "Pass IBM Validation",
      field: "ibmValidation",
      sortable: true,
      width: "7rem",
      filter: { show: true },
      cellRenderer: <TableCellRenderer fieldName="ibmValidation" type="booleanText" />,
      filterRenderer: <DropdownFilter options={AUDIT_IBM_OPTIONS} />,
    };
    REPOSITORY_COLUMNS.splice(1, 1);
    REPOSITORY_COLUMNS.splice(1, 0, supportSiteColumn, ibmValidationColumn);
  }

  const handleLogClick = (isAuditRepo: boolean) => {
    history.push(isAuditRepo ? ROUTE_PATHS.AUDIT_LOGS : ROUTE_PATHS.REPOSITORY_LOGS);
  };

  const showDownload = isSelectAll || allCheckBoxSelected || false;
  const showRepoLogsIcon = isAdmin(signedInUser?.role) || isSuperAdmin(signedInUser?.role) || false;

  return (
    <div>
      <ClayLayout.ContainerFluid view size={false}>
        <ClayLayout.Row justify="start" className="align-items-center">
          <ClayLayout.Col className="d-flex align-items-center">
            <PageTitle title={!isAudit ? REPOSITORY_PAGETITLE : AUDIT_PAGETITLE} />
          </ClayLayout.Col>
        </ClayLayout.Row>
        <div className="mb-4">
          <FaqsList
            questions={!isAudit ? REPOSITORY_QUESTIONS(envVars) : AUDIT_QUESTIONS(envVars)}
            customAccordionOpen={1}
            showHeader={false}
            className="accordion-repository-summary"
          />
        </div>
        <UploadReport
          getRepoDetails={getRepoDetails}
          ref={uploadReportRef}
          source={!isAudit ? WEBSITE : SCRT_AUDIT}
        />
        <ClayLayout.Row>
          <ClayCard className="m-3 w-100">
            <ClayCard.Body className="py-4 px-2">
              <div
                className={`d-flex ${showDownload || showRepoLogsIcon ? "repository-logs" : ""}`}
              >
                {!!showDownload && (
                  <>
                    <DownloadComponent
                      handleClose={handleUploadAlertClose}
                      toolTip={"Download selected reports"}
                      source={DONWLOAD_SOURCE.Repository}
                      reportIdList={Object.values(downloadReportIdObj)}
                    />
                  </>
                )}
                {!!showRepoLogsIcon && (isAdmin(signedInUser?.role) || isSuperAdmin(signedInUser?.role)) && (
                      <ClayButton displayType="secondary" className="scrt-repo-action-button" onClick={() => handleLogClick(isAudit)}>
                        Log
                      </ClayButton>
                )}
              </div>
              <Table
                definitions={REPOSITORY_COLUMNS}
                handleCheckboxChange={handleCheckboxChange}
                dataSource={data}
                borderedColumns={false}
                borderless={true}
                headVerticalAlignment={"middle"}
                headingNoWrap={true}
                hover={false}
                noWrap={true}
                responsive={true}
                responsiveSize={"sm"}
                striped={true}
                tableVerticalAlignment={"middle"}
                handleFilter={handleFilter}
                filterValue={filterValue}
                sort={sort}
                handleSort={handleSort}
                pagination={paginationInfo}
                onActivePageChange={handlePagination}
                showPagination={true}
              />
            </ClayCard.Body>
          </ClayCard>
        </ClayLayout.Row>
        <div>
          {reportId && isDrawerOpen && (
            <Preview
              isDrawerOpen={isDrawerOpen}
              handleDrawerChange={handleDrawerChange}
              previewDetailsData={previewDetails}
            />
          )}
        </div>
      </ClayLayout.ContainerFluid>
      {deleteModalReportId && (
        <DeleteModal
          confirmDelete={handleDelete}
          reportId={deleteModalReportId}
          rowData={rowData}
          modalClose={() => openDeleteConfirmModal(null)}
        />
      )}
    </div>
  );
}

export default Repository;
