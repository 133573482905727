import ClayCard from "@clayui/card";
import ClayLayout from "@clayui/layout";
import { PageTitle } from "@common-components";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import { ROUTE_PATHS } from "@constants";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Grid from "./grid/Grid";
import Instructions from "./instructions/Instructions";
import UploadReport from "./uploadReport/UploadReport";

function AdditionalMainframeReporting(props: any) {
  const pageTitle = "Additional Mainframe Reporting";
  const envVars = useSelector(getEnvVars);
  const isOktaEnabled = envVars.DISABLE_OKTA !== "true";
  const isValidUser = localStorage.getItem("okta-valid-user") || "";
  const [reloadList, setReloadList] = useState(false);

  // Check if Valid user
  useEffect(() => {
    if (isOktaEnabled && isValidUser !== "true") {
      window.location.assign(ROUTE_PATHS.OKTA_REDIRECT);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const hanldeReloadListFlag = (val: any) => {
    setReloadList(val);
  };

  return (
    <React.Fragment>
      <ClayLayout.ContainerFluid view size={false}>
        <ClayLayout.Row justify="start" className="align-items-center">
          <ClayLayout.Col className="d-flex align-items-center">
            <PageTitle title={pageTitle} />
          </ClayLayout.Col>
        </ClayLayout.Row>

        <div>
          <Instructions />
        </div>

        <ClayLayout.Row>
          <ClayCard className="m-3 w-100">
            <ClayCard.Body className="py-4 px-2">
              <UploadReport hanldeReloadListFlag={hanldeReloadListFlag} />
            </ClayCard.Body>
          </ClayCard>
        </ClayLayout.Row>

        <ClayLayout.Row>
          <ClayCard className="m-3 w-100">
            <ClayCard.Body className="py-4 px-2">
              <Grid reloadList={reloadList} hanldeReloadListFlag={hanldeReloadListFlag} />
            </ClayCard.Body>
          </ClayCard>
        </ClayLayout.Row>
      </ClayLayout.ContainerFluid>
    </React.Fragment>
  );
}

export default AdditionalMainframeReporting;
