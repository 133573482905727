import { ClaySelectWithOption } from "@clayui/form";
import React from "react";

const DropdownFilter = (props: any) => {
  const { filterVal, item, options, setFilterVal } = props;
  return (
    <div>
      <ClaySelectWithOption
        style={{ backgroundColor: "white" }}
        aria-label={item.headerName}
        id={item.field}
        value={filterVal[item.field] || ""}
        options={options}
        onChange={(e: any) => {
          setFilterVal({ ...filterVal, [item.field]: e.target.value });
        }}
      />
    </div>
  );
};

export { DropdownFilter };
