import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const slice = createSlice({
  name: "globalAlertHandler",
  initialState: {
    toastMessages: [] as any,
    accessDeniedConfig: {
      show: false,
      clossable: true,
    },
  },
  reducers: {
    addToastAlert: (globalAlertHandler, action) => {
      globalAlertHandler.toastMessages.push(action.payload);
    },
    removeToastAlert: (globalAlertHandler, action) => {
      globalAlertHandler.toastMessages = globalAlertHandler.toastMessages.filter(
        (value: any) => value.id !== action.payload,
      );
    },
    addAccessDeniedModal: (globalAlertHandler, action) => {
      globalAlertHandler.accessDeniedConfig = action.payload;
    },
    removeAccessDeniedModal: (globalAlertHandler, action) => {
      globalAlertHandler.accessDeniedConfig = {
        show: action.payload,
        clossable: true,
      };
    },
  },
});

export const { addToastAlert, removeToastAlert, addAccessDeniedModal, removeAccessDeniedModal } =
  slice.actions;

export const showAlert =
  ({
    display = "toast",
    type = "error",
    position = "top-right",
    timeout = "5000",
    message = "",
    additionalInfo = "",
    clossable = true,
  }) =>
  (dispatch: any) => {
    if (message) {
      const id = uuidv4();
      if (!type || type === "failure") {
        type = "error";
      }

      if (display === "toast") {
        dispatch(
          addToastAlert({
            type,
            position,
            timeout,
            message,
            additionalInfo,
            id,
          }),
        );
      }
    }

    if (display === "accessDenied") {
      dispatch(
        addAccessDeniedModal({
          show: true,
          clossable,
        }),
      );
    }
  };
export const getToastMessages = (state: any) => state.globalAlertHandler.toastMessages;
export const getAccessDeniedConfig = (state: any) => state.globalAlertHandler.accessDeniedConfig;

export const globalAlertHandlerReducer = slice.reducer;
