import Login from "@components/login/Login";
import Logout from "@components/logout/Logout";
import OktaRedirect from "@components/oktaRedirect/oktaRedirect";
import { Overview } from "@components/screens/Overview/Overview";
import AdditionalMainframeReporting from "@components/screens/additionalMainframeReporting/AdditionalMainframeReporting";
import GettingStarted from "@components/screens/gettingStarted/GettingStarted";
import Help from "@components/screens/help/Help";
import Manage from "@components/screens/manage/Manage";
import NoAccess from "@components/screens/noAccess/NoAccess";
import Profile from "@components/screens/profile/Profile";
import Repository from "@components/screens/repository/Repository";
import Logs from "@components/screens/repository/logs/Logs";
import RepositoryLogs from "@components/screens/repository/logs/RepositoryLogs";
import SiteMapping from "@components/screens/siteMapping/SiteMapping";
import { SiteList } from "@components/screens/sites/site/SiteList";
import SitesDetail from "@components/screens/sites/site/SitesDetail";

export enum ROUTE_PATHS {
  HOME = "/",
  LOCAL_LOGIN = "/local-login",
  OKTA_LOGIN = "/login",
  LOGOUT = "/logout",
  GETTING_STARTED = "/getting-started",
  HELP = "/help",
  REPOSITORY = "/repository",
  SITES = "/sites",
  SITES_DETAIL = "/sites/:id",
  ECX_REDIRECT = "/ecxRedirect",
  REPOSITORY_LOGS = "/repository/logs",
  OKTA_REDIRECT = "/oktaRedirect",
  MANAGE = "/manage",
  HEALTH = "/health",
  PROFILE = "/profile",
  NO_ACCESS = "/no-access",
  SITEMAPPING = "/site-mapping",
  OVERVIEW = "/overview",
  AMR = "/additional-mainframe-reporting",
  AUDIT = "/audit",
  AUDIT_MAPPING = "/audit-mapping",
  REPO_LOGS = "/repo-logs",
  AUDIT_LOGS = "/audit-logs",
  LOGIN_CALLBACK = "/login/callback",
}

export const routesConfig = [
  { path: ROUTE_PATHS.GETTING_STARTED, component: GettingStarted },
  { path: ROUTE_PATHS.REPOSITORY, component: Repository },
  { path: ROUTE_PATHS.SITES, component: SiteList },
  { path: ROUTE_PATHS.SITES_DETAIL, component: SitesDetail },
  { path: ROUTE_PATHS.REPOSITORY_LOGS, component: Logs },
  { path: ROUTE_PATHS.OKTA_REDIRECT, component: OktaRedirect },
  { path: ROUTE_PATHS.LOGOUT, component: Logout },
  { path: ROUTE_PATHS.NO_ACCESS, component: NoAccess },
  { path: ROUTE_PATHS.HELP, component: Help },
  { path: ROUTE_PATHS.MANAGE, component: Manage },
  { path: ROUTE_PATHS.PROFILE, component: Profile },
  { path: ROUTE_PATHS.SITEMAPPING, component: SiteMapping },
  { path: ROUTE_PATHS.OVERVIEW, component: Overview },
  { path: ROUTE_PATHS.AMR, component: AdditionalMainframeReporting },
  //{ path: ROUTE_PATHS.OKTA_LOGIN, component: Login },
  { path: ROUTE_PATHS.AUDIT, component: Repository },
  { path: ROUTE_PATHS.AUDIT_MAPPING, component: SiteMapping },
  { path: ROUTE_PATHS.REPO_LOGS, component: RepositoryLogs },
  { path: ROUTE_PATHS.AUDIT_LOGS, component: RepositoryLogs },
  //{ path: ROUTE_PATHS.LOGIN_CALLBACK, component: Login },
];
