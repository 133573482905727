import { apiCallBegan } from "@common-services/redux-helper/api-action-creator";
import { DEFAULT_DELTA } from "@constants";
import { configureStore, createSlice } from "@reduxjs/toolkit";

import { REPOSITORY, REPOSITORY_DETAILS_URL, formatPreviewDetails } from "./constants";

const defaultState = {
  deleteConfirmId: null,
  repoData: [],
  repositoryList: {},
  sort: { column: "", order: "" },
  pagination: {
    activeDelta: DEFAULT_DELTA,
    activePage: 0,
    ellipsisBuffer: 5,
    totalItems: 100,
  },
  filterVal: {},
  scrtReportUploadResponse: undefined,
  previewDetails: {},
  reportId: null,
};

//State for Repo details defaults and reducers
const slice = createSlice({
  name: "repositoryDetails",
  initialState: { ...defaultState },
  reducers: {
    repositoryData: (state, action) => {
      if (action.payload && action.payload.success) {
        state.repoData = action.payload?.data?.result || [];
      }
    },
    repositoryListReceived: (state, action) => {
      if (action.payload) {
        state.repositoryList = action.payload || {};
      }
    },
    handleSortState: (state, action) => {
      if (action.payload) {
        state.sort = action.payload;
      }
    },
    handleFilterState: (state, action) => {
      if (action.payload) {
        state.sort = { column: "", order: "" };
        state.filterVal = action.payload;
      }
    },
    handlePaginationState: (state, action) => {
      if (action.payload) {
        state.pagination = action.payload;
      }
    },

    onPreviewClick: (state, action) => {
      if (action.payload) {
        state.reportId = action.payload;
      }
    },
    previewDetailsReceived: (state, action) => {
      state.previewDetails = formatPreviewDetails(action.payload?.data) || null;
    },

    onUploadScrtReportResponse: (state, action) => {
      state.scrtReportUploadResponse = { ...action.payload } || {};
    },
    onSuccessDeleteReport: (state, action) => {
      state.deleteMessage = action.payload?.data?.body || null;
    },
    resetPreview: (state, action) => {
      state.previewDetails = null;
      state.reportId = action.payload || null;
    },

    resetRepoData: (state) => {
      state.repoData = [];
      state.repositoryList = {};
    },

    resetRepositoryState() {
      return { ...defaultState };
    },
    closeDelteConfirmModal: (state, action) => {
      state.deleteConfirmId = action?.payload || null;
    },
  },
});

export const {
  repositoryData,
  repositoryListReceived,
  handleSortState,
  handleFilterState,
  handlePaginationState,
  onPreviewClick,
  previewDetailsReceived,
  resetPreview,
  onUploadScrtReportResponse,
  onSuccessDeleteReport,
  resetRepositoryState,
  resetRepoData,
  closeDelteConfirmModal,
} = slice.actions;

//API call to load Repository load details
export const loadRepositoryDetails =
  (siteId, sortParams, filterParams, pageParams, userName, source) => (dispatch, getState) => {
    //Need to dispatch events, update state and then make API call
    if (sortParams) {
      dispatch(handleSortState(sortParams));
    }
    if (filterParams) {
      dispatch(handleFilterState(filterParams));
    }
    if (pageParams) {
      dispatch(handlePaginationState(pageParams));
    }
    const { URL, body } = getURLWithParams(siteId, getState(), userName, source);
    return dispatch(
      apiCallBegan({
        url: URL,
        method: "POST",
        data: body,
        onSuccess: repositoryData.type,
        onError: resetRepoData.type,
      }),
    );
  };

//To fetch URL, request params and body
//@Input state
//@Output { URL, body }
const getURLWithParams = (siteId, state, userName, source) => {
  let URL = REPOSITORY_DETAILS_URL.GET_REPOSITORY_DETAILS;
  let body = {
    supportSiteId: siteId,
    uploaded: "",
    uploadedBy: "",
    customerName: "",
    submittedBy: "",
    serialNumber: "",
    periodStart: "",
    periodEnd: "",
    source: source || REPOSITORY,
  };

  if (state?.repositoryDetails?.pagination) {
    URL += `?size=${state.repositoryDetails.pagination.activeDelta}&page=${
      state.repositoryDetails.pagination.activePage - 1
    }`;
  }
  if (state?.repositoryDetails?.filterVal) {
    const filters = { ...state.repositoryDetails.filterVal };
    filters["submittedBy"] = filters["reporterName"];
    filters["uploaded"] = filters["uploadDate"];
    delete filters["action"];
    delete filters["uploadDate"];
    delete filters["reporterName"];
    body = { ...body, ...filters };
  }
  if (state?.repositoryDetails?.sort?.column) {
    const sortColumn =
      state?.repositoryDetails?.sort?.column === "serialNumber"
        ? "machineSerialNumber"
        : state?.repositoryDetails?.sort?.column;
    const sortOrder = state?.repositoryDetails?.sort?.order
      ? state?.repositoryDetails?.sort?.order.toUpperCase()
      : "ASC";
    URL += `&sort=${sortColumn},${sortOrder}`;
  }
  if (body["ibmValidation"]) {
    body["ibmValidation"] =
      body["ibmValidation"] === "All" ? null : body["ibmValidation"] === "true";
  }
  return { URL, body };
};

//To make delete Report API call
export const deleteReport = (reportId) => (dispatch) => {
  if (reportId) {
    dispatch(onSuccessDeleteReport(null));
    return dispatch(
      apiCallBegan({
        url: REPOSITORY_DETAILS_URL.DELETE_REPORT + `/${reportId}`,
        data: {},
        method: "PUT",
        onSuccess: onSuccessDeleteReport.type,
      }),
    );
  }
};

//API call to load preview details
export const loadPreviewDetails = (reportId) => (dispatch) => {
  if (reportId) {
    dispatch(resetPreview(reportId));
    return dispatch(
      apiCallBegan({
        url: REPOSITORY_DETAILS_URL.GET_PREVIEW_DETAILS + `/${reportId}`,
        method: "GET",
        onSuccess: previewDetailsReceived.type,
        errorMessage: "Error fetching Preview details.",
      }),
    );
  }
};

export const uploadSCRTReport = (data) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: REPOSITORY_DETAILS_URL.UPLOAD_SCRT_REPORT,
      method: "POST",
      data: data,
      isFormData: true,
      onSuccess: onUploadScrtReportResponse.type,
      onError: onUploadScrtReportResponse.type,
      skipErrorHandling: true,
      config: { headers: { "Content-Type": "multipart/form-data", isFormData: true } },
    }),
  );
};

export const resetFilters = () => (dispatch, getState) => {
  dispatch(handleFilterState({}));
};

export const store = configureStore({
  reducer: slice.reducer,
});

// selectors
export const getRepositoryDetails = (state) => state.repositoryDetails.repositoryList;

export const getRepositoryData = (state) => state.repositoryDetails.repoData;

export const getRepoSortState = (state) => state.repositoryDetails.sort;

export const getRepoFilterState = (state) => state.repositoryDetails.filterVal;

export const getRepoPageState = (state) => state.repositoryDetails.pagination;

export const getReportId = (state) => state.repositoryDetails.reportId;

export const getPreviewDetails = (state) => state.repositoryDetails.previewDetails;

export const getDeleteMessage = (state) => state.repositoryDetails.deleteMessage;

export const getDeleteConfirmReportId = (state) => state.repositoryDetails.deleteConfirmId;

export const getUploadScrtReportResponse = (state) =>
  state.repositoryDetails.scrtReportUploadResponse;

// reducer
export const repositoryDetailsReducer = slice.reducer;
