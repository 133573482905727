import ClayForm from "@clayui/form";
import { claySpriteImagePath } from "@common-services/util/claySpriteImagePath";
import React from "react";

function TextError(props: any) {
  return (
    <ClayForm.FeedbackItem className={props.className}>
      <ClayForm.FeedbackIndicator spritemap={claySpriteImagePath()} symbol="exclamation-full" />
      {props.children}
    </ClayForm.FeedbackItem>
  );
}

export default TextError;
