import ClayCard from "@clayui/card";
import ClayLayout from "@clayui/layout";
import { DropdownFilter, Table } from "@common-components";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import TableCellRenderer from "@components/cellRenderer/TableCellRenderer";
import { ResetFilter } from "@components/resetFilter/ResetFilter";
import { DEFAULT_DELTA, ROLE_USER, ROUTE_PATHS } from "@constants";
import { IUserEvent } from "@interfaces";
import {
  getUserEvent,
  getUserEventData,
  getUserEventFilterState,
  getUserEventPageState,
  getUserEventSortState,
  handleEventPaginationState,
  loadUserEventData,
  parseLogData,
  resetUserEventDataAndFilters,
  resetUserEventFilters,
  userEvent,
} from "@store/repositoryLogs/slice";
import { GET_USER_ROLE } from "@utils";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./repositoryLogsTables.scss";

function SignInSignOutLogs(props: any) {
  const dispatch = useDispatch();
  const sort = useSelector(getUserEventSortState);
  const filterValue = useSelector(getUserEventFilterState);
  const paginationInfo = useSelector(getUserEventPageState);
  const data = useSelector(getUserEvent);

  const envVars = useSelector(getEnvVars);
  const isOktaEnabled = envVars.DISABLE_OKTA !== "true";
  const isValidUser = localStorage.getItem("okta-valid-user") || "";

  const rowData = useSelector(getUserEventData);

  //Check if Valid user
  useEffect(() => {
    if (isOktaEnabled && isValidUser !== "true") {
      window.location.assign(ROUTE_PATHS.OKTA_REDIRECT);
    } else if (GET_USER_ROLE() === ROLE_USER) {
      window.location.href = ROUTE_PATHS.NO_ACCESS;
    }
    return () => {
      dispatch(resetUserEventDataAndFilters());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleResetFilter = () => {
    let isExistingFilter = false;

    // check if any filters are present
    if (Object.keys(filterValue).length === 0) {
      isExistingFilter = false;
    } else {
      for (let key in filterValue) {
        if (filterValue.hasOwnProperty(key)) {
          isExistingFilter = filterValue[key] !== "" ? true : false;
          break;
        }
      }
    }

    if (sort.column !== "" && sort.order !== "") {
      isExistingFilter = true;
    }

    // reset filters only if there are any filters
    if (isExistingFilter) {
      dispatch(resetUserEventFilters());
    }
  };

  useEffect(() => {
    let newData: IUserEvent[] =
      (rowData &&
        rowData.content &&
        rowData.content.length &&
        rowData.content.map((row: IUserEvent) => ({
          ...row,
        }))) ||
      [];
    newData = parseLogData(
      [...newData],
      "erpAccountName",
      "erpAccountNumber",
      "erpAccountName",
      "|||",
    );
    dispatch(userEvent(newData));
    const newPageInfo = {
      activeDelta: rowData?.size || DEFAULT_DELTA,
      activePage: rowData?.number + 1 || 1,
      ellipsisBuffer: 5,
      totalItems: rowData?.totalElements || 0,
    };
    dispatch(handleEventPaginationState(newPageInfo));
  }, [rowData]); // eslint-disable-line react-hooks/exhaustive-deps

  const dropdownOptions = [
    { label: "Select Event", value: "" },
    { label: "Login", value: "login" },
    { label: "Logout", value: "logout" },
  ];

  const columnDefs = [
    { headerName: "User Id", field: "userId", sortable: true, filter: { show: true } },
    { headerName: "Name", field: "name", sortable: true, filter: { show: true } },
    { headerName: "Broadcom ERP Account", field: "erpAccountName", sortable: true, filter: { show: true } },
    {
      headerName: "Event",
      field: "event",
      sortable: true,
      filter: { show: true },
      filterRenderer: <DropdownFilter options={dropdownOptions} />,
      cellRenderer: <TableCellRenderer fieldName="event" type="capitalize" />,
    },
    {
      headerName: "Timestamp",
      field: "eventTimestamp",
      sortable: true,
      type: "date",
      filter: { show: true, type: "date", range: true },
      cellRenderer: <TableCellRenderer fieldName="eventTimestamp" type="date" />,
    },
    {
      headerName: "Action",
      field: "action",
      filter: { show: true },
      filterRenderer: <ResetFilter handleResetFilter={handleResetFilter} />,
    },
  ];

  const getUserEventDetails = (
    sortInfo: any = sort,
    filter: any = filterValue,
    pageInfo: any = paginationInfo,
  ) => {
    const sortState = sortInfo && sortInfo.column ? sortInfo : null;
    dispatch(loadUserEventData(sortState, filter, pageInfo));
  };

  const handleFilter = (val: any) => {
    const newPagination = { ...paginationInfo, activePage: 1, activeDelta: DEFAULT_DELTA };
    getUserEventDetails(null, val, newPagination);
  };

  const handleSort = (val: any) => {
    const newPagination = { ...paginationInfo, activePage: 1 };
    dispatch(loadUserEventData(val, null, newPagination));
  };

  const handlePagination = (page: any, delta: number) => {
    const newPagination = { ...paginationInfo, activePage: page, activeDelta: delta };
    getUserEventDetails(null, null, newPagination);
  };

  return (
    <React.Fragment>
      <ClayLayout.ContainerFluid view size={false}>
        <ClayLayout.Row>
          <ClayCard className="m-3 w-100">
            <ClayCard.Body className="p-4">
              <Table
                rowClassName="repo-log-table"
                definitions={columnDefs}
                dataSource={data}
                borderedColumns={false}
                borderless={true}
                headVerticalAlignment={"middle"}
                headingNoWrap={true}
                hover={false}
                noWrap={true}
                responsive={true}
                responsiveSize={"sm"}
                striped={true}
                tableVerticalAlignment={"middle"}
                handleFilter={handleFilter}
                filterValue={filterValue}
                sort={sort}
                handleSort={handleSort}
                pagination={paginationInfo}
                onActivePageChange={handlePagination}
                showPagination={true}
              />
            </ClayCard.Body>
          </ClayCard>
        </ClayLayout.Row>
      </ClayLayout.ContainerFluid>
    </React.Fragment>
  );
}

export default SignInSignOutLogs;
