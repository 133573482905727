import { DropdownFilter } from "@common-components";
import { ActionComponent } from "@components/ActionComponent/ActionComponent";
import TableCellRenderer from "@components/cellRenderer/TableCellRenderer";
import { ResetFilter } from "@components/resetFilter/ResetFilter";
import { DONWLOAD_SOURCE } from "@constants";

export const REPO_LOGS_OPTIONS = [
  { label: "All Status", value: "" },
  { label: "Success", value: "success" },
  { label: "Failure", value: "failure" },
];

export const REPORT_TYPE_OPTIONS = [
  { label: "All Report Types", value: "" },
  { label: "IBM", value: "IBM" },
  { label: "ISV", value: "ISV" },
];

export const SOURCE_TYPE_OPTIONS = [
  { label: "All Sources", value: "" },
  { label: "Website", value: "website" },
  { label: "MWP Mailbox", value: "mwp_mailbox" },
  { label: "SCRT Mailbox", value: "scrt_mailbox" },
];

export const repoLogColumns = (handleResetFilter: any, isAuditLogs: boolean = false) => {
  const columns = [
    {
      headerName: "Date",
      field: "date",
      sortable: true,
      width: "6rem",
      type: "datePicker",
      filter: { show: true, type: "date", range: true },
      cellRenderer: <TableCellRenderer fieldName="date" type="date" />,
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      filter: { show: true },
      filterRenderer: <DropdownFilter options={REPO_LOGS_OPTIONS} />,
      cellRenderer: <TableCellRenderer fieldName="status" type="capitalize" />,
      width: "8rem",
    },
    {
      headerName: "Report Type",
      field: "reportType",
      sortable: true,
      filter: { show: true },
      filterRenderer: <DropdownFilter options={REPORT_TYPE_OPTIONS} />,
      width: "10rem",
    },
    {
      headerName: "Source",
      field: "source",
      sortable: true,
      filter: { show: true },
      filterRenderer: isAuditLogs ? <></> : <DropdownFilter options={SOURCE_TYPE_OPTIONS} />,
      cellRenderer: <TableCellRenderer fieldName="source" type="tokenize" />,
      width: "10rem",
    },
    {
      headerName: "User ID",
      field: "username",
      sortable: true,
      filter: { show: true },
      width: "12rem",
    },
    {
      headerName: "Broadcom ERP Account",
      field: "erpcustomername",
      sortable: true,
      filter: { show: true },
      width: "8rem",
    },
    {
      headerName: "File Name",
      field: "filename",
      width: "5rem",
      sortable: true,
      filter: { show: true },
    },
    {
      headerName: "Concatenated",
      field: "concatenate",
      sortable: true,
      filter: { show: true },
    },
    {
      headerName: "Serial Number",
      field: "serialnumber",
      sortable: true,
      filter: { show: true },
      width: "8rem",
    },
    {
      headerName: "Reporting Period",
      field: "reportingPeriod",
      sortable: true,
      width: "10rem",
      filter: { show: true },
    },
    {
      headerName: "Message",
      field: "errorMessage",
      sortable: true,
      width: "15rem",
      filter: { show: true },
      cellRenderer: <TableCellRenderer fieldName="errorMessage" type="html" />,
    },
    {
      headerName: "Action",
      field: "action",
      filter: { show: true },
      filterRenderer: <ResetFilter handleResetFilter={handleResetFilter} />,
      cellRenderer: (
        <ActionComponent
          hidePreview={true}
          showDelete={false}
          disabledDownload={false}
          source={DONWLOAD_SOURCE.Repository}
        ></ActionComponent>
      ),
    },
  ];
  if (isAuditLogs) {
    columns.splice(4, 0, {
      headerName: "Support Site ID",
      field: "supportSiteId",
      sortable: true,
      filter: { show: true },
      width: "10rem",
    });
  }
  return columns;
};
