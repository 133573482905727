import React, { useState } from "react";

interface ShowMoreProps {
  length?: number;
  data?: string;
}
const MORE = "more";
const LESS = "less";
const DEFAULT_LENGTH = 200;

export function ShowMoreLess(props: ShowMoreProps) {
  const { length, data } = props;
  const maxLength = length || DEFAULT_LENGTH;
  const [action, setAction] = useState(MORE);
  if (!data) {
    return <>{data}</>;
  }

  const handleActionClick = (event: any, type: string) => {
    event.preventDefault();
    event.stopPropagation();
    setAction(type);
  };
  return (
    <>
      {data.length > maxLength && action === MORE ? <>{data.substring(0, maxLength)}</> : data}
      {data.length > maxLength && action === MORE ? (
        <span onClick={(e) => handleActionClick(e, LESS)}>...more</span>
      ) : data.length > maxLength && action === LESS ? (
        <span onClick={(e) => handleActionClick(e, MORE)}>...less</span>
      ) : (
        ""
      )}
    </>
  );
}
