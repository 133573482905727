import ClayCard from "@clayui/card";
import ClayLayout from "@clayui/layout";
import { Table } from "@common-components";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import TableCellRenderer from "@components/cellRenderer/TableCellRenderer";
import { ResetFilter } from "@components/resetFilter/ResetFilter";
import { DEFAULT_DELTA, ROLE_USER, ROUTE_PATHS } from "@constants";
import { IReminderLogs } from "@interfaces";
import {
  getReminderLogs,
  getReminderLogsData,
  getReminderLogsDataApi,
  getReminderLogsFilterState,
  getReminderLogsPageState,
  getReminderLogsSortState,
  handleReminderLogsPaginationState,
  parseLogData,
  reminderLogs,
  resetReminderLogsFilters,
  resetRepositoryLogsState,
} from "@store/repositoryLogs/slice";
import { GET_USER_ROLE } from "@utils";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./repositoryLogsTables.scss";

function ReminderLogs(props: any) {
  const dispatch = useDispatch();
  const sort = useSelector(getReminderLogsSortState);
  const filterValue = useSelector(getReminderLogsFilterState);
  const paginationInfo = useSelector(getReminderLogsPageState);
  const data = useSelector(getReminderLogs);

  const envVars = useSelector(getEnvVars);
  const isOktaEnabled = envVars.DISABLE_OKTA !== "true";
  const isValidUser = localStorage.getItem("okta-valid-user") || "";

  const rowData = useSelector(getReminderLogsData);

  //Check if Valid user
  useEffect(() => {
    if (isOktaEnabled && isValidUser !== "true") {
      window.location.assign(ROUTE_PATHS.OKTA_REDIRECT);
    } else if (GET_USER_ROLE() === ROLE_USER) {
      window.location.href = ROUTE_PATHS.NO_ACCESS;
    }
    return () => {
      dispatch(resetRepositoryLogsState());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleResetFilter = () => {
    let isExistingFilter = false;

    // check if any filters are present
    if (Object.keys(filterValue).length === 0) {
      isExistingFilter = false;
    } else {
      for (let key in filterValue) {
        if (filterValue.hasOwnProperty(key)) {
          isExistingFilter = filterValue[key] !== "" ? true : false;
          break;
        }
      }
    }

    if (sort.column !== "" && sort.order !== "") {
      isExistingFilter = true;
    }
    // reset filters only if there are any filters
    if (isExistingFilter) {
      dispatch(resetReminderLogsFilters());
    }
  };

  useEffect(() => {
    let newData: IReminderLogs[] =
      (rowData &&
        rowData.content &&
        rowData.content.length &&
        rowData.content.map((row: IReminderLogs) => ({
          ...row,
        }))) ||
      [];
    newData = parseLogData(
      [...newData],
      "erpAccountName",
      "erpAccountNumber",
      "erpAccountName",
      "||",
    );
    newData = parseLogData([...newData], "username", "toEmailAddress", "username");
    dispatch(reminderLogs(newData));
    const newPageInfo = {
      activeDelta: rowData?.size || DEFAULT_DELTA,
      activePage: rowData?.number + 1 || 1,
      ellipsisBuffer: 5,
      totalItems: rowData?.totalElements || 0,
    };
    dispatch(handleReminderLogsPaginationState(newPageInfo));
  }, [rowData]); // eslint-disable-line react-hooks/exhaustive-deps

  const columnDefs = [
    {
      headerName: "User ID",
      field: "username",
      sortable: true,
      filter: { show: true },
      width: "8rem",
    },
    {
      headerName: "Broadcom ERP Account",
      field: "erpAccountName",
      sortable: true,
      filter: { show: true },
      width: "8rem",
    },
    {
      headerName: "Site Id",
      field: "supportSiteId",
      sortable: true,
      filter: { show: true },
      width: "6rem",
    },
    {
      headerName: "Reminder Notice in Days",
      field: "reminderInDays",
      sortable: false,
      filter: { show: true },
      width: "9rem",
    },
    {
      headerName: "Reminder Type",
      field: "isReportReminder",
      sortable: false,
      filter: { show: true },
      width: "9rem",
    },
    {
      headerName: "Serial Number",
      field: "serialNumber",
      sortable: true,
      filter: { show: true },
      width: "6rem",
    },
    {
      headerName: "Reminder Date",
      field: "lastRemainderDate",
      sortable: true,
      type: "date",
      filter: { show: true, type: "date", range: true },
      cellRenderer: (
        <TableCellRenderer fieldName="lastRemainderDate" type="date" skipTimezone="true" />
      ),
      width: "6rem",
    },
    {
      headerName: "Reporting Start Date",
      field: "reportingPeriodStartDateEpoch",
      sortable: true,
      type: "date",
      filter: { show: true, type: "date", range: true },
      cellRenderer: (
        <TableCellRenderer
          fieldName="reportingPeriodStartDateEpoch"
          type="date"
          skipTimezone="true"
        />
      ),
      width: "6rem",
    },
    {
      headerName: "Reporting End Date",
      field: "reportingPeriodEndDateEpoch",
      sortable: true,
      type: "date",
      filter: { show: true, type: "date", range: true },
      cellRenderer: (
        <TableCellRenderer
          fieldName="reportingPeriodEndDateEpoch"
          type="date"
          skipTimezone="true"
        />
      ),
      width: "6rem",
    },

    {
      headerName: "Action",
      field: "action",
      filter: { show: true },
      filterRenderer: <ResetFilter handleResetFilter={handleResetFilter} />,
    },
  ];

  const getReminderLogsDetails = (
    sortInfo: any = sort,
    filter: any = filterValue,
    pageInfo: any = paginationInfo,
  ) => {
    const sortState = sortInfo && sortInfo.column ? sortInfo : null;
    dispatch(getReminderLogsDataApi(sortState, filter, pageInfo));
  };

  const handleFilter = (val: any) => {
    const newPagination = { ...paginationInfo, activePage: 1, activeDelta: DEFAULT_DELTA };
    getReminderLogsDetails(null, val, newPagination);
  };

  const handleSort = (val: any) => {
    const newPagination = { ...paginationInfo, activePage: 1 };
    dispatch(getReminderLogsDataApi(val, null, newPagination));
  };

  const handlePagination = (page: any, delta: number) => {
    const newPagination = { ...paginationInfo, activePage: page, activeDelta: delta };
    getReminderLogsDetails(null, null, newPagination);
  };

  return (
    <React.Fragment>
      <ClayLayout.ContainerFluid view size={false}>
        <ClayLayout.Row>
          <ClayCard className="m-3 w-100">
            <ClayCard.Body className="p-4">
              <Table
                rowClassName="repo-log-table"
                definitions={columnDefs}
                dataSource={data}
                borderedColumns={false}
                borderless={true}
                headVerticalAlignment={"middle"}
                headingNoWrap={true}
                hover={false}
                noWrap={true}
                responsive={true}
                responsiveSize={"sm"}
                striped={true}
                tableVerticalAlignment={"middle"}
                handleFilter={handleFilter}
                filterValue={filterValue}
                sort={sort}
                handleSort={handleSort}
                pagination={paginationInfo}
                onActivePageChange={handlePagination}
                showPagination={true}
              />
            </ClayCard.Body>
          </ClayCard>
        </ClayLayout.Row>
      </ClayLayout.ContainerFluid>
    </React.Fragment>
  );
}

export default ReminderLogs;
