import Drawer from "@mui/material/Drawer";

const DrawerMaterial = (props: any) => {
  return (
    <Drawer
      className={props.className}
      style={props.style}
      anchor={props.anchor}
      children={props.children}
      elevation={props.elevation}
      ModalProps={props.ModalProps}
      onClose={props.onClose}
      open={props.open}
      PaperProps={props.PaperProps}
      SlideProps={props.SlideProps}
      transitionDuration={props.transitionDuration}
      variant={props.variant}
      id={props.id}
      sx={props.sx}
      disableEnforceFocus
    ></Drawer>
  );
};

export { DrawerMaterial };
