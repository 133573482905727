import ClayTable from "@clayui/table";
import { MAPPER_PREVIEW_TAB, PREVIEW_TABLE_DATE_COLUMNS } from "@constants";
import { getUserTime } from "@utils";
import isEmpty from "lodash.isempty";
import React from "react";

import "./previewTable.scss";

const PreviewTable = (props: any) => {
  const { data, header, secondHeader } = props;
  const formatData = { ...data };

  if (header === "REPORT DETAILS") {
    formatData["reportingPeriodStartDate"] = data["reportingPeriodStartDate"].split("T")[0];
    formatData["reportingPeriodEndDate"] = data["reportingPeriodEndDate"].split("T")[0];
  }

  const previewTableColumnKeys = Object.keys(data);
  const previewTableRow =
    previewTableColumnKeys &&
    previewTableColumnKeys.length &&
    previewTableColumnKeys.map((column: any, index: number) => {
      return (
        <ClayTable.Row key={index}>
          {/* Mapper setting value for first column in table, if first column is dynamic, its taken from prev variable
          (Using Mapper)
          @Input : reportName
          @Output : Uploaded By
          (Not Using Mapper)
          @Input: OSE1
          @Output: OSE1*/}
          <ClayTable.Cell headingTitle>{MAPPER_PREVIEW_TAB[column] || column}</ClayTable.Cell>
          {/*
          Null Check and Formmatting Date Cells in User Time format
          @Input : "uploaded" : "2021-10-14T06:59:30.223+0000"
          @Output : "uploaded" : "Oct-14-2021 12:10 IST"
          @Input : "uploaded" : null
          @Output : "uploaded" : -
           */}
          <ClayTable.Cell>
            {formatData[column] != null
              ? PREVIEW_TABLE_DATE_COLUMNS.indexOf(column) !== -1
                ? getUserTime(formatData[column], "false") //To display timezone for upload Date
                : formatData[column]
              : "-"}
          </ClayTable.Cell>
        </ClayTable.Row>
      );
    });

  const emptyRow = (
    <ClayTable.Row>
      <ClayTable.Cell>No data available</ClayTable.Cell>
      <ClayTable.Cell>&nbsp;</ClayTable.Cell>
    </ClayTable.Row>
  );

  return (
    <ClayTable>
      <ClayTable.Head>
        <ClayTable.Row>
          <ClayTable.Cell className="preview-background-color" headingCell>
            {header}
          </ClayTable.Cell>
          <ClayTable.Cell className="preview-background-color" headingCell>
            {!isEmpty(data) && secondHeader ? secondHeader : <></>}
          </ClayTable.Cell>
        </ClayTable.Row>
      </ClayTable.Head>
      <ClayTable.Body>{isEmpty(data) ? emptyRow : previewTableRow}</ClayTable.Body>
    </ClayTable>
  );
};

export { PreviewTable };
