export const REPOSITORY_QUESTIONS: any = (envVars: any) => {
  return [
    {
      question: `
      Use this page to upload Broadcom ISV SCRT reports.`,
      answer: `<div>
    <ul>
      <li>For instructions on generating reports, see <a href=${envVars.REACT_APP_COMMON_SERVICE_URL} target="_blank" rel="noopener noreferrer">Generate ISV SCRT reports for Broadcom Products.</a></li>
      <li>Use your Broadcom Support Site ID in the Customer Number field of the SCRT. Click <a href=${envVars.REACT_APP_ECX_SUPPORT_ID_ARTICLE} target="_blank" rel="noopener noreferrer">here</a> to find your Support Site ID.</li>
      <li>Upload reports in CSV or TXT format.</li>
      <li>When you edit the report to fill in the “(required)” fields, DO NOT use a spreadsheet program. Use an ISPF editor before you download the report, or use a plain text editor on your local machine.</li>
      <li>Valid characters for file names include a-z A-Z 0-9 . _ -</li>
      <li>Single or multiple (concatenated) reports are accepted.</li>
    </ul>
    <p><strong>Important!</strong> The email address from which you send the report must be associated with the Broadcom Support Site ID that is listed in the SCRT report. 
    Do not use a personal email address or a generic automation service account. If the email address is not associated with a Support site ID, the report submission fails and you may not see a notification of the failure.</p>
    <p>For more information about emailing your reports, see the <a href=${envVars.REACT_APP_TECH_DOCS_URL} target="_blank" rel="noopener noreferrer">Broadcom SCRT Report Management</a> documentation.</p>
    </div>
    `,
      show: true,
      id: 1,
    },
  ];
};

export const AUDIT_QUESTIONS: any = (envVars: any) => {
  return [
    {
      question: `This repository is for internal use only, to submit SCRT reports for audit analysis.`,
      answer: `<div>
    <ul>
      <li>To Upload an IBM or ISV SCRT Report.</li>
      <li>The Pass IBM Validation field identifies when an ISV Report passes the IBM validation check (represented by 'Y'). N/A identifies that the report is an IBM Report, as such the IBM validation check is not used.</li>
      <li>The SCRT report must be in .csv or .txt format.</li>
      <li>Single or multiple (concatenated) SCRT reports are accepted.</li>
      <li>Be sure to map SCRT Reports that do not have a 'Support Site ID' listed in the column by going to Your Profile Name, then Audit Mapping.</li>
    </ul>
    </div>
    `,
      show: true,
      id: 1,
    },
  ];
};

export const DROPDOWN_OPTIONS = [
  { label: "Select Source", value: "" },
  { label: "Website", value: "Website" },
  { label: "Mailbox", value: "Mailbox" },
];

export const SOURCE_OBJ = {
  mwp_mailbox: "Mailbox",
  "access db": "Mailbox",
  scrt_mailbox: "Mailbox",
  CRONJOB: "Website",
  website: "Website",
};

export const REPOSITORY_PAGETITLE = "Repository";
export const AUDIT_PAGETITLE = "Audit Repository";
export const WEBSITE = "website";
export const SCRT_AUDIT = "scrt_audit";
export const REPOSITORY = "Repository";
export const AUDIT_REPOSITORY = "AuditRepository";
