export const HELP_TABS = {
  faqs: "Frequently Asked Questions",
  documentation: "Documentation",
  messages: "Messages",
};

export const PAGE_TITLES: any = {
  faqs: "This page answers frequently asked questions about uploading and managing Broadcom ISV SCRT reports.",
  messages:
    "Search this page for messages that you encounter when using Broadcom SCRT Report Management.",
};

export const HELP_TABS_LIST = [
  {
    display: "Frequently Asked Questions",
    show: true,
    navPath: HELP_TABS.faqs,
  },
  {
    display: "Documentation",
    show: true,
    navPath: HELP_TABS.documentation,
  },
  {
    display: "Messages",
    show: true,
    navPath: HELP_TABS.messages,
  },
];

export const FAQ_LIST: any = (envVars: any) => {
  return [
    {
      question: "How do I generate a Broadcom ISV SCRT report?",
      answer: `Click <a href=${envVars.REACT_APP_COMMON_SERVICE_URL} target="_blank" rel="noreferrer">here</a> for information.`,
      show: true,
      id: 1,
    },
    {
      question: "How do I create a concatenated SCRT report?",
      answer: `<div>
    <p>
    To submit multiple reports in a single file, create a concatenated report. Update your SCRT JCL to change the output file from a partitioned data set to a sequential data set.
    </p>
  </div>`,
      show: true,
      id: 3,
    },
    {
      question: "How do I submit an SCRT report?",
      answer: `<div>
      <p>You can submit your Broadcom ISV SCRT reports in two ways:</p>
      <ul>
        <li>
          <p>Upload the reports on the <a href="./repository">Repository</a> page. Use the <b>+Upload SCRT</b> button to select a file, or drag and drop the file onto the indicated area.</p>
        </li>
        <li>
          Email the report to <a href="mailto:${envVars.SUBMISSION_MAILBOX}">${envVars.SUBMISSION_MAILBOX}</a>. You can email the report directly from z/OS, or you can download it to your local computer and email the report from there.
          The email address from which you send the report must be associated with the Broadcom Support Site ID that is listed in the SCRT report. For more information about emailing your reports, see the <a href=${envVars.REACT_APP_TECH_DOCS_URL} target="_blank" rel="noopener noreferrer">Broadcom SCRT Report Management</a> documentation.
        </li> 
      </ul>
      <p>When you submit the report, Broadcom SCRT uses an IBM-supplied utility to verify that the report format has not been modified. The report is then added to the secure repository.</p>
      <p>If you upload the file directly to the Repository page, status messages indicate whether the report uploads successfully, is a duplicate, or includes errors. These messages disappear when the next report is uploaded, so jot down any messages that you want to review and resolve larger.</p>
      <p>If you email the report, you receive automated emails that indicate whether the report has been submitted successfully, is a duplicate, or includes errors.</p>
      </div>`,
      show: true,
      id: 4,
    },
    {
      question: "How do I upload other documents?",
      answer: `<div>
    <p>
    You can upload documents other than Broadcom ISV SCRT reports, such as reports for non-z/OS product usage, on the <a href="./additional-mainframe-reporting">Additional Mainframe Reporting</a> page. Use the <b>+Upload Report</b> button to select a file, or drag and drop the file onto the indicated area.
    </p>
  </div>`,
      show: true,
      id: 5,
    },
    {
      question: "What file types are accepted?",
      answer: `<div>
    <p>Broadcom ISV SCRT reports must be in CSV or TXT format.</p>
    <p>Documents that are uploaded to the <a href="./additional-mainframe-reporting">Additional Mainframe Reporting</a> page must be in TXT, DOCX, XLSX, or PDF format.</p>
  </div>`,
      show: true,
      id: 6,
    },
    {
      question: "What utility is used to verify an SCRT report?",
      answer: `<div>
    <p>
    IBM SCRT ISV Report Validation Utility 20200922-1947
    </p>
  </div>`,
      show: true,
      id: 7,
    },
    {
      question: "What are common SCRT validation errors?",
      answer: `<div>
      <p>SCRT report validation errors are usually due to the following reasons:</p>
      <ul>
        <li><p>Modifying the report format after generation. DO NOT save or export the report from a spreadsheet program. To edit the “(required)” fields in the report, use an ISPF editor before you download the report locally, or use a plain text editor on your local machine.</p></li>
        <li>
        <p>Specifying an invalid customer number in your report. Enter your Broadcom Support Site ID for the customer number. Click <a href="${envVars.REACT_APP_ECX_SUPPORT_ID_ARTICLE}" target="_blank" rel="noopener">here</a> to find your Broadcom Support Site ID.
        <p>
          <b>NOTE:</b> Do not edit the CUSTOMER_NUMBER= field in the generated report, because this causes the report to fail validation. Update the field in your CAISCRT JCL and regenerate the report.
        </p>
        </p>
        </li>
        <li>
          <p>Mismatched code pages. The same code page must be used for all SCRT report activities. Check the following code page settings:</p>
          <ul>
            <li>The code page that your 3270 emulator uses</li>
            <li>The code page that is specified as your java encoding option</li>
            <li>The code page that is used to download the SCRT report</li>
            <li>The code page that is used to email the report</li>
          </ul>
          <p></p>
          <p>For more information about code pages, see <a href="${envVars.REACT_APP_CODE_PAGE_KNOWLEDGE}" target="_blank" rel="noopener">this</a> knowledge document.</p>
        </li>
        <li><p>Including an E7 section in your report. Remove the ISV_SHOW_CONTAINERS statement from the SPECIAL DD statement to create a report without this section.</p></li>
        <li><p>Including a V5 section in your report. Remove the Generate_Detailed_Data statement from the SPECIAL DD statement and create a report without this section.</p></li>
        <li><p>Submitting a report that has already been submitted.</p></li>
        <li><p>Submitting an IBM SCRT report. The website only accepts Broadcom ISV SCRT reports.</p></li>
        <li><p>Using a personal email or a generic automation service account when submitting the report by email. 
        The email address from which you send the report must be associated with the Broadcom Support Site ID that is listed in the SCRT report. 
        For more information about emailing your reports, see the <a href=${envVars.REACT_APP_TECH_DOCS_URL} target="_blank" rel="noopener noreferrer">Broadcom SCRT Report Management</a> documentation.</p></li>
    </ul>
  </div>`,
      show: true,
      id: 8,
    },
    {
      question: "How do I know my SCRT report has been submitted successfully?",
      answer: `<div>
    <p>
    The <a href="./repository">Repository</a> displays a confirmation message on successful report submission. A confirmation email is also sent. This email serves as a record of submission.
    </p>
  </div>`,
      show: true,
      id: 9,
    },
    {
      question: "Do I have to download an SCRT report to see the contents?",
      answer: `<div>
    <p>
    You can preview a summary of the report on the <a href="./repository">Repository</a> page. Select the preview icon next to the report to see the following information:
    </p>
   <ul>
      <li>Report details, including the upload date, reporting period, customer name, and contact information.</li>
      <li>Machine details such as the serial number, machine type and model, machine rated capacity (MSUs), and the peak four-hour average. This average is the central processor complex (CPC) value from the N5 section of the report.</li>
      <li>The LPARs and the total MSUs that each LPAR used. The totals are obtained from the N7 section of the report.</li>
   </ul>
   <p>To view the entire report, select the <b>Download Report</b> icon next to the report.</p>
  </div>`,
      show: true,
      id: 10,
    },
    {
      question: "How do I search for an SCRT report?",
      answer: `<div>
    <p>
    Search for reports on the <a href="./repository">Repository</a> page. All fields on this page are searchable.
    </p>
    <p><b>Search by Date</b></p>
    <p>The following fields provide calendar icons that let you select dates for searching:</p>
    <ul>
      <li><b>Uploaded:</b> Searches for reports that were uploaded on the specified date or date range.</li>
      <li><b>Period Start:</b> Searches for reports that start on the specified date.</li>
      <li><b>Period End:</b> Searches for reports that end on the specified date.</li>
    </ul>
    <p>
    When you select the calendar icon in any of these fields, you can select a date or date range in the following ways:
    </p>
    <ul>
      <li>Select Today to select the current date.</li>
      <li>Select a single date.</li>
      <li>Select a start date and end date to specify a date range.</li>
    </ul>
    <p>After you select a date or date range, select the calendar icon again or select any area outside the calendar to close it.</p>
    <p><b>Search by Other Criteria</b></p>
    <p>You can also use the following fields to search for a report:</p>
    <ul>
      <li><b>Source:</b> Searches for reports that were submitted by the specified method (uploaded directly to the website, or submitted through email).</li>
      <li><b>Uploaded by:</b> Searches for reports that were uploaded by a specific name and email address.</li>
      <li><b>Submitted by:</b> Searches for reports that were created by a specific customer, as shown in the report.</li>
      <li><b>Customer Name:</b> Searches for reports with a specific customer name, as shown in the SCRT report.</li>
      <li><b>Serial Number:</b> Searches for reports with a specific serial number for a z Systems processor. If a report includes more than one serial number, a VIEW ALL link appears in the report row. Select VIEW ALL to select a serial number.</li>
    </ul>
  </div>`,
      show: true,
      id: 11,
    },
    {
      question: "How do I download an SCRT report?",
      answer: `<div>
    <p>
    Download Broadcom ISV SCRT reports from the 
      <a href="./repository">Repository</a> page.
    </p>
    <p>
    To download a single report, select the download icon next to the report.
    </p>
    <p>
    To download multiple reports:
    <ol>
      <li>Select the check box for each report.</li>
      <li>Select the download icon above the table. The selected reports are compressed into a ZIP file and downloaded.</li>
    </ol>
    </p>
  </div>`,
      show: true,
      id: 12,
    },
    {
      question: "How do I delete an SCRT report?",
      answer: `<div>
      <p>If you submit a Broadcom ISV SCRT report in error, you have a limited window of time in which you can delete it. Your contract determines the size of this window. Most contracts allow you to delete an SCRT report between the second and tenth days of the month. After that time, the report submission is final and cannot be changed.</p>
      <p>You can only delete the Broadcom ISV SCRT reports that you submit. If you need to delete a report that was submitted by another user, contact your SCRT Account Administrator.</p>
      <p>To delete a report, select <a href="./repository">Repository</a> from the navigation bar, locate the report, and select the delete icon next to it.</p>
      </div>`,
      show: true,
      id: 13,
    },
    {
      question: "How do I get assigned to a customer site ID?",
      answer: `<div>
      <p>You must be assigned to a Broadcom Support Site ID before you can submit, view, download, or delete reports for that site. You can be assigned to more than one site ID.</p>
      <p>To request access to a site ID, select your name in the upper right corner of the Broadcom SCRT window, select Profile, and then select the <b>Request Additional Site Access</b> tab. You can then follow the prompts to specify a site ID and request access.<p>
      </div>`,
      show: true,
      id: 14,
    },
    {
      question: "Where can I see my assigned customer site IDs?",
      answer: `<div>
      <p>Select your name in the upper right corner of the Broadcom SCRT window, then select <a href="./profile">Profile</a>. This page shows your assigned Broadcom Support Site IDs.<p>
      </div>`,
      show: true,
      id: 14,
    },
    {
      question: "Where can I see my assigned SCRT Account Administrators?",
      answer: `<div>
      <p>To find out who your SCRT Account Administrator is, select your name in the upper right corner of the Broadcom SCRT window, then select <a href="./profile">Profile</a>. This page shows your assigned SCRT Account Administrators.<p>
      </div>`,
      show: true,
      id: 15,
    },
    {
      question: "How do I view the mainframe consumption for my site?",
      answer: `<div>
      <p>If you are a Mainframe Consumption Licensing (MCL) Customer, you can view your monthly MSU consumption and quickly identify whether any Broadcom ISV SCRT reports have not been submitted.</p>
      <p>Select <a href="./overview">Overview</a> from the navigation bar to see the past 12 months of consumption data for your site in comparison to your baseline. Use the <b>Reporting Period</b> drop-down to view previous reporting periods.
      You can also scroll down past the graph to see the active machine serial numbers that were included in the report for each month, and see the LPARs that were excluded for each month.
      <p>
      </div>`,
      show: true,
      id: 16,
    },
    {
      question: "How do I add or remove LPARs from the excluded LPAR list?",
      answer: `<div>
      <p>If you are a Mainframe Consumption Licensing (MCL) Customer, you can view your monthly MSU consumption and see which LPARs have been excluded from Broadcom ISV SCRT reporting.</p>
      <p>To add or remove LPARs from the billing exclusion list for a reporting period, open a Broadcom Support case and complete the required fields. Provide the following information:<p>
      <ul>
        <li>In the Select Case Type field, select <b>Broadcom Standard</b>.</li>
        <li>In the Product field, select <b>CCS</b> (Common Components and Services).</li>
        <li>In the Subject field, enter <b>MCL LPAR Change Request.</b></li>
        <li>In the Description field, copy and paste the following text and fill in your information:</li>
      </ul>
        <div>Contact Name (first and last):</div>
        <div>Contact Email:</div>
        <div>Contact Phone:</div>
        <br />
        <div>Contract Number (if known):</div>
        <div>Explanation of change: (for example, “Adding dev/test LPARs for upcoming failover activities.”)</div>
        <div class="help-table">
        <table>
        <tr>
          <th>Date to Apply LPAR Change</th>
          <th>LPAR Name</th> 
          <th>Change Type</th>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>(for example, “Add” or “Remove”)</td>
        </tr>
        <tr>
          <td><br /></td>
          <td><br /></td>
          <td><br /></td>
        </tr>
        <tr>
        <td><br /></td>
        <td><br /></td>
        <td><br /></td>
        </tr>
      </table>
        </div>
      </div>`,
      show: true,
      id: 17,
    },
    {
      question: "How do I manage the users for a site?",
      answer: `<div>
      <p>If you are an SCRT Account Administrator, you can manage the users for your assigned sites.</p>
      <p>Select <a href=${envVars.REACT_APP_ECX_USERS_ADMINISTRATION} target="_blank" rel="noreferrer">
      SCRT User Administration
    </a>from the navigation bar, then perform following tasks as needed:<p>
    <ul>
      <li>To add a user, select <b>Add User</b> and follow the prompts to add the user.</li>
      <li>To manage a user, select <b>Users and Permissions</b>,  use the filter fields to display the user, and select the edit icon next to the user name.<br>You can then make the following changes:</li>
      <ul>
        <li>Grant or revoke user access to Broadcom Support Site IDs. When you revoke access to a site ID, the user can no longer submit, view, or download reports for that site ID.</li>
        <li>Grant SCRT Account Administrator permissions.</li>
      </ul>
      <li>To manage pending access requests from users, select <b>Pending Requests</b>. You can then select the <b>Manage Request</b> icon next to a user to view their access request and approve or reject it.</li>
    </ul>
      </div>`,
      show: true,
      id: 18,
    },
    {
      question: "How can I view and change the status of my machine serial numbers?",
      answer: `<div>
      <p>If you are an SCRT Account Administrator, you can review the machine serial numbers for a Broadcom Support Site ID to see which ones are active or inactive. You can also change the status of a serial number.</p>
      <ol>
        <li>Select <a href="./sites">Sites</a> from the navigation bar.</li>
        <li>Select the Broadcom Support Site ID for the serial number.</li>
        <li>The site details appear, including the status of its machine serial numbers.</li>
        <li>Select the Modify button.</li>
        <li>Select a new status (Active, Decommissioned, or DR/Failover/Swapped) for one or more of the serial numbers. If you select Decommissioned or DR/Failover/Swapped, also select a reason in the Feedback field.</li>
      </ol>
      <p>For status definitions and an explanation of how decommission dates are recorded in the system, see <a href="https://techdocs.broadcom.com/us/en/ca-mainframe-software/intelligent-operations/broadcom-scrt-report-management/1-0/administrating.html" target="_blank" rel="noreferrer">Administrating</a> in the Broadcom SCRT Report Management documentation.</p>
      </div>`,
      show: true,
      id: 19,
    },
    {
      question: "How do I view the activity logs for a site?",
      answer: `<div>
      <p>If you are an SCRT Account Administrator, you can view the activity logs for your assigned Broadcom Support Site IDs.</p>
      <ol>
        <li>Select <a href="./repository">Repository</a> from the navigation bar.</li>
        <li>Select the log icon above the report list.</li>
        <li>Select the tab for the desired log:
        <ul>
          <li>Repository Logs show reports that have been uploaded (or that have failed to upload, with the reason for failure).</li>
          <li>Sign In/Sign Out Logs show who has logged in and out of Broadcom SCRT.</li>
          <li>Reminder Logs show which sites have been sent a reminder email to submit a report, along with the serial number and the missing report dates.</li>
        </ul>
        </li>
      </ol>
      </div>`,
      show: true,
      id: 20,
    },
    {
      question: "How do I contact Support?",
      answer: `<div>
      <p>To report a problem with the SCRT website, open a <a href=${
        envVars.REACT_APP_SUPPORT_URL
      } target='_blank'>Broadcom Support case</a> and complete the required fields. Provide the following information:</p>
      <ul>
      <li>In the Select Case Type field, select <b>Global Customer Assistance.</b></li>
      <li>In the Product field, select <b>Support Portal.</b></li>
      <li>In the Subject field, enter SCRT website (<a href=${envVars.ui} target='_blank'>${
        envVars.ui.split("https://")[1]
      }</a>) technical issue.</li>
      <li>In the Description field, copy and paste the following text and fill in your information:
      <br>Contact Name (first and last):
      <br>Contact Email:
      <br>Contact Phone:
      <br>Contract Number (if known):
      <br>Explanation of problem:
      </li>
      </ul>
      </div> 
      `,
      show: true,
      id: 21,
    },
  ];
};

export const MESSAGES_LIST: any = (envVars: any) => {
  return [
    {
      question:
        "<i>&customer_number</i> present in the SCRT report is not associated with any Broadcom Support Site ID.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>The Broadcom SCRT report contains a customer number that is not associated with a Broadcom Support Site ID.</p>
      <b>Action</b>
      <p>
      In your CAISCRT JCL, update the CUSTOMER_NUMBER parameter to specify your Broadcom Support Site ID. Do not specify your IBM Site ID. If you have more than one Broadcom Support Site ID, only specify one of them. Any of your Broadcom Support Site IDs will work. Then regenerate and resubmit the report. 
      <br><br>
      If you receive this error message again, contact your local SCRT Account Administrator. If you do not know who your SCRT Account Administrator is, select your name in the upper right corner of the Broadcom SCRT window, then select Profile. You can also contact Broadcom Support for assistance.
      </p>
      </div>
      `,
      show: true,
      id: 1,
    },
    {
      question: "<i>&num</i> out of <i>&num</i> reports already exist.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>One or more of the Broadcom SCRT reports in the concatenated report file have already been submitted to the repository. You cannot resubmit an existing report. The existing reports are not uploaded to the repository. The other reports in the file are uploaded.</p>
      <b>Action</b>
      <p>
      Compare the concatenated report file to the list of logs on the Repository page to identify which report has already been submitted.<br>Then perform one of the following actions: 
      <br>
      <ul>
        <li>Delete the report from the Repository page and resubmit the file.</li>
        <li>Recreate the concatenated file without that report and then resubmit the file.</li>
      </ul>
      </p>
      </div>
      `,
      show: true,
      id: 2,
    },
    {
      question:
        "An encoding error has been detected and some characters have been corrupted. The report cannot be accepted. See this knowledge document for more information.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>
      The validation program has detected an encoding error that corrupted the file contents. This error happens when the same code page is not used across all SCRT reporting activities. For example, if one code page is used to generate the report and a different code page is used by the SMTP server when you email the report, this error occurs.
      <br><br>This message is preceded by a brief description of the encoding error that was detected.
      </p>
      <b>Action</b>
      <p>
      Select the link in the message to review a knowledge document that describes the error and how to resolve it. 
      <br><br>Also review the encoding instructions in the CAISCRT JCL or in <a href="https://techdocs.broadcom.com/us/en/ca-mainframe-software/traditional-management/ca-common-services-for-z-os/15-0/administrating/resource-initialization-manager-cairim/generate-isv-scrt-reports-for-broadcom-products.html" target="_blank" rel="noreferrer">Generate a Broadcom SCRT Reports for Broadcom Products</a>.
      <br><br>Also check the following code page settings and verify that they match:
      <br>
      <ul>
        <li>The code page that your 3270 emulator uses.</li>
        <li>The code page that is specified as your java encoding option.</li>
        <li>The code page that is used to download the SCRT report.</li>
        <li>The code page that is used to email the report.</li>
      </ul>
      </p>
      </div>
      `,
      show: true,
      id: 3,
    },
    {
      question:
        "Broadcom Support Site ID <i>&site</i> not found. Please enter a valid Broadcom Support Site ID.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You have entered an invalid Broadcom Support Site ID.</p>
      <b>Action</b>
      <p>Enter a valid site ID.</p>
      </div>
      `,
      show: true,
      id: 4,
    },
    {
      question: "Contact Support.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>An error has occurred that requires assistance from Broadcom Support. This message is preceded by an error message that provides more details about what happened.</p>
      <b>Action</b>
      <p>Contact Broadcom Support and provide the message details.</p>
      </div>
      `,
      show: true,
      id: 5,
    },
    {
      question:
        "Customer Number for <i>&num</i> out of <i>&num</i> reports is not associated with any Broadcom Support Site ID.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>One or more of the Broadcom SCRT reports in the concatenated file contain a customer number that is not associated with a Broadcom Support Site ID. Only the reports with valid customer numbers are uploaded.</p>
      <b>Action</b>
      <p>In your CAISCRT JCL, update the CUSTOMER_NUMBER parameter to specify your Broadcom Support Site ID. Do not specify your IBM Site ID. If you have more than one Broadcom Support Site ID, only specify one of them. Any of your Broadcom Support Site IDs will work. Then regenerate and resubmit the reports. 
      <br><br>
      If you receive this error message again, contact your local SCRT Account Administrator. If you do not know who your SCRT Account Administrator is, select your name in the upper right corner of the Broadcom SCRT window, then select Profile. You can also contact Broadcom Support for assistance.
      </p>
      </div>
      `,
      show: true,
      id: 6,
    },
    {
      question:
        "Customer number not present in report. Enter your Broadcom Support site ID for the customer number.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You tried to upload a Broadcom SCRT report that does not contain a customer number.</p>
      <b>Action</b>
      <p>In your CAISCRT JCL, update the CUSTOMER_NUMBER parameter to specify your Broadcom Support Site ID. Do not specify your IBM Site ID. Then regenerate and resubmit the report.</p>
      </div>
      `,
      show: true,
      id: 7,
    },
    {
      question: "Failed to upload the report. Contact Support.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>An internal error has occurred.</p>
      <b>Action</b>
      <p>Contact Broadcom Support.</p>
      </div>
      `,
      show: true,
      id: 8,
    },
    {
      question:
        "Failed to upload the report. SCRT report detected. SCRT reports are submitted on the Repository page only.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You are trying to upload a Broadcom SCRT report to the Additional Mainframe Reporting page. This page is not intended for SCRT reports.</p>
      <b>Action</b>
      <p>Select Repository from the navigation bar to display the Repository page. Then upload your SCRT report there.</p>
      </div>
      `,
      show: true,
      id: 9,
    },
    {
      question: "Failed to upload the report. The file is empty.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You tried to upload an empty file to the Additional Mainframe Reporting page.</p>
      <b>Action</b>
      <p>Verify that your file contains data before you upload it.</p>
      </div>
      `,
      show: true,
      id: 10,
    },
    {
      question:
        "For <i>&num</i> out of <i>&num</i> reports, email ID does not have required access to submit SCRT reports. Please contact your SCRT Account Administrator.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You do not have submission permissions for one or more of the Broadcom Support Site IDs in the concatenated report file. You are allocated to the site ID, but you do not have permission to submit reports for that site ID. The reports for your permitted site IDs are uploaded to the repository. The other reports are not uploaded.</p>
      <b>Action</b>
      <p>To request submission permissions for a Broadcom Support Site ID, contact your local SCRT Account Administrator. If you do not know who your SCRT Account Administrator is, select your name in the upper right corner of the Broadcom SCRT window, then select Profile. You can also contact Broadcom Support for assistance.</p>
      </div>
      `,
      show: true,
      id: 11,
    },
    {
      question:
        "For <i>&num</i> out of <i>&num</i> reports, you are not allocated to the Broadcom Support Site ID. Please contact your SCRT Account Administrator.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You are not allocated to one or more of the Broadcom Support Site IDs in the concatenated report file. You must be assigned to a site ID before you can submit, view, download, or delete reports for that site ID. The reports for your allocated site IDs are uploaded to the repository. The other reports are not uploaded.</p>
      <b>Action</b>
      <p>To request allocation to a Broadcom Support Site ID, select your name in the upper right corner of the Broadcom SCRT window, select Profile, then select Request Additional Site Access. Follow the prompts to specify the site ID and submit your access request.</p>
      </div>
      `,
      show: true,
      id: 12,
    },
    {
      question: "IBM validation failed. See the Frequently Asked Questions in the online help.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You tried to submit a Broadcom SCRT report, but it failed validation.</p>
      <b>Action</b>
      <p>On the Broadcom SCRT Report Management website, select the help icon, then review the Frequently Asked Questions to determine the cause of failure. Then correct the problem and resubmit the report.</p>
      </div>
      `,
      show: true,
      id: 13,
    },
    {
      question: "Invalid customer name.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>The report specifies an invalid customer name. The name must begin and end with a letter or number. The rest of the name can contain letters, numbers, spaces, dots, and hyphens.</p>
      <b>Action</b>
      <p>Verify that your report specifies a valid customer name. If necessary, regenerate the report.</p>
      </div>
      `,
      show: true,
      id: 14,
    },
    {
      question:
        "Invalid file. The report may have been saved or exported from a spreadsheet program, which modified the report and rendered it invalid. Only submit the unmodified file.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>The Broadcom SCRT report that you tried to submit is invalid because its format was changed after it was generated. This modification typically happens when you open the report in a spreadsheet program and then save or export the report. Doing so modifies the report format, even when you save or export it to CSV or TXT format.</p>
      <b>Action</b>
      <p>To complete the “(required)” fields in the generated report, edit it with an ISPF editor before you download it locally, or edit it with a plain text editor on your local machine. Only edit the “(required)” fields. If you edit anything else, the report fails validation.</p>
      </div>
      `,
      show: true,
      id: 15,
    },
    {
      question: "Invalid file format. Only .csv and .txt files are allowed.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You tried to submit a Broadcom SCRT report with an unsupported file format to the Repository page. The report must be in CSV or TXT format.</p>
      <b>Action</b>
      <p>If you are submitting a Broadcom SCRT report, verify that it is in a supported file format. If you are submitting any other document, you must submit it on the Additional Mainframe Reporting page.</p>
      </div>
      `,
      show: true,
      id: 16,
    },
    {
      question:
        "Invalid file format. Please upload file in the following formats only: .txt, .docx, .xlsx, .pdf",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>The file you are trying to upload to the Additional Mainframe Reporting page has an unsupported format. The file must be in TXT, DOCX, XLSX, or PDF format.</p>
      <b>Action</b>
      <p>Save the file in a supported format and then upload it.</p>
      </div>
      `,
      show: true,
      id: 17,
    },
    {
      question: "Machine serial number is missing from the report.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You tried to upload a Broadcom SCRT report that does not contain the machine serial number. This information is required for metrics purposes.</p>
      <b>Action</b>
      <p>Regenerate and resubmit the report.</p>
      </div>
      `,
      show: true,
      id: 18,
    },
    {
      question:
        "Multiplex reports are not accepted. Run the SCRT without the Country_Multiplex_Pricing control statement. To submit multiple reports in a single file, use concatenated reports.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You tried to submit a multiplex SCRT report. These reports are not supported.</p>
      <b>Action</b>
      <p>Only use the CAISCRT JCL that is provided in <i>hlq</i>.CAW0JCL to generate Broadcom SCRT reports. Verify that your JCL does not include a Country_Multiplex_Pricing statement in the SPECIAL DD statement. Then regenerate the report and resubmit it..</p>
      </div>
      `,
      show: true,
      id: 19,
    },
    {
      question: "Report delete unsuccessful.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You are trying to delete a report that has already been deleted.</p>
      <b>Action</b>
      <p>Refresh the web page to see a current list.</p>
      </div>
      `,
      show: true,
      id: 20,
    },
    {
      question: "Report uploaded successfully.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>The Broadcom SCRT report has been uploaded successfully to the Repository page.</p>
      <b>Action</b>
      <p>None.</p>
      </div>
      `,
      show: true,
      id: 21,
    },
    {
      question: "Reporting period not present in <i>&file</i> file.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>Your Broadcom SCRT report does not specify a reporting period.</p>
      <b>Action</b>
      <p>Verify that your report contains the “Reporting Period” row. If this row is missing, regenerate the report.</p>
      </div>
      `,
      show: true,
      id: 22,
    },
    {
      question: "SCRT report deleted.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You have successfully deleted a Broadcom SCRT report from the Repository page.</p>
      <b>Action</b>
      <p>None.</p>
      </div>
      `,
      show: true,
      id: 23,
    },
    {
      question: "The file size exceeds 16 MB.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You tried to upload a file that is larger than the 16 MB limit.</p>
      <b>Action</b>
      <p>Decrease the file size and try again.</p>
      </div>
      `,
      show: true,
      id: 24,
    },
    {
      question: "The ISV SCRT report is missing.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You tried to upload an empty Broadcom SCRT report file.</p>
      <b>Action</b>
      <p>Verify that your file contains data before you upload it. If necessary, regenerate the report.</p>
      </div>
      `,
      show: true,
      id: 25,
    },
    {
      question: "The report is an IBM SCRT report. The website only accepts ISV SCRT reports.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You tried to submit an IBM SCRT report to the Repository page.</p>
      <b>Action</b>
      <p>Only submit Broadcom SCRT reports to the Repository page.</p>
      </div>
      `,
      show: true,
      id: 26,
    },
    {
      question:
        "The report with Machine Serial Number: <i>&num</i> has been submitted and processed successfully.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>The specified Broadcom SCRT report has passed validation and been saved to the repository.</p>
      <b>Action</b>
      <p>None.</p>
      </div>
      `,
      show: true,
      id: 27,
    },
    {
      question: `The submitted report has at least one "(required)" placeholder field that must be replaced. See "Using placeholder fields in the sub-capacity report" in the SCRT Users Guide for more information.`,
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>Your Broadcom SCRT report has at least one “(required)” field in which you must enter comments.</p>
      <b>Action</b>
      <p>
      Edit the report to add your comments in the “(required)” fields. Either edit the report with an ISPF editor before you download it, or edit it with a plain text editor on your local machine. Then submit the report.
      <br><br><b>Important!</b> Do not edit the report in any other program. For example, do not edit the report in a spreadsheet program and then export it. Doing so modifies the report format, even when you export it to CSV or TXT format, and the report fails validation when you try to submit it. Also, only edit the “(required)” fields. If you edit anything else, the report fails validation.
      </p>
      </div>
      `,
      show: true,
      id: 28,
    },
    {
      question:
        "The website does not accept reports with a V5 section. Remove the Generate_Detailed_Data statement from the SPECIAL DD statement and create a report without this section.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You tried to submit a Broadcom SCRT report that contains a V5 section. This section is not accepted in Broadcom SCRT reports.</p>
      <b>Action</b>
      <p>Only use the CAISCRT JCL that is provided in <i>hlq</i>.CAW0JCL to generate Broadcom SCRT reports. Verify that your JCL does not include a Generate_Detailed_Data statement in the SPECIAL DD statement. Then regenerate the report and resubmit it.</p>
      </div>
      `,
      show: true,
      id: 29,
    },
    {
      question:
        "The website does not accept reports with an E7 section. Remove the ISV_SHOW_CONTAINERS statement from the SPECIAL DD statement and create a report without this section.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You tried to submit a Broadcom SCRT report that contains an E7 section. This section is no longer accepted in Broadcom SCRT reports.</p>
      <b>Action</b>
      <p>Update your CAISCRT JCL to remove the ISV_SHOW_CONTAINERS statement from the SPECIAL DD statement. Then regenerate the report and resubmit it.</p>
      </div>
      `,
      show: true,
      id: 30,
    },
    {
      question:
        "This Email ID does not have required access to submit SCRT reports. Please contact your SCRT Account Administrator.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You are trying to submit a Broadcom SCRT report for a Broadcom Support Site ID that you do not have submission permissions for. You are allocated to that site ID, but you do not have permission to submit reports for that site ID.</p>
      <b>Action</b>
      <p>To request submission permissions for a Broadcom Support Site ID, contact your local SCRT Account Administrator. If you do not know who your SCRT Account Administrator is, select your name in the upper right corner of the Broadcom SCRT window, then select Profile. You can also contact Broadcom Support for assistance.</p>
      </div>
      `,
      show: true,
      id: 31,
    },
    {
      question:
        "To access the Broadcom SCRT Report Management website, you need to be an enterprise user. Please complete your profile by clicking here.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You must be an enterprise user to use the Broadcom SCRT​​​ Report Management website.</p>
      <b>Action</b>
      <p>Select the link and follow the prompts to complete your enterprise user profile and request access to Broadcom SCRT Report Management..</p>
      </div>
      `,
      show: true,
      id: 32,
    },
    {
      question: "Unable to process the ISV SCRT report.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You are trying to upload a Broadcom SCRT report, but the server is temporarily unavailable.</p>
      <b>Action</b>
      <p>Try again later.</p>
      </div>
      `,
      show: true,
      id: 33,
    },
    {
      question:
        "UTC time not present in report. In the SPECIAL DD statement, update the Report_Time parameter to specify UTC time.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You tried to submit a Broadcom SCRT report that does not specify the report time as UTC time. Mainframe Consumption Licensing (MCL) customers must use UTC time to keep your reports consistent from month to month.</p>
      <b>Action</b>
      <p>Regenerate the report with the time specified as UTC time. Then resubmit the report.
      <br><br>
      For detailed information about specifying the report time, see <a href="https://techdocs.broadcom.com/us/en/ca-mainframe-software/traditional-management/ca-common-services-for-z-os/15-0/administrating/resource-initialization-manager-cairim/generate-isv-scrt-reports-for-broadcom-products.html" target="_blank" rel="noreferrer">Generate ISV SCRT Reports for Broadcom Products</a>.</p>
      </div>
      `,
      show: false,
      id: 34,
    },
    {
      question:
        "We apologize for any inconvenience. The application data is being refreshed, which may cause a delay in data displayed. Please check back later. Thank you.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>The Broadcom SCRT Report Management website is temporarily unavailable.</p>
      <b>Action</b>
      <p>Try again later.</p>
      </div>
      `,
      show: true,
      id: 35,
    },
    {
      question:
        "You already have access to Broadcom Support Site ID <i>&site</i>. Please enter a different site ID.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You already have access to the specified Broadcom Support Site ID.</p>
      <b>Action</b>
      <p>If you need access to another Broadcom Support Site ID, enter that site ID instead. Otherwise, you do not need to do anything.</p>
      </div>
      `,
      show: true,
      id: 36,
    },
    {
      question:
        "You are not allocated to <i>&site</i> Broadcom Support Site ID. Please contact your SCRT Account Administrator.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You must be assigned to a Broadcom Support Site ID before you can submit, view, download, or delete reports for that site ID.</p>
      <b>Action</b>
      <p>To request allocation to a Broadcom Support Site ID,  select your name in the upper right corner of the Broadcom SCRT window, select Profile, then select Request Additional Site Access. Follow the prompts to specify the site ID and submit your access request.</p>
      </div>
      `,
      show: true,
      id: 37,
    },
    {
      question:
        "You have already requested access to Broadcom Support Site ID <i>&site</i>. Please enter a different site ID.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>You have already requested access to the specified Broadcom Support Site ID.</p>
      <b>Action</b>
      <p>If you need access to another Broadcom Support Site ID, enter that site ID instead. Otherwise, you do not need to do anything.</p>
      </div>
      `,
      show: true,
      id: 39,
    },
    {
      question:
        "Your request to access the Broadcom SCRT Report website is pending approval. You can check the request status here",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>Your request to access the Broadcom SCRT Report Management website has not yet been approved.</p>
      <b>Action</b>
      <p>Click the provided link to check the status of your request.</p>
      </div>
      `,
      show: true,
      id: 40,
    },
    {
      question: "Your SCRT access is pending approval.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>Your request for access to a Broadcom Support Site ID has not yet been approved.</p>
      <b>Action</b>
      <p>Check again later, or contact one of the SCRT Account Administrators that is identified after this message.</p>
      </div>
      `,
      show: true,
      id: 41,
    },
    {
      question: "Failed to upload the report. Contact your security administrator.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>Your Broadcom SCRT report failed to upload to the Broadcom SCRT site. Your workplace may have security settings in place that prevent users from uploading files to web sites.</p>
      <b>Action</b>
      <p>Ask your security administrator to review the security protocols and policies at your workplace. You can also try emailing the report to the Broadcom SCRT site. For more information about emailing your reports, see the <a href="https://techdocs.broadcom.com/us/en/ca-mainframe-software/intelligent-operations/broadcom-scrt-report-management/1-0.html" target="_blank" rel="noreferrer">Broadcom SCRT Report Management</a> documentation.</p>
      <p>If the problem persists after the security administrator verifies that you should be able to upload and email your SCRT reports, contact <a href=${envVars.REACT_APP_SUPPORT_URL} target="_blank" rel="noreferrer">Broadcom Support</a>.</p>
      </div>
      `,
      show: true,
      id: 42,
    },
    {
      question: "Unable to upload the report. Value for field osproductid/lparname is missing in H4 section. Contact Broadcom Support.",
      answer: `
      <div className="p-4">
      <b>Reason</b>
      <p>The IBM SCRT Report failed to upload because the data in the H4 section of the report was modified or edited after it was generated.</p>
      <b>Action</b>
      <p>Regenerate the SCRT report and do not open the file. Submit the SCRT report again.</p>
      </div>
      `,
      show: true,
      id: 43,
    },
  ];
};
