import ClayForm, { ClayInput } from "@clayui/form";
import ClayIcon from "@clayui/icon";
import ClayLabel from "@clayui/label";
import { claySpriteImagePath } from "@common-services/util/claySpriteImagePath";
import { ErrorMessage, Field } from "formik";
import React, { useState } from "react";

import TextError from "./TextError";

function Tags(props: any) {
  const { label, id, name, placeholder, validate, required, helperText } = props;
  const [intervalValue, setInternalValue] = useState("");
  return (
    <div>
      {label && (
        <label htmlFor={name}>
          {required && <span className="text-red">* </span>}
          {label}
        </label>
      )}
      <Field name={name} validate={validate}>
        {({ form, field }: { form: any; field: any }) => {
          const { setFieldValue } = form;
          const { value } = field;
          const handleInputChange = (event: any) => {
            setInternalValue(event.target.value);
          };

          const handleEnterKey = (event: any) => {
            if (event.keyCode === 13) {
              addKeywords(event);
            }
          };

          const addKeywords = (event: any) => {
            if (event.target.value) {
              const addedValue = value ? value.split(",") : [];

              event.target.value
                .toUpperCase()
                .split(",")
                .forEach((newTag: any) => {
                  if (newTag.trim() && !~addedValue.indexOf(newTag.trim())) {
                    addedValue.push(newTag.trim());
                  }
                });
              setFieldValue(name, addedValue.join(","));
              setInternalValue("");
            }
          };

          const removeTag = (tag: any) => {
            if (value && value.length) {
              setFieldValue(
                name,
                value
                  .split(",")
                  .filter((tagValue: any) => tagValue !== tag)
                  .join(","),
              );
            }
          };
          return (
            <ClayForm.Group
              className={
                "form-group-sm " +
                (form.errors[name] && form.touched[name] !== undefined ? "has-error" : "")
              }
            >
              <ClayInput
                value={intervalValue}
                type="text"
                id={id}
                placeholder={placeholder || label}
                onKeyUp={handleEnterKey}
                onInput={handleInputChange}
                onBlur={addKeywords}
              />
              {value &&
                value.split(",").map((tag: any) => (
                  <ClayLabel
                    key={tag}
                    className="ecx-tag-label"
                    closeButtonProps={{
                      id: "closeId",
                      onClick: () => removeTag(tag),
                    }}
                    displayType="secondary"
                    spritemap={claySpriteImagePath()}
                  >
                    {tag}
                  </ClayLabel>
                ))}
              {helperText && (
                <p>
                  <small>
                    <ClayIcon symbol="info-circle" spritemap={claySpriteImagePath()} />
                    &nbsp;{helperText}
                  </small>
                </p>
              )}

              <ErrorMessage name={name} component={TextError} />
            </ClayForm.Group>
          );
        }}
      </Field>
    </div>
  );
}

export default Tags;
