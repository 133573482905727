import ClayLayout from "@clayui/layout";
import { ISignedinUser } from "@interfaces";
import { getSignedInUser } from "@store/userDetails/slice";
import { isAdmin, isSuperAdmin } from "@utils";
import React from "react";
import { useSelector } from "react-redux";

import Panel from "../Panel";

function FaqsList(props: any) {
  const { questions, customAccordionOpen, showHeader, headerMessage } = props;
  const signedInUser: ISignedinUser = useSelector(getSignedInUser);
  const userTabShow = isAdmin(signedInUser?.role) || isSuperAdmin(signedInUser?.role);
  return (
    <>
      {showHeader && (
        <>
          <ClayLayout.Row justify="start">
            <ClayLayout.Col xs={10} md={10} lg={10} className="mt3 pb-4 pl-4">
              <span>{headerMessage || ""}</span>
            </ClayLayout.Col>
          </ClayLayout.Row>
        </>
      )}

      {questions.map((object: any, idx: number) => {
        return (
          <div key={idx}>
            {object.show ? (
              object.id !== 11 ? (
                <Panel
                  id={object.id}
                  customAccordionOpen={customAccordionOpen}
                  data={object}
                  className={props.className || ""}
                />
              ) : object.id === 11 && !userTabShow ? (
                <></>
              ) : (
                <Panel
                  id={object.id}
                  customAccordionOpen={customAccordionOpen}
                  data={object}
                  className={props.className || ""}
                />
              )
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </>
  );
}

export { FaqsList };
