import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import { ROUTE_PATHS } from "@constants";
import { addUserEvent } from "@store/userDetails/slice";
import { deleteCookie, getCookie } from "@utils";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function Logout() {
  const envVars = useSelector(getEnvVars);
  const dispatch = useDispatch();
  const logout = async () => {
    localStorage.removeItem("scrt-local");
    if (envVars.DISABLE_OKTA === "true") {
      localStorage.setItem("email", "");
      window.location.href = ROUTE_PATHS.HOME;
    } else if (!getCookie("user")) {
      window.location.href = ROUTE_PATHS.HOME;
    } else {
      dispatch(addUserEvent("LOGOUT"))
        .then(async () => {
          localStorage.clear();
          sessionStorage.clear();
          deleteCookie("user");
          localStorage.removeItem("okta-valid-user");
          const myInit: any = {
            method: "DELETE",
            "credentials": "include"
          };
          const myRequest = new Request(envVars.okta.domain+envVars.okta.logoutPath, myInit);

          fetch(myRequest)
            .then(function (response: any) {
              return response;
            })
            .then(function (response: any) {
              window.location.href = envVars.AUTHHUB_LOGOUT_URL;
            })
            .catch(function (e: any) {
              console.log(e);
            });
        })
        .catch(async (err: any) => {
          console.log("err: ", err);
          localStorage.clear();
          sessionStorage.clear();
          deleteCookie("user");
          const myInit: any = {
            method: "DELETE",
            "credentials": "include"
          };
          const myRequest = new Request(envVars.okta.domain+envVars.okta.logoutPath, myInit);

          fetch(myRequest)
            .then(function (response: any) {
              return response;
            })
            .then(function (response: any) {
              window.location.href = envVars.AUTHHUB_LOGOUT_URL;
            })
            .catch(function (e: any) {
              console.log(e);
            });
        });
    }
  };

  useEffect(() => {
    logout();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>Logging out ...</>;
}
