import {
  getEnvVarsSet,
  updateEnvironmentVariables,
} from "@common-services/redux-helper/store/slice/initializeApp.slice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import SkeletonLoader from "../skeleton-loader/SkeletonLoader";

// Sets all environment variables in redux store
// Till the variable are not set, returns a skeleton Loader

const AppInitializer: React.FC<any> = React.memo((props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateEnvironmentVariables());
  }, [dispatch]);

  const envVarsSet = useSelector(getEnvVarsSet);
  return <React.Fragment>{envVarsSet ? props.children : <SkeletonLoader />}</React.Fragment>;
});

export default AppInitializer;
