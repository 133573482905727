import ClayButton from "@clayui/button";
import ClayCard from "@clayui/card";
import ClayForm from "@clayui/form";
import ClayLayout from "@clayui/layout";
import { FormControl, PageTitle } from "@common-components";
import { ROUTE_PATHS } from "@constants";
import { ILocalLoginForm } from "@interfaces";
import { Formik } from "formik";
import * as Yup from "yup";

function LocalLogin() {
  const pageTitle = "Login";
  const formData = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email address is invalid"),
  });

  const onSubmit = (values: ILocalLoginForm) => {
    localStorage.setItem("email", values.email);
    window.location.href = ROUTE_PATHS.REPOSITORY;
  };

  return (
    <ClayLayout.ContainerFluid view size={false}>
      <ClayLayout.Row justify="start" className="align-items-center">
        <ClayLayout.Col className="d-flex align-items-center">
          <PageTitle title={pageTitle} />
        </ClayLayout.Col>
      </ClayLayout.Row>
      <ClayLayout.Row>
        <ClayCard className="m-3 w-100">
          <ClayCard.Body className="p-4">
            <Formik
              enableReinitialize={true}
              initialValues={formData}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {(formik) => (
                <ClayForm onSubmit={formik.handleSubmit} className="m-4 mw-100">
                  <ClayLayout.Row justify="start">
                    <ClayLayout.Col>
                      <FormControl
                        control="text"
                        label="Email Id"
                        name="email"
                        required={true}
                        autoComplete={"off"}
                      />
                    </ClayLayout.Col>
                  </ClayLayout.Row>
                  <ClayLayout.Row className="flex-row-reverse">
                    <ClayButton
                      type="submit"
                      className="ml-2"
                      displayType={"primary"}
                      style={{ fontSize: "small" }}
                    >
                      Submit
                    </ClayButton>
                  </ClayLayout.Row>
                </ClayForm>
              )}
            </Formik>
          </ClayCard.Body>
        </ClayCard>
      </ClayLayout.Row>
    </ClayLayout.ContainerFluid>
  );
}
export default LocalLogin;
