import ClayTable from "@clayui/table";
import React from "react";

import { NoDataFoundIcon } from "../../icons/noDataFound";

const EmptyTable = () => {
  const col = 100;
  return (
    <ClayTable.Row>
      <ClayTable.Cell
        columnTextAlignment="center"
        colSpan={col}
        style={{ padding: "4rem 0px 4rem 0px" }}
      >
        <div>
          <NoDataFoundIcon />
        </div>
      </ClayTable.Cell>
    </ClayTable.Row>
  );
};

export { EmptyTable };
