export const PROFILE_TABS = {
  profile: "User Information",
  aSiteAccess: "Request Additional Site Access",
};

export const PROFILE_TABS_LIST = [
  {
    display: "User Information",
    show: true,
    navPath: PROFILE_TABS.profile,
  },
  {
    display: "Request Additional Site Access",
    show: true,
    navPath: PROFILE_TABS.aSiteAccess,
  },
];
