import ClayButton from "@clayui/button";
import ClayCard from "@clayui/card";
import ClayForm, { ClayInput } from "@clayui/form";
import ClayIcon from "@clayui/icon";
import ClayLayout from "@clayui/layout";
import { ConfirmSendModal, FormControl, PageTitle } from "@common-components";
import Sprite from "@common-images/clay/sprite.svg";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import { ROUTE_PATHS } from "@constants";
import { IAlertMessage } from "@interfaces";
import {
  checkSiteId,
  getSiteAccessResponse,
  getSiteId,
  getSiteIdData,
  siteAccessRequest,
  updateSiteId,
  updateSiteIdData,
} from "@store/oktaAuth.slice";
import { GET_USER_EMAIL_AUTHHUB_LOWERCASE } from "@utils";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import Alert from "./../Alert";
import {
  FIRST_TIME_USER,
  NOT_FIRST_TIME,
  NOT_FIRST_TIME_ASA,
  enterprisePending,
} from "./constants";

function OktaRedirectForm(props: any) {
  const { userData, requestType = "SA" } = props;
  const [sendAlerts, setSendAlerts] = useState([] as IAlertMessage[]);

  let inputRef: any = useRef(null);
  const form = useRef(null);
  const dispatch = useDispatch();
  const envVars = useSelector(getEnvVars);
  const siteId = useSelector(getSiteId);
  const siteIdData = useSelector(getSiteIdData);

  const emailResponse = useSelector(getSiteAccessResponse);
  const [formData, setFormData] = useState<any>({
    to: [envVars.REACT_APP_MAIL_ID_FOR_SITE_ACCESS],
    subject: `SCRT Report Management User Access Update.`,
    message: `Provide SCRT site access for the user (${GET_USER_EMAIL_AUTHHUB_LOWERCASE()})`,
  });

  const pageTitle = `Welcome to Broadcom SCRT Report Management.`;
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!!emailResponse && Object.keys(emailResponse).length > 0) {
      setSendAlerts([
        {
          type: emailResponse.success ? "success" : "error",
          message: emailResponse?.data?.message || "Something went wrong. Please try again later.",
        },
      ]);
      handleModalClose();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      if (requestType === "SA" && emailResponse.success) {
        setTimeout(() => (window.location.href = ROUTE_PATHS.OKTA_REDIRECT), 2500);
      }
    }
  }, [emailResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleConfirmSend = () => {
    document.body.style.overflow = "visible";
    dispatch(
      siteAccessRequest({
        firstTimeUser: siteIdData.firstTimeUser,
        requestType,
        supportSiteId: siteId,
        subject: formData["subject"],
      }),
    );
    handleModalClose();
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setFormData({
      to: [envVars.REACT_APP_MAIL_ID_FOR_SITE_ACCESS],
      subject: `SCRT Report Management User Access Update.`,
      message: `Provide SCRT site access for the user (${GET_USER_EMAIL_AUTHHUB_LOWERCASE()})`,
    });
  };

  const handleUploadAlertClose = () => {
    setSendAlerts([]);
  };

  const onSubmit = () => {
    setModalOpen(true);
    handleUploadAlertClose();
  };

  const validationSchema = Yup.object().shape({
    message: Yup.string()
      .trim()
      .min(1, "Minimum 1 character is required")
      .max(2000, "Maximum 2000 characters allowed"),
  });

  const handleSiteIdUpdate = () => {
    if (!!siteId) {
      dispatch(checkSiteId(siteId));
      handleUploadAlertClose();
    }
  };

  const updateSiteIdVal = (event: any) => {
    dispatch(updateSiteId(event.target.value));
    if (!event.target.value) {
      resetState();
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleSiteIdUpdate();
    }
  };

  const resetState = () => {
    if (inputRef && inputRef.current && inputRef.current.value) {
      inputRef.current.value = "";
    }
    dispatch(updateSiteId(null));
    dispatch(updateSiteIdData(null));
    handleUploadAlertClose();
  };

  useEffect(() => {
    if (siteId && siteIdData) {
      let newFormData: any = { ...form };
      if (siteIdData && siteIdData.listOfSiteAdmins && siteIdData.listOfSiteAdmins.length) {
        newFormData["to"] = siteIdData.listOfSiteAdmins.join(", ");
      } else {
        newFormData["to"] = envVars.REACT_APP_MAIL_ID_FOR_SITE_ACCESS;
      }
      newFormData["subject"] = `SCRT Report Management User Access Update.`;
      newFormData["message"] = `Provide SCRT site access for the user (${GET_USER_EMAIL_AUTHHUB_LOWERCASE()})`;
      setFormData({ ...newFormData });
    }
  }, [siteIdData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <ClayLayout.ContainerFluid view size={false}>
        {!userData?.enterpriseUser ? (
          <ClayCard className="m-3 w-100">
            <ClayCard.Body className="px-4">
              <div
                dangerouslySetInnerHTML={{ __html: enterprisePending(envVars) }}
                className="text-center"
              ></div>
            </ClayCard.Body>
          </ClayCard>
        ) : (
          <>
            <ClayLayout.Row>
              <ClayCard className="m-3 w-100">
                {requestType === "SA" && (
                  <ClayLayout.Row justify="start" className="align-items-center">
                    <ClayLayout.Col className="d-flex justify-content-center align-items-center text-center pt-4">
                      <PageTitle title={pageTitle} color={"okta"} />
                    </ClayLayout.Col>
                  </ClayLayout.Row>
                )}
                {sendAlerts && sendAlerts.length ? (
                  <div className="row m-3">
                    {sendAlerts.map((report: IAlertMessage, key: number) => {
                      return (
                        <div
                          key={key}
                          className={`${
                            sendAlerts.length > 1 ? "col-md-6" : "col-md-12"
                          } col-sm-12`}
                        >
                          {report.type && report.message && (
                            <div key={report.message}>
                              <Alert handleClose={handleUploadAlertClose} report={report} />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                <ClayCard.Body className="px-4">
                  <ClayLayout.Row className="m-4 mw-100">
                    <ClayForm.Group>
                      <label htmlFor="basicInputText">Broadcom Support Site ID</label>
                      <div className="d-flex">
                        <ClayInput
                          id="basicInputText"
                          placeholder="Insert your Site ID"
                          type="number"
                          className="siteInput"
                          value={siteId}
                          onChange={(val) => updateSiteIdVal(val)}
                          onKeyDown={handleKeyDown}
                          min={0}
                          ref={inputRef}
                        />
                        <ClayButton
                          type="submit"
                          className="ml-2"
                          displayType={"primary"}
                          style={{ fontSize: "small" }}
                          onClick={handleSiteIdUpdate}
                        >
                          <span className="inline-item inline-item-before">
                            <ClayIcon spritemap={Sprite} symbol="angle-right" />
                          </span>
                          Verify
                        </ClayButton>
                      </div>
                      {siteId && siteIdData && siteIdData.siteName && (
                        <div className="pt-4">
                          <label htmlFor="basicInputText">Broadcom Support Site Name</label>
                          <ClayInput
                            id="basicSiteName"
                            type="text"
                            className="siteInput"
                            value={siteIdData?.siteName}
                            disabled={true}
                          />
                        </div>
                      )}
                    </ClayForm.Group>
                  </ClayLayout.Row>
                  {(!emailResponse || Object.keys(emailResponse).length < 0) && (
                    <ClayLayout.Row justify="start" className="align-items-center">
                      <ClayLayout.Col className="d-flex flex-column ml-4">
                        {siteIdData && siteIdData.firstTimeUser ? (
                          <>
                            <div>
                              {FIRST_TIME_USER.map((text: string, idx: number) => (
                                <div
                                  className={`${idx === 2 ? "pt-4" : ""}`}
                                  dangerouslySetInnerHTML={{ __html: text }}
                                ></div>
                              ))}
                            </div>
                            <div className="my-4 d-flex flex-row-reverse">
                              <ClayButton
                                type="submit"
                                className="ml-2"
                                displayType={"primary"}
                                style={{ fontSize: "small" }}
                                onClick={(e) => onSubmit(e)}
                              >
                                <span className="inline-item inline-item-before">
                                  <ClayIcon spritemap={Sprite} symbol="check" />
                                </span>
                                Confirm
                              </ClayButton>
                              <ClayButton
                                type="submit"
                                className="ml-2"
                                displayType={"secondary"}
                                style={{ fontSize: "small" }}
                                onClick={resetState}
                              >
                                <span className="inline-item inline-item-before">
                                  <ClayIcon spritemap={Sprite} symbol="times" />
                                </span>
                                Cancel
                              </ClayButton>
                            </div>
                          </>
                        ) : siteIdData && !siteIdData.firstTimeUser && requestType === "SA" ? (
                          <>
                            {NOT_FIRST_TIME.map((text) => (
                              <div>{text}</div>
                            ))}
                          </>
                        ) : siteIdData && !siteIdData.firstTimeUser && requestType === "ASA" ? (
                          <>
                            {NOT_FIRST_TIME_ASA.map((text) => (
                              <div>{text}</div>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </ClayLayout.Col>
                    </ClayLayout.Row>
                  )}
                  {(!emailResponse || Object.keys(emailResponse).length < 0) &&
                    !!siteIdData &&
                    !siteIdData.firstTimeUser && (
                      <Formik
                        enableReinitialize={true}
                        initialValues={formData}
                        onSubmit={(e) => onSubmit(e)}
                        validationSchema={validationSchema}
                        innerRef={form}
                      >
                        {(formik) => (
                          <ClayForm onSubmit={formik.handleSubmit} className="m-4 mw-100">
                            <ClayLayout.Row justify="start"></ClayLayout.Row>
                            <ClayLayout.Row justify="start">
                              <ClayLayout.Col xs={12} md={10} lg={8} className="pt-2">
                                <FormControl
                                  control="textarea"
                                  label="To"
                                  placeholder={envVars.REACT_APP_MAIL_ID_FOR_SITE_ACCESS}
                                  name="to"
                                  disabled={true}
                                  autoComplete={"off"}
                                  readOnly
                                />
                              </ClayLayout.Col>
                            </ClayLayout.Row>

                            <ClayLayout.Row justify="start">
                              <ClayLayout.Col xs={12} md={10} lg={8} className="mt3">
                                <FormControl
                                  control="text"
                                  label="Subject"
                                  name="subject"
                                  autoComplete={"off"}
                                  disabled={true}
                                  readOnly
                                />
                              </ClayLayout.Col>
                            </ClayLayout.Row>
                            <ClayLayout.Row justify="start">
                              <ClayLayout.Col xs={12} md={10} lg={8} className="mt3">
                                <FormControl
                                  control="textarea"
                                  label="Message"
                                  name="message"
                                  autoComplete={"off"}
                                  disabled={true}
                                  readOnly
                                />
                              </ClayLayout.Col>
                            </ClayLayout.Row>
                            <ClayLayout.Row className="mt-3">
                              <ClayButton
                                type="submit"
                                className="ml-2"
                                displayType={"primary"}
                                style={{ fontSize: "small" }}
                              >
                                <span className="inline-item inline-item-before">
                                  <ClayIcon spritemap={Sprite} symbol="angle-right" />
                                </span>
                                Send
                              </ClayButton>
                            </ClayLayout.Row>
                          </ClayForm>
                        )}
                      </Formik>
                    )}
                </ClayCard.Body>
              </ClayCard>
            </ClayLayout.Row>
            {isModalOpen && (
              <ConfirmSendModal
                confirmSend={handleConfirmSend}
                modalClose={() => setModalOpen(false)}
                body={`Are you sure you want to request access ?`}
              />
            )}
          </>
        )}
      </ClayLayout.ContainerFluid>
    </React.Fragment>
  );
}

export default OktaRedirectForm;
