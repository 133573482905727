const REPOSITORY_URL = "/repository";

export const AMR_INSTRUCTIONS: any = [
  {
    question: `Use this page to upload documents, such as reports for non-z/OS product usage and other Mainframe reporting requirements. DO NOT upload Broadcom ISV SCRT reports here.&nbsp;SCRT reports are uploaded on the <a href=${REPOSITORY_URL}>Repository</a> page.`,
    answer: `
        <ul>
            <li>For reports of non-z/OS product usage, provide the manufacturer, model, serial number, and LPAR names for each CPU that is located at the customer site, or that is remotely accessing the customer site.</li>
            <li>For all documents, provide the following contact details: full name, email address. and phone number.</li>
            <li>You can upload files in the following formats: TXT, DOCX, XLSX, and PDF.</li>
        </ul>
    `,
    show: true,
    id: 1,
  },
];
