import React from "react";

function PageTitle(props: any) {
  const title = props.title;
  const color = props.color ? props.color : "default";
  const additionalClass = props.additionalClass;
  return (
    <React.Fragment>
      <p className={`scrt-page-title-${color} ${additionalClass} mb-0`}>{title}</p>
    </React.Fragment>
  );
}

export default PageTitle;
