import ClayButton from "@clayui/button";
import ClayDatePicker from "@clayui/date-picker";
import { ClayInput } from "@clayui/form";
import ClayTable from "@clayui/table";
import { claySpriteImagePath } from "@common-services/util/claySpriteImagePath";
import { formatFilterStartEndDate } from "@utils";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";

import { SortAscIcon } from "../../../icons/sortAsc";
import { SortDescIcon } from "../../../icons/sortDesc";
import { UnsortIcon } from "../../../icons/unsort";

const TableHeader = (props: any) => {
  const { info, sortInfo, onHandleSort, filterValue, hideFilter = false } = props;
  const [resetFilter, setResetFilter] = useState(false);

  let initialFilterValue: any = filterValue;
  //To set default header filter Value
  if (!filterValue) {
    initialFilterValue = {};
    info.forEach((header: any) => {
      if (header.field) {
        initialFilterValue[header.field] = "";
      }
    });
  }
  const [filterVal, setFilterVal] = useState(initialFilterValue);

  const handleHeaderClick = (column: string) => {
    const newSortInfo = {
      order: "asc",
      column,
    };
    if (column === sortInfo.column) {
      newSortInfo.order = sortInfo.order === "asc" ? "desc" : "asc";
    }

    onHandleSort(newSortInfo);
  };

  //Reset Calendar to default
  const clearFilter = (field: string) => {
    const newFilterVal = { ...filterVal };
    delete newFilterVal[field];
    setResetFilter(!resetFilter);
    setFilterVal({ ...newFilterVal });
  };

  //Reset Calendar to Current Date
  const todayFilter = (item: any) => {
    const todayDate = moment().format("yyyy-MM-DD");
    const newFilterVal = {
      ...filterVal,
      [item.field]: !item?.filter?.range ? `${todayDate}` : `${todayDate}_${todayDate}`,
    };
    setResetFilter(!resetFilter);
    setFilterVal({ ...newFilterVal });
  };

  //Date Picker Footer
  const datePickerFooter = (item: any) => {
    return (
      <div>
        <div className="date-picker-footer">
          <ClayButton
            displayType="secondary"
            onClick={() => todayFilter(item)}
            className="date-footer-btn"
          >
            Today
          </ClayButton>
          <ClayButton
            displayType="secondary"
            onClick={() => clearFilter(item.field)}
            className="date-footer-btn"
          >
            Clear
          </ClayButton>
        </div>
        {item?.filter?.range && filterVal[item.field] ? (
          <div className="p-1">Date Range:&nbsp;{filterVal[item.field].replace("_", " To ")}</div>
        ) : null}
      </div>
    );
  };

  useEffect(() => {
    if (!!filterValue) {
      setFilterVal(filterValue);
    }
  }, [filterValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (filterVal) {
        props.onFilterChange(filterVal);
      }
    }, 750);
    return () => clearTimeout(delayDebounceFn);
  }, [filterVal]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ClayTable.Head>
      <ClayTable.Row>
        {info.map((item: any) => (
          <ClayTable.Cell
            headingCell
            headingTitle
            style={{
              minWidth: item.width || "auto",
              maxWidth: item.width || "auto",
            }}
            className={`${item.sortable ? "pe-cursor" : ""} scrt-table-header scrt-table-${
              item.field
            }`}
            key={item.field}
            onClick={() => item.sortable && handleHeaderClick(item.field)}
          >
            <span>
              {item.renderer ? item.renderer : <span className={"pr-1"}>{item.headerName}</span>}
              {item.sortable ? (
                sortInfo.column === item.field ? (
                  sortInfo.order === "asc" ? (
                    <SortAscIcon />
                  ) : (
                    <SortDescIcon />
                  )
                ) : (
                  <UnsortIcon />
                )
              ) : null}
            </span>
          </ClayTable.Cell>
        ))}
      </ClayTable.Row>
      {!hideFilter && (
        <ClayTable.Row>
          {info.map((item: any, index: number) => {
            const datePickerProps: any = {};
            datePickerProps["footerElement"] = () => <>{datePickerFooter(item)}</>;
            let selectedDate = filterVal[item.field];
            if (item?.filter?.range && filterVal[item.field]) {
              const [date1, date2] = filterVal[item.field].split("_");
              if (date1 === date2) {
                selectedDate = date1;
              }
            }

            return (
              <ClayTable.Cell
                style={{
                  minWidth: item.width || "auto",
                  maxWidth: item.width || "auto",
                  padding: "0.5rem",
                }}
                key={index}
              >
                {!!item?.filter?.show ? (
                  <>
                    {item.filterRenderer ? (
                      React.cloneElement(item.filterRenderer, {
                        filterVal,
                        item,
                        setFilterVal,
                      })
                    ) : item?.filter?.type === "date" ? (
                      <ClayDatePicker
                        key={resetFilter}
                        className="date-picker-input"
                        style={{ backgroundColor: "white" }}
                        placeholder={""}
                        readOnly={true}
                        range={item?.filter?.range || false}
                        years={{ start: 1980, end: 2050 }}
                        spritemap={claySpriteImagePath()}
                        onValueChange={(e) => {
                          setFilterVal({ ...filterVal, [item.field]: formatFilterStartEndDate(e) });
                        }}
                        value={selectedDate}
                        {...datePickerProps}
                      />
                    ) : (
                      <ClayInput
                        type="text"
                        style={{ backgroundColor: "white" }}
                        value={filterVal[item.field] || ""}
                        id={item.field}
                        placeholder={""}
                        name={item.field}
                        onChange={(e) =>
                          setFilterVal({ ...filterVal, [item.field]: e.target.value })
                        }
                      />
                    )}
                  </>
                ) : null}
              </ClayTable.Cell>
            );
          })}
        </ClayTable.Row>
      )}
    </ClayTable.Head>
  );
};

export { TableHeader };
