import { BASEPATH } from "../../utils/constants/constants";

//TODO add in env.json
const DASHBOARD_GUID = "d50b2db6-d49a-4539-95b8-8472beb17030";
const INSIGHT_GUID = "ef22ee4f-d8e2-44fb-960c-0b5e6e3932a5";
const SERIAL_NUMBER_INSIGHT_ID = "d4b6da7c-8e8a-4d9a-b23c-727973b3b29e";

export const MANAGE_URL = {
  GET_DASHBOARD_DATA: `${BASEPATH}/overview/dashboards/${DASHBOARD_GUID}/insights/${INSIGHT_GUID}`,
  GET_SUPPORT_SITE_IDS: `${BASEPATH}/admin/get-user-sites`,
  GET_ACTIVE_SERIAL_NUMBER: `${BASEPATH}/overview/dashboards/${DASHBOARD_GUID}/insights/${SERIAL_NUMBER_INSIGHT_ID}`,
};
