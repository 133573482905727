import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const CookieConsent = () => {
  const envVariables = useSelector(getEnvVars);
  const cookieConsentDomain = envVariables?.COOKIE_CONSENT_DOMAIN || "";

  useEffect(() => {
    if (cookieConsentDomain) {
      const script = document.createElement("script");
      script.setAttribute("src", "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js");
      script.setAttribute("type", "text/javascript");
      script.setAttribute("charset", "UTF-8");
      script.setAttribute("data-domain-script", `${cookieConsentDomain}`);
      document.body.appendChild(script);
    }
  }, [cookieConsentDomain]);

  return <></>;
};

export default CookieConsent;
