import ClayCard from "@clayui/card";
import { IAnnouncement } from "@interfaces";
import React from "react";

import "./announcement.scss";

const Announcement = (props: any) => {
  const { announcement } = props;
  return (
    <React.Fragment>
      {announcement.map((announce: IAnnouncement, index: number) => {
        return (
          <ClayCard key={index} className="responsive-card">
            <ClayCard.Row className="align-items-center ml-2">
              <div className="announcement-icon avatar-icon">
                <i className={`${announce.icon}`} />
              </div>
              <div className="announcements-title-padding autofit-col autofit-col-expand autofit-col-gutters">
                <section className="autofit-section">
                  <ClayCard.Description displayType="title">{announce.title}</ClayCard.Description>
                  <ClayCard.Description truncate={false} displayType="text">
                    {announce.description}
                  </ClayCard.Description>
                </section>
              </div>
            </ClayCard.Row>
          </ClayCard>
        );
      })}
    </React.Fragment>
  );
};

export { Announcement };
