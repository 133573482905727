import { apiCallBegan } from "@common-services/redux-helper/api-action-creator";
import { createSlice } from "@reduxjs/toolkit";

import { MANAGE_URL } from "./constants";

const defaultState = {
  dashboardData: undefined,
  siteIdList: [],
  activeSerialNumber: undefined,
  dashboardDataResponse: [],
};

const slice = createSlice({
  name: "overview",
  initialState: { ...defaultState },
  reducers: {
    dashboardDataReceived: (state, action) => {
      if (action.payload) {
        state.dashboardData = action.payload.data.data;
      }
    },
    activeSerialNumberDataReceived: (state, action) => {
      if (action.payload) {
        state.activeSerialNumber = action.payload.data.data;
      }
    },
    siteIdListReceived: (state, action) => {
      if (action.payload) {
        const data = [...action.payload.data.userSiteList];
        data.forEach((obj) => {
          obj.id = obj.siteId;
          obj.label = `${obj.siteName}(${obj.siteId})`;
        });
        state.siteIdList = [...data];
      }
    },
    setDashboardDataResponse: (state, action) => {
      state.dashboardDataResponse = { ...action.payload } || {};
    },
  },
});

export const {
  dashboardDataReceived,
  siteIdListReceived,
  activeSerialNumberDataReceived,
  setDashboardDataResponse,
} = slice.actions;

export const loadDashboardData = (body) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: MANAGE_URL.GET_DASHBOARD_DATA,
      method: "POST",
      data: body,
      onSuccess: dashboardDataReceived.type,
      onError: setDashboardDataResponse.type,
      skipErrorHandling: true,
    }),
  );
};

export const loadActiveSerialNumberData = (body) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: MANAGE_URL.GET_ACTIVE_SERIAL_NUMBER,
      method: "POST",
      data: body,
      onSuccess: activeSerialNumberDataReceived.type,
      onError: setDashboardDataResponse.type,
      skipErrorHandling: true,
    }),
  );
};

export const loadUserSiteIds = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: `${MANAGE_URL.GET_SUPPORT_SITE_IDS}`,
      method: "GET",
      onSuccess: siteIdListReceived.type,
    }),
  );
};

//To fetch URL, request params and body
//@Input state
//@Output { URL, body }
// const getDashboardDataUrl = (prompts, state) => {
//   let URL = MANAGE_URL.GET_DASHBOARD_DATA;
//   let body = {
//     prompts: [...prompts],
//   };

//   return { URL, body };
// };

export const getDashboardData = (state) => state.overview.dashboardData;

export const getUserSiteIds = (state) => state.overview.siteIdList;
export const getDashboardActiveSerialNumber = (state) => state.overview.activeSerialNumber;
export const getDashboardDataResponse = (state) => state.overview.dashboardDataResponse;
export const overviewReducer = slice.reducer;
