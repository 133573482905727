import { ROUTE_PATHS } from "@constants";
import React, { useEffect } from "react";

import "./noaccess.scss";

export default function NoAccess() {
  const logoutRedirect = () => {
    window.location.href = ROUTE_PATHS.LOGOUT;
  };

  useEffect(() => {
    setTimeout(() => {
      logoutRedirect();
    }, 5000);
  }, []);
  return (
    <div className="text-container">
      You don't have access to the page/application. Logging you out in few seconds.
    </div>
  );
}
