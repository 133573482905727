import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import { ROUTE_PATHS } from "@constants";
import { addUserEvent } from "@store/userDetails/slice";
import { GET_USER_EMAIL_AUTHHUB } from "@utils";
import isEmpty from "lodash.isempty";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  checkUserGroup,
  getEnterpriseStatus,
  getErrorDetails,
  getUserData,
  resetUserData,
  updateEnterprise,
  updateUserProfile,
} from "./../../store/oktaAuth.slice";
import ECXRedirect from "./../ecxRedirect/ecxRedirect";
import EnterprisePending from "./EnterprisePending";
import "./oktaRedirect.scss";
import OktaRedirectForm from "./oktaRedirectForm";
import OktaRedirectFormError from "./oktaRedirectFormError";

export default function OktaRedirect() {
  debugger;
  const dispatch = useDispatch();
  const userData = useSelector(getUserData);
  const errorMessage = useSelector(getErrorDetails);
  const envVars = useSelector(getEnvVars);
  const isOktaEnabled = envVars.DISABLE_OKTA !== "true";
  const email = GET_USER_EMAIL_AUTHHUB();
  const noEnterprise = useSelector(getEnterpriseStatus);
  const isInternalUser = (email && email.includes("@broadcom.com")) || false;

  useEffect(() => {
    if (!email) {
      window.location.href = ROUTE_PATHS.LOGOUT;
      return;
    } else if (!isOktaEnabled) {
      localStorage.setItem("okta-valid-user", "true");
      window.location.href = ROUTE_PATHS.REPOSITORY;
      return;
    }
    dispatch(resetUserData());
    dispatch(checkUserGroup(email));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!!userData && !isEmpty(userData)) {
      //Check for Internal user and has scrt sites enabled
      const internalUserCheck = userData && isInternalUser && userData?.hasSCRTSite;
      const externalUserCheck =
        !isInternalUser && userData && userData?.enterpriseUser && userData?.hasSCRTSite;
      if (internalUserCheck || externalUserCheck) {
        localStorage.setItem("okta-valid-user", "true");
        localStorage.setItem("scrt-local", JSON.stringify({ mclUser: userData.mclUser }));
        dispatch(updateUserProfile(true));
        dispatch(addUserEvent("LOGIN")).then(() => {
          window.location.href = ROUTE_PATHS.REPOSITORY;
        });
        return;
      }
      //ECX build profile redirect for basic user
      else if (
        !isInternalUser &&
        userData &&
        !userData?.enterpriseUser &&
        (!userData?.status || userData.status === "03")
      ) {
        dispatch(updateEnterprise(true));
        return;
      }
      const noUserSite = isInternalUser
        ? userData && !userData.hasSCRTSite
        : (userData &&
            ((userData?.enterpriseUser && !userData.hasSCRTSite) ||
              (!userData?.enterpriseUser && userData?.status === "01"))) ||
          false;
      if (noUserSite) {
        const scrtLocalDetails = JSON.parse(localStorage.getItem("scrt-local") || "{}");
        delete scrtLocalDetails.mclUser;
        delete scrtLocalDetails.redirect;
        if (!userData.scrtInternal) {
          delete scrtLocalDetails.scrtInternal;
        }
        localStorage.setItem(
          "scrt-local",
          JSON.stringify({ ...scrtLocalDetails, redirect: ROUTE_PATHS.OKTA_REDIRECT }),
        );
      }
    }
  }, [userData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //Logout user if error fetching user details
    if (errorMessage) {
      setTimeout(() => {
        window.location.href = ROUTE_PATHS.LOGOUT;
      }, 5000);
    }
  }, [errorMessage]);

  //Check if user data exists and no site allocated
  const noUserSite = isInternalUser
    ? userData && !userData.hasSCRTSite
    : (userData &&
        ((userData?.enterpriseUser && !userData.hasSCRTSite) ||
          (!userData?.enterpriseUser && userData?.status === "01"))) ||
      false;
  let pendingEnterprise = false;
  if (noUserSite && userData) {
    pendingEnterprise = noUserSite && userData && userData?.scrtSiteStatus === "01";
  }

  //To Show Repository
  if (!noEnterprise && !!userData && !userData.hasSCRTSite && userData.scrtInternal) {
    const scrtLocalDetails = JSON.parse(localStorage.getItem("scrt-local") || "{}");
    localStorage.setItem(
      "scrt-local",
      JSON.stringify({
        ...scrtLocalDetails,
        scrtInternal: true,
        hasScrtAccess: userData.hasSCRTSite,
      }),
    );
    window.location.href = ROUTE_PATHS.REPO_LOGS;
    return <></>;
  }

  return (
    <div className={`${noUserSite ? "" : "loadingText"}`}>
      {errorMessage ? (
        errorMessage
      ) : !!noEnterprise ? (
        <ECXRedirect />
      ) : pendingEnterprise ? (
        <EnterprisePending {...userData} />
      ) : noUserSite && isInternalUser ? (
        //Check if internal user data exists and no site allocated
        <OktaRedirectFormError userData={userData} />
      ) : noUserSite && !isInternalUser ? (
        //Check if external user data exists and no site allocated
        <OktaRedirectForm userData={userData} />
      ) : (
        "Fetching User Details ..."
      )}
    </div>
  );
}
