import { v4 as uuidv4 } from "uuid";

const scrt = (window as any).scrt || {};
const scrtGlobalActions = scrt.globalActions || {};

const globalActionsSubscribers: any = {};
let dispatch: any = null;
let getState: any = null;

export const registerGlobalActions = (actionKey: any, actionFn: any) => {
  const id = uuidv4();
  //-------- new code-------
  if (!globalActionsSubscribers[actionKey]) {
    globalActionsSubscribers[actionKey] = {
      ids: [],
      subscribers: {},
    };
  }

  globalActionsSubscribers[actionKey].ids.push(id);
  globalActionsSubscribers[actionKey].subscribers[id] = actionFn;

  if (!scrtGlobalActions[actionKey]) {
    scrtGlobalActions[actionKey] = (payload: any) => {
      globalActionsSubscribers[actionKey].ids.forEach((subId: any) => {
        try {
          globalActionsSubscribers[actionKey].subscribers[subId](payload, dispatch, getState);
        } catch (e) {
          console.error(e);
        }
      });
    };
  }

  return () => {
    // globalActionsSubscribers[actionKey].subscribers[id]; Check: needed?
    globalActionsSubscribers[actionKey].ids = globalActionsSubscribers[actionKey].ids.filter(
      (subId: any) => subId !== id,
    );
  };
};

scrt.globalActions = scrtGlobalActions;
(window as any).scrt = scrt;

//should be called by the store configurer, by passing in the store
export const configureGlobalActions = (store: any) => {
  getState = store.getState;
  dispatch = store.dispatch;
};
