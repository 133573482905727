import { ClayCheckbox } from "@clayui/form";
import React from "react";

const TableCheckBox = (props: any) => {
  const { handleCheckboxChange, rowData, currentPage, index } = props;
  return (
    <div className="scrt-checkbox-center">
      <ClayCheckbox
        checked={rowData.isChecked}
        onChange={(e) => {
          handleCheckboxChange(Math.abs(index - currentPage), e.target.checked);
        }}
      />
    </div>
  );
};

export default TableCheckBox;
