import { apiCallBegan } from "@common-services/redux-helper/api-action-creator";
import { configureStore, createSlice } from "@reduxjs/toolkit";

import { USER_DETAILS_URL } from "./userDetails/constants";

//State for Okta Auth
const slice = createSlice({
  name: "oktaDetails",
  initialState: {
    userData: null,
    errorMessage: "",
    userEmail: "",
    validUser: false,
    siteIdData: null,
    siteId: "",
    siteAccessResponse: null,
    noEnterprise: false,
  },
  reducers: {
    updateDetails: (state, action) => {
      if (action.payload && action.payload.success) {
        state.userData = action.payload.data || {};
      }
    },
    updateError: (state, action) => {
      state.errorMessage = "Error Fetching user details, Please try after sometime.";
    },
    updateEmail: (state, action) => {
      state.userEmail = action.payload || "";
    },
    resetUserData: (state) => {
      state.userData = null;
      state.errorMessage = "";
      state.noEnterprise = false;
    },
    updateUserProfile: (state, action) => {
      state.validUser = action.payload || false;
    },
    updateSiteIdData: (state, action) => {
      state.siteIdData = action.payload;
    },
    updateSiteVerify: (state, action) => {
      state.siteIdData = action.payload.data;
      state.siteAccessResponse = null;
    },
    updateSiteId: (state, action) => {
      state.siteId = action.payload;
    },
    updateEnterprise: (state, action) => {
      state.noEnterprise = action.payload || false;
    },
    updateAccessRequest: (state, action) => {
      state.siteAccessResponse = {
        data: action.payload.data,
        success: !action?.payload?.data?.siteName ? false : action?.payload?.success || false,
      };
      state.siteIdData = {
        ...state.siteIdData,
        siteName: action?.payload?.data?.siteName || null,
      };
    },
  },
});

export const {
  updateDetails,
  updateError,
  updateEmail,
  resetUserData,
  updateEnterprise,
  updateUserProfile,
  updateSiteId,
  updateSiteVerify,
  updateSiteIdData,
  updateAccessRequest,
  resetSiteAccessRequest,
} = slice.actions;

//To make delete Report API call
export const checkUserGroup = (userMail) => (dispatch) => {
  dispatch(updateEmail(userMail));
  return dispatch(
    apiCallBegan({
      url: USER_DETAILS_URL.IS_ENTERPRISE_URL,
      data: {},
      method: "POST",
      onSuccess: updateDetails.type,
      onError: updateError.type,
    }),
  );
};

//To verify Support Site ID API call
export const checkSiteId = (siteId) => (dispatch) => {
  const body = { supportSiteId: siteId };
  return dispatch(
    apiCallBegan({
      url: USER_DETAILS_URL.VERIFY_SITE_ID,
      method: "POST",
      data: body,
      onSuccess: updateSiteVerify.type,
      onError: updateAccessRequest.type,
      skipErrorHandling: true,
    }),
  );
};

//To verify Support Site ID API call
export const siteAccessRequest = (postData) => (dispatch) => {
  const body = { ...postData };
  return dispatch(
    apiCallBegan({
      url: USER_DETAILS_URL.SITE_ACCESS_REQUEST,
      method: "POST",
      data: body,
      onSuccess: updateAccessRequest.type,
      onError: updateError.type,
    }),
  );
};

// selectors
export const getUserData = (state) => state.oktaDetails.userData;

export const getErrorDetails = (state) => state.oktaDetails.errorMessage;

export const getIfUserValid = (state) => state.oktaDetails.validUser;

export const getSiteIdData = (state) => state.oktaDetails.siteIdData;

export const getSiteId = (state) => state.oktaDetails.siteId;

export const getSiteAccessResponse = (state) => state.oktaDetails.siteAccessResponse;

export const getEnterpriseStatus = (state) => state.oktaDetails.noEnterprise;

// reducer
export const oktaDetailsReducer = slice.reducer;

// store
export const store = configureStore({
  reducer: slice.reducer,
});
