/**
 * Cache structure
 *  storage key -- <unique id>
 *  storage val -- {
 *     <uniqueSessionId> : {
 *          <generatedKey>: <response>
 *     }
 *  }
 *
 */
import MD5 from "crypto-js/md5";

export const cacheResponse = (request: any, response: any, cacheConfig: any) => {
  if (cacheConfig && cacheConfig.key) {
    return setDataInStore(request, response, cacheConfig);
  }
};

export const getCachedResponse = (request: any, cacheConfig: any) => {
  if (cacheConfig && cacheConfig.key && !cacheConfig.evict) {
    return getDataFromStore(request, cacheConfig);
  }
};

const setDataInStore = (request: any, response: any, cacheConfig: any) => {
  const cacheUniqueKey = cacheConfig.key;
  // const sessionInitTime = ecx && ecx.ecxSessionId ? ecx.ecxSessionId : +(new Date()); what?
  const sessionInitTime: any = new Date();

  const cachedArtifact = localStorage.getItem(cacheUniqueKey);
  let cachedJson: any = "";
  if (cachedArtifact) {
    try {
      cachedJson = JSON.parse(cachedArtifact);
    } catch (e) {
      //corrupt json
      localStorage.removeItem(cacheUniqueKey);
      cachedJson = {};
    }
  } else {
    cachedJson = {};
  }

  if (!cachedJson[sessionInitTime]) {
    //create new one,setting it clears stale session cache
    cachedJson = {};
  }
  cachedJson[sessionInitTime] = cachedJson[sessionInitTime] || {};
  cachedJson[sessionInitTime][generateCacheRequestKey(request, cacheConfig)] = response;

  let stringedArtifact = "{}";
  try {
    stringedArtifact = JSON.stringify(cachedJson);
  } catch (e) {}

  localStorage.setItem(cacheUniqueKey, stringedArtifact);
};

const getDataFromStore = (request: any, cacheConfig: any) => {
  let response;
  const cacheUniqueKey = cacheConfig.key;
  // const sessionInitTime = ecx && ecx.ecxSessionId ? ecx.ecxSessionId : +(new Date()); what?
  const sessionInitTime: any = new Date();

  const cachedArtifact = localStorage.getItem(cacheUniqueKey);
  if (cachedArtifact) {
    try {
      const cachedJson = JSON.parse(cachedArtifact);
      if (cachedJson[sessionInitTime]) {
        const key = generateCacheRequestKey(request, cacheConfig);
        response = cachedJson[sessionInitTime][key];
      } else {
        //new session, remove all cached data
        localStorage.removeItem(cacheUniqueKey);
      }
    } catch (e) {
      //corrupt json
      localStorage.removeItem(cacheUniqueKey);
    }
  }

  return response;
};

const generateCacheRequestKey = (request: any, cacheConfig: any) => {
  let key: any = "";
  if (cacheConfig.type === "body") {
    let body = request.data;
    body = JSON.stringify(body);
    key = MD5(body);
  } else {
    //default url
    key = MD5(request.url);
  }

  return key;
};
