import { apiCallBegan } from "@common-services/redux-helper/api-action-creator";
import { DEFAULT_DELTA, ROUTE_PATHS } from "@constants";
import { configureStore, createSlice } from "@reduxjs/toolkit";
import { GET_USER_ROLE } from "@utils";

import { SITE_DETAILS_URL } from "./constants";

const defaultState = {
  siteObj: {},
  siteList: [],
  //need to remove this static data once api is ready and needs to be integrated
  siteDetail: {},
  scrtReports: [],
  userList: [],
  sort: { column: "", order: "" },
  pagination: {
    activeDelta: DEFAULT_DELTA,
    activePage: 0,
    ellipsisBuffer: 5,
    totalItems: 100,
  },
  filterVal: {},
  openModal: "",
};

const slice = createSlice({
  name: "siteDetails",
  initialState: { ...defaultState },
  reducers: {
    siteDataReceived: (siteDetails, action) => {
      if (action.payload && action.payload.success) {
        siteDetails.siteObj = action.payload?.data?.result || {};
      }
    },
    siteListReceived: (siteDetails, action) => {
      if (action.payload) {
        siteDetails.siteList = formatSiteList(action.payload || []);
      }
    },
    userList: (siteDetails, action) => {
      if (action.payload && action.payload.success) {
        siteDetails.userList = action.payload?.data?.result || [];
      }
    },
    siteDetail: (siteDetails, action) => {
      if (action.payload && action.payload.success) {
        siteDetails.siteDetail = updateSerialNumbers(action.payload?.data || {});
      }
    },

    handleSortState: (state, action) => {
      if (action.payload) {
        state.sort = action.payload;
      }
    },
    handleFilterState: (state, action) => {
      if (action.payload) {
        state.sort = { column: "", order: "" };
        state.filterVal = action.payload;
      }
    },
    handlePaginationState: (state, action) => {
      if (action.payload) {
        state.pagination = action.payload;
      }
    },
    updateModal: (state, action) => {
      state.openModal = action.payload || "";
    },
    resetSiteData: (state) => {
      state.siteObj = {};
      state.siteList = [];
    },
    resetUserData: (state) => {
      state.userList = [];
    },
    resetSiteState() {
      return { ...defaultState };
    },
    handleSiteDetailError: (state, action) => {
      if (
        action.payload &&
        action.payload.errors[0] &&
        action.payload.errors[0].details[0] &&
        action.payload.errors[0].details[0].includes("have admin access to this site ID.")
      ) {
        setTimeout(() => {
          window.location.href = ROUTE_PATHS.SITES;
        }, 4000);
      }
    },
  },
});

export const {
  scrtReportsList,
  userList,
  siteDetail,
  siteListReceived,
  siteDataReceived,
  handleSortState,
  handleFilterState,
  handlePaginationState,
  updateModal,
  resetSiteState,
  resetSiteData,
  resetUserData,
  handleSiteDetailError,
} = slice.actions;

export const loadSiteUsers = (siteId) => (dispatch, getState) => {
  const state = getSiteState(getState());
  const { url } = getUserURLWithParams(siteId, state);
  return dispatch(
    apiCallBegan({
      url: url,
      method: "GET",
      onSuccess: userList.type,
      onError: resetUserData.type,
      skipErrorHandling: true,
    }),
  );
};

export const loadSiteDetailBySiteId = (id) => (dispatch, getState) => {
  const url = `${SITE_DETAILS_URL.GET_SITE_DETAILS}/${id}`;
  return dispatch(
    apiCallBegan({
      url: url,
      method: "POST",
      data: {},
      onSuccess: siteDetail.type,
      onError: handleSiteDetailError.type,
    }),
  );
};

export const updateSiteDetails = (data) => async (dispatch, getState) => {
  const url = SITE_DETAILS_URL.UPDATE_SITE_DETAIL;
  dispatch(updateModal(""));
  return dispatch(
    apiCallBegan({
      url: url,
      method: "POST",
      data: data,
      onSuccess: updateModal.type,
    }),
  );
};

export const loadSiteDetails = (sortParams, filterParams, pageParams) => (dispatch, getState) => {
  if (sortParams) {
    dispatch(handleSortState(sortParams));
  }
  if (filterParams) {
    dispatch(handleFilterState(filterParams));
  }
  if (pageParams) {
    dispatch(handlePaginationState(pageParams));
  }
  const state = getSiteState(getState());
  const signedInUser = getState().userDetails.signedInUser;
  const { URL, body } = getURL(state, signedInUser);
  return dispatch(
    apiCallBegan({
      url: URL,
      method: "POST",
      data: body,
      onSuccess: siteDataReceived.type,
      onError: resetSiteData.type,
      skipErrorHandling: true,
    }),
  );
};

export const resetFilters = () => (dispatch, getState) => {
  dispatch(handleFilterState({}));
};

export const store = configureStore({
  reducer: slice.reducer,
});

// selectors
export const getScrtReports = (state) => state.siteDetails.scrtReports;
export const getSiteUsers = (state) => state.siteDetails.userList;
export const getSiteObj = (state) => state.siteDetails.siteObj;
export const getSiteDetail = (state) => state.siteDetails.siteDetail;
export const getSiteList = (state) => state.siteDetails.siteList;
export const getSiteState = (state) => state.siteDetails;
export const getModalStatus = (state) => state.siteDetails.openModal;
export const getSiteSortState = (state) => state.siteDetails.sort;
export const getSiteFilterState = (state) => state.siteDetails.filterVal;
export const getSitePageState = (state) => state.siteDetails.pagination;

// reducer
export const siteDetailsReducer = slice.reducer;

const getUserURLWithParams = (siteId, state) => {
  let url = SITE_DETAILS_URL.SITE_USER_URL + `/${siteId}`;
  if (state?.sort?.column) {
    const sortOrder = state?.sort?.order ? state?.sort?.order.toUpperCase() : "ASC";
    url += "?sort=" + state?.sort?.column + "," + sortOrder;
  }
  return { url };
};

const getURL = (state, signedInUser) => {
  let URL = SITE_DETAILS_URL.GET_SITE_DETAILS;
  let body = {
    siteId: "",
    siteName: "",
    serialNumber: "",
    isDecommissioned: "",
    updatedBy: "",
    updatedDate: "",
    createdDate: "",
    createdBy: "",
    adminType: GET_USER_ROLE(),
  };
  if (state?.pagination) {
    URL += `?size=${state.pagination.activeDelta}&page=${state.pagination.activePage - 1}`;
  }
  if (state?.filterVal) {
    const filters = { ...state.filterVal };
    body = { ...body, ...filters, siteName: filters?.formattedSiteName || "" };
    delete body["formattedSiteName"];
  }
  if (state?.sort?.column) {
    const sortOrder = state?.sort?.order ? state?.sort?.order.toUpperCase() : "ASC";
    URL += `&sort=${
      state?.sort?.column === "formattedSiteName" ? "siteName" : state?.sort?.column
    },${sortOrder}`;
  }

  return { URL, body };
};

//Split serial numbers object to separate Active/Decomissioned/Failover status
function updateSerialNumbers(data) {
  if (!data) {
    return data;
  }
  let newData = { ...data };
  const allSerialNumbers = data?.serialNumberAndCommentDtoList || [];
  newData["siteIdDetailsNotDecommissioned"] = allSerialNumbers
    .filter((serial) => serial.status === "A")
    .map((serial) => serial.serialNumber);
  newData["siteIdDetailsDecommissioned"] = allSerialNumbers
    .filter((serial) => serial.status === "D")
    .map((serial) => serial.serialNumber);
  newData["siteIdDetailsFailed"] = allSerialNumbers
    .filter((serial) => serial.status === "F")
    .map((serial) => serial.serialNumber);
  newData["formattedSiteName"] = data.siteName + `${data.siteId ? " (" + data.siteId + ")" : ""}`;
  newData["trueUpPeriodStartDate"] = data.trueUpPeriodStartDate;
  newData["trueUpPeriodEndDate"] = data.trueUpPeriodEndDate;
  return newData;
}

//Format sitelist response to show multiple columns for Active/Decom/Failover
export function formatSiteList(data: any) {
  if (!data) {
    return data;
  }
  return data.map((item) => {
    item = updateSerialNumbers(item);
    return item;
  });
}
