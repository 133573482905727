import ClayButton from "@clayui/button";
import ClayIcon from "@clayui/icon";
import ClayModal, { useModal } from "@clayui/modal";
import { claySpriteImagePath } from "@common-services/util/claySpriteImagePath";
import React from "react";

export function DeleteModal(props: any) {
  const { modalClose, reportId, confirmDelete } = props;
  const { observer } = useModal({
    onClose: () => modalClose(reportId),
  });
  if (!reportId) {
    return <></>;
  }

  const rowData =
    (props?.rowData?.content && props.rowData.content.find((i: any) => i.reportId === reportId)) ||
    {};
  const periodStart = rowData.periodStart || "";
  const periodEnd = rowData.periodEnd || "";
  const customerName = rowData.customerName || "";

  return (
    <ClayModal
      observer={observer}
      spritemap={claySpriteImagePath()}
      disableAutoClose={true}
      status="danger"
    >
      <ClayModal.Header>Confirmation</ClayModal.Header>
      <ClayModal.Body>
        {`This file will be deleted.
            Are you sure you want to delete the report for ${customerName} for the period from ${periodStart} to ${periodEnd} ?`}
      </ClayModal.Body>
      <ClayModal.Footer
        last={
          <ClayButton.Group spaced>
            <ClayButton onClick={() => modalClose(reportId)} borderless>
              <ClayIcon symbol="times" spritemap={claySpriteImagePath()} />
              &nbsp;{"No"}
            </ClayButton>
            <ClayButton onClick={() => confirmDelete(reportId)} borderless>
              <ClayIcon symbol="check" spritemap={claySpriteImagePath()} />
              &nbsp;{"Yes"}
            </ClayButton>
          </ClayButton.Group>
        }
      />
    </ClayModal>
  );
}
