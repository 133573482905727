import { BasicAuth, OktaAuthentication } from "@common-components";
import { Header } from "@common-components";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import { ROUTE_PATHS } from "@constants";
import { ErrorBoundary } from "react-error-boundary";
import { useSelector } from "react-redux";
import { Route, useLocation } from "react-router-dom";

function ErrorFallback({ error }) {
  return (
    <div role="alert">
      <Header showNavbar={false}></Header>
      <div className="m-5">
        <p>{`Something went wrong "${error}", Please try after sometime or login to another window.`}</p>
      </div>
    </div>
  );
}

function Routes() {
  const envVars = useSelector(getEnvVars);
  const location = useLocation();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="brdcm-container">
        <Route path={ROUTE_PATHS.HEALTH}>
          <h3>The App is Healthy</h3>
        </Route>
        {location.pathname !== ROUTE_PATHS.HEALTH ? (
          <>{envVars.DISABLE_OKTA === "true" ? <BasicAuth /> : <OktaAuthentication />}</>
        ) : null}
      </div>
    </ErrorBoundary>
  );
}

export default Routes;
