import ClayButton from "@clayui/button";
import ClayModal, { useModal } from "@clayui/modal";
import {
  getAccessDeniedConfig,
  getToastMessages,
  removeAccessDeniedModal,
  removeToastAlert,
} from "@common-services/redux-helper/store/slice/globalAlertHandler.slice";
import CheckIcon from "@mui/icons-material/Check";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CrossIcon } from "../icons/cross";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
});

function GlobalAlertHandler() {
  const dispatch = useDispatch();
  const toastMessages: any = useSelector(getToastMessages);
  const permDeniedConfig: any = useSelector(getAccessDeniedConfig);

  const onToastClose = (id: number) => {
    dispatch(removeToastAlert(id));
  };

  const { observer, onClose } = useModal({
    onClose: () => dispatch(removeAccessDeniedModal(false)),
  });

  return (
    <Fragment>
      {toastMessages.length
        ? toastMessages.map((value: any, index: number) => (
            <div key={value.id}>
              <Snackbar
                open={true}
                autoHideDuration={value.timeout || 6000}
                onClose={() => {
                  onToastClose(value.id);
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert
                  className={value.position + "-alert"}
                  onClose={() => {
                    onToastClose(value.id);
                  }}
                  severity={value.type}
                  sx={{ width: "100%" }}
                  iconMapping={{
                    success: <CheckIcon fontSize="inherit" className="success-color" />,
                    error: <HighlightOffIcon fontSize="inherit" className="error-color" />,
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: value.message }}></span>
                </Alert>
              </Snackbar>
            </div>
          ))
        : null}
      {permDeniedConfig.show ? (
        <ClayModal observer={observer} size="sm">
          <ClayModal.Header withTitle={false} className={"h-auto pt-3 border-bottom"}>
            <ClayModal.ItemGroup>
              <ClayModal.Item>
                <ClayModal.TitleSection>
                  <ClayModal.Title className="text-danger font-weight-bold">
                    {"Access Denied"}
                  </ClayModal.Title>
                </ClayModal.TitleSection>
              </ClayModal.Item>
            </ClayModal.ItemGroup>
            {permDeniedConfig.clossable && (
              <ClayButton displayType="unstyled" onClick={onClose}>
                <CrossIcon />
              </ClayButton>
            )}
          </ClayModal.Header>
          <ClayModal.Body className="m-2 mb-4">
            <span>This feature has been disabled by your administrator.</span>
          </ClayModal.Body>
        </ClayModal>
      ) : null}
    </Fragment>
  );
}

export { GlobalAlertHandler };
