import ClayIcon from "@clayui/icon";
import { claySpriteImagePath } from "@common-services/util/claySpriteImagePath";
import { IAlertMessage } from "@interfaces";
import { getUploadScrtReportResponse, uploadSCRTReport } from "@store/repositoryDetails/slice";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Alert from "./../../Alert";
import { WEBSITE } from "./constants";

const UploadReport = React.forwardRef((props: any, ref) => {
  const [uploadAlerts, setUploadAlerts] = useState([] as IAlertMessage[]);
  const [warningAlerts, setWarningAlerts] = useState([] as IAlertMessage[]);
  const { getRepoDetails, source } = props;
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const uploadScrtReportResponse = useSelector(getUploadScrtReportResponse);
  const location = useLocation();

  useEffect(() => {
    if (uploadScrtReportResponse && !uploadScrtReportResponse.success) {
      setUploadAlerts([
        {
          type: uploadScrtReportResponse.success ? "success" : "error",
          message: uploadScrtReportResponse.success
            ? uploadScrtReportResponse?.data?.message
            : uploadScrtReportResponse.errors?.[0]?.details?.[0] ||
              "Failed to upload the report. Contact your security administrator.",
        },
      ]);
    } else if (uploadScrtReportResponse && uploadScrtReportResponse?.data?.response?.length) {
      let newAlerts = uploadScrtReportResponse.data.response.map((item: any) => {
        return {
          type: item.messageType === "Warning" ? "warning" : item.success ? "success" : "error",
          message: item.message,
          errorCode: item.errorCode,
        };
      });
      const warnAlerts = (newAlerts && newAlerts.filter((i: any) => i.type === "warning")) || [];
      newAlerts = newAlerts.filter((i: any) => i.type !== "warning");
      setWarningAlerts(warnAlerts);
      setUploadAlerts(newAlerts);
      if (uploadScrtReportResponse.success) {
        getRepoDetails();
      }
    }
  }, [uploadScrtReportResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  //for drag drop of reports
  const handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    handleFileUpload(event, "dragdrop");
  };

  const handleUploadAlertClose = () => {
    setUploadAlerts([]);
    setWarningAlerts([]);
  };

  useImperativeHandle(ref, () => ({
    handleUploadAlertClose() {
      setUploadAlerts([]);
    },
  }));

  const handleFileUpload = (event: any, elementType: string = "") => {
    const fileObj =
      elementType === "input" ? event.currentTarget.files[0] : event.dataTransfer.files[0];
    if (!fileObj) {
      return;
    }
    const data = {
      scrtReport: fileObj,
      source: source || WEBSITE,
    };
    handleUploadAlertClose();
    dispatch(uploadSCRTReport(data));
  };

  useEffect(() => {
    handleUploadAlertClose();
  }, [location]);

  return (
    <div className="mt-2">
      <ul className="list-group">
        <li className="list-group-item list-group-item-flex">
          <div
            className="autofit-col autofit-col-expand dropzone"
            onDrop={(e) => handleDrop(e)}
            onDragOver={(e) => handleDragOver(e)}
            onDragEnter={(e) => handleDragEnter(e)}
            onDragLeave={(e) => handleDragLeave(e)}
          >
            <p className="list-group-title text-truncate d-flex draggable-container">
              <label htmlFor="files" className="btn btn-primary">
                <ClayIcon symbol="plus" spritemap={claySpriteImagePath()} />
                &nbsp;Upload SCRT
              </label>
              <input
                id="files"
                className="d-none"
                type="file"
                name="file"
                accept=".csv, .txt"
                onChange={(e) => handleFileUpload(e, "input")}
                ref={inputRef}
                onClick={() => {
                  if (inputRef.current) {
                    inputRef.current.value = "";
                  }
                }}
              ></input>
            </p>
            <p className="list-group-subtitle text-truncate">Or drag your report here</p>
            <div>
              {uploadAlerts && uploadAlerts.length ? (
                <div className="row">
                  {uploadAlerts.map((report: IAlertMessage, key: number) => {
                    return (
                      <div
                        key={key}
                        className={`${
                          uploadAlerts.length > 1 ? "col-md-6" : "col-md-12"
                        } col-sm-12`}
                      >
                        {report.type && report.message && (
                          <div className="c-mt-2" key={report.message}>
                            <Alert
                              handleClose={handleUploadAlertClose}
                              report={report}
                              pageType="repository"
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {warningAlerts.map((report: IAlertMessage, key: number) => {
                    return (
                      <div
                        key={key}
                        className={`${
                          warningAlerts.length > 1 ? "col-md-6" : "col-md-12"
                        } col-sm-12`}
                      >
                        {report.type && report.message && (
                          <div className="c-mt-2" key={report.message}>
                            <Alert handleClose={handleUploadAlertClose} report={report} />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
});

export default UploadReport;
