import { IAnnouncement } from "@interfaces";

const iconBell = "fa fa-bell";
const iconLightBulb = "fa fa-lightbulb";

export const ANNOUNCEMENTS: IAnnouncement[] = [
  {
    icon: iconBell,
    title: "Launch of SCRT Report Management",
    description: "An all-in-one portal to analyze and store SCRT reports.",
  },
  {
    icon: iconLightBulb,
    title: "Ease of Managing SCRT and Licensing",
    description: "One integrated app that eliminates spreadsheets and manual tracking.",
  },
  {
    icon: iconLightBulb,
    title: "Get Notified",
    description: "Get notified when SCRT reports are uploaded or missing.",
  },
];
