import ClayForm from "@clayui/form";
import { ErrorMessage, Field } from "formik";
import React from "react";

import TextError from "./TextError";

function TextArea(props: any) {
  const { label, name, required } = props;
  return (
    <div>
      {label && (
        <label htmlFor={name}>
          {required && <span className="text-red">* </span>}
          {label}
        </label>
      )}
      <Field name={name}>
        {({ form, field }: { form: any; field: any }) => {
          return (
            <ClayForm.Group
              className={
                "form-group-sm " +
                (form.errors[name] && form.touched[name] !== undefined ? "has-error" : "")
              }
            >
              <textarea
                className="form-control"
                value={name}
                {...field}
                {...props}
                style={{ height: "auto" }}
              ></textarea>
              <ErrorMessage name={name} component={TextError} />
            </ClayForm.Group>
          );
        }}
      </Field>
    </div>
  );
}

export default TextArea;
