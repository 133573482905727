import ClayLayout from "@clayui/layout";
import { NavTabs, PageTitle } from "@common-components";
import OktaRedirectForm from "@components/oktaRedirect/oktaRedirectForm";
import { ROLE_SUPER_ADMIN } from "@constants";
import { GET_USER_ROLE } from "@utils";
import React, { useState } from "react";

import UserInformation from "./UserInformation";
import { PROFILE_TABS, PROFILE_TABS_LIST } from "./constants";
import "./profile.scss";

const getTabComponent = (currentTab: any) => {
  switch (currentTab) {
    case PROFILE_TABS.profile:
      return <UserInformation />;
    case PROFILE_TABS.aSiteAccess:
      return <OktaRedirectForm userData={{ enterpriseUser: true }} requestType="ASA" />;
  }
};

function Profile() {
  const pageTitle = `Profile`;
  const navTabs = PROFILE_TABS_LIST;
  const [currentTab, setCurrentTab] = useState(PROFILE_TABS.profile);
  const role = GET_USER_ROLE();

  const handleTabChanged = (navPath: any) => {
    setCurrentTab(navPath);
  };

  return (
    <React.Fragment>
      <ClayLayout.ContainerFluid view size={false}>
        <ClayLayout.Row justify="start" className="align-items-center">
          <ClayLayout.Col className="d-flex align-items-center">
            <PageTitle title={pageTitle} />
          </ClayLayout.Col>
        </ClayLayout.Row>
        {role !== ROLE_SUPER_ADMIN && (
          <ClayLayout.Row className={"mt-4"}>
            <ClayLayout.Col size={6}>
              <NavTabs
                tabList={navTabs}
                disableNav="true"
                defaultTab={currentTab}
                tabChanged={handleTabChanged}
              />
            </ClayLayout.Col>
          </ClayLayout.Row>
        )}
        <ClayLayout.Row>{getTabComponent(currentTab)}</ClayLayout.Row>
      </ClayLayout.ContainerFluid>
    </React.Fragment>
  );
}

export default Profile;
