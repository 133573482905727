import { Header } from "@common-components";
import Home from "@components/home/Home";
import LocalLogin from "@components/local-login/LocalLogin";
import PageNotFound from "@components/pageNotFound/PageNotFound";
import { ROUTE_PATHS, routesConfig } from "@constants";
import { IRoute } from "@interfaces";
import { isLogoutRedirect, isNoAccessRedirect } from "@utils";
import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";

// Returns Protected Routes for Local Development with OKTA disabled
// Needs a valid email for authentication purposes

function BasicAuth() {
  const isAuthenticated = localStorage.getItem("email");
  const location = useLocation();
  const ProtectedRoutes = routesConfig.map((route: IRoute, key: number) => (
    <Route
      path={route.path}
      key={key}
      exact
      render={(props: any) =>
        !!isAuthenticated ? <route.component {...props} /> : <Redirect to="/local-login" />
      }
    />
  ));
  const hideNavbar = isNoAccessRedirect(location.pathname) || isLogoutRedirect(location.pathname);

  return (
    <React.Fragment>
      <Header showNavbar={!hideNavbar} />
      <Route path={ROUTE_PATHS.HOME} exact={true} component={Home} />
      <Route path={ROUTE_PATHS.LOCAL_LOGIN} exact={true} component={LocalLogin} />
      {ProtectedRoutes}
      <Route component={PageNotFound} />
    </React.Fragment>
  );
}

export default BasicAuth;
