import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import { ROUTE_PATHS } from "@constants";
import CheckIcon from "@mui/icons-material/Check";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import React from "react";
import { useSelector } from "react-redux";

import "./alert.scss";

function Index(props: any) {
  const { handleClose, report, pageType = "" } = props;
  const onCloseProps: any = {};
  const envVars = useSelector(getEnvVars);
  if (handleClose) {
    onCloseProps["onClose"] = () => {
      handleClose();
    };
  }
  return (
    <div className={report.type}>
      <Alert
        iconMapping={{
          success: <CheckIcon fontSize="inherit" className="success-color" />,
          error: <HighlightOffIcon fontSize="inherit" className="error-color" />,
        }}
        severity={report.type}
        sx={{ width: "100%" }}
        {...onCloseProps}
      >
        <AlertTitle>
          <span dangerouslySetInnerHTML={{ __html: report.message }}></span>
        </AlertTitle>
        {pageType === "repository" &&
          (report.type !== "success" ? (
            report.errorCode === 1006 ? (
              <>
                See{" "}
                <a
                  href={`${envVars.REACT_APP_COMMON_SERVICE_URL}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>
                    <u>How do I generate a Broadcom ISV SCRT report?</u>
                  </b>
                </a>{" "}
              </>
            ) : report.errorCode === 1053 || report.errorCode === 1022 ? (
              <>
                Click{" "}
                <a
                  href={`${envVars.REACT_APP_ECX_SUPPORT_ID_ARTICLE}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>
                    <u>here</u>
                  </b>
                </a>{" "}
                to find your Support Site ID
              </>
            ) : (
              <>
                Click{" "}
                <a href={`${ROUTE_PATHS.HELP}?tab=2`}>
                  <b>here</b>
                </a>{" "}
                to check common report errors.
              </>
            )
          ) : null)}
      </Alert>
    </div>
  );
}

export default Index;
