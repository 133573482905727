import { Table } from "@common-components";
import { ActionComponent } from "@components/ActionComponent/ActionComponent";
import TableCellRenderer from "@components/cellRenderer/TableCellRenderer";
import { ResetFilter } from "@components/resetFilter/ResetFilter";
import { DEFAULT_DELTA, DONWLOAD_SOURCE } from "@constants";
import { ISignedinUser } from "@interfaces";
import {
  getReportFilterState,
  getReportList,
  getReportPageState,
  getReportSortState,
  loadReportList,
  resetFilters,
} from "@store/additionalMainframeReporting/slice";
import { getSignedInUser } from "@store/userDetails/slice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const CustomerNameRenderer = (props: any) => {
  const { rowData } = props;

  return (
    <React.Fragment>
      <span>
        {rowData.customerName} ({rowData.supportSiteId})
      </span>
    </React.Fragment>
  );
};

function Grid(props: any) {
  const { reloadList, hanldeReloadListFlag } = props;
  const dispatch = useDispatch();

  //To fetch signedIn User Details
  const signedInUser: ISignedinUser = useSelector(getSignedInUser);
  const signedInUserName = signedInUser?.name || "";

  const sort = useSelector(getReportSortState);
  const filterValue = useSelector(getReportFilterState);
  const paginationInfo = useSelector(getReportPageState);
  const data = useSelector(getReportList);

  useEffect(() => {
    if (reloadList) {
      const newPagination = { ...paginationInfo, activePage: 1, activeDelta: DEFAULT_DELTA };
      getList(null, null, newPagination);
      hanldeReloadListFlag(false);
    }
  }, [reloadList]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleResetFilter = () => {
    let isExistingFilter = false;

    // check if any filters are present
    if (Object.keys(filterValue).length === 0) {
      isExistingFilter = false;
    } else {
      for (let key in filterValue) {
        if (filterValue.hasOwnProperty(key)) {
          isExistingFilter = filterValue[key] !== "" ? true : false;
          break;
        }
      }
    }

    if (sort.column !== "" && sort.order !== "") {
      isExistingFilter = true;
    }

    // reset filters only if there are any filters
    if (isExistingFilter) {
      dispatch(resetFilters());
    }
  };

  const COLUMNS_DEF = [
    {
      headerName: "Uploaded",
      field: "uploadDate",
      sortable: true,
      filter: { show: true, type: "date", range: true },
      cellRenderer: <TableCellRenderer fieldName="uploadDate" type="date" />,
    },
    {
      headerName: "Submitter Email",
      field: "submitterEmail",
      sortable: true,
      filter: { show: true },
    },
    {
      headerName: "Submitter Name",
      field: "submitterName",
      sortable: true,
      filter: { show: true },
    },
    {
      headerName: "Customer Name",
      field: "customerName",
      sortable: true,
      filter: { show: true },
      cellRenderer: <CustomerNameRenderer rowData={data} />,
    },
    {
      headerName: "Action",
      field: "action",
      sortable: false,
      filter: { show: true },
      filterRenderer: <ResetFilter handleResetFilter={handleResetFilter} />,
      cellRenderer: (
        <ActionComponent
          rowData={data}
          hidePreview={true}
          showDelete={false}
          disabledDownload={false}
          source={DONWLOAD_SOURCE.AdditionMfReporting}
        ></ActionComponent>
      ),
    },
  ];

  const handleFilter = (val: any) => {
    const newPagination = { ...paginationInfo, activePage: 1, activeDelta: DEFAULT_DELTA };
    getList(null, val, newPagination);
  };

  const handleSort = (val: any) => {
    const newPagination = { ...paginationInfo, activePage: 1 };
    dispatch(loadReportList(null, val, null, newPagination, signedInUserName));
  };

  const handlePagination = (page: any, delta: number) => {
    const newPagination = { ...paginationInfo, activePage: page, activeDelta: delta };
    getList(null, null, newPagination);
  };

  const getList = (
    sortInfo: any = sort,
    filter: any = filterValue,
    pageInfo: any = paginationInfo,
  ) => {
    const sortState = sortInfo && sortInfo.column ? sortInfo : null;
    dispatch(loadReportList(null, sortState, filter, pageInfo, signedInUserName));
  };

  return (
    <Table
      rowClassName=""
      definitions={COLUMNS_DEF}
      dataSource={data}
      borderedColumns={false}
      borderless={true}
      headVerticalAlignment={"middle"}
      headingNoWrap={true}
      hover={false}
      noWrap={true}
      responsive={true}
      responsiveSize={"sm"}
      striped={true}
      tableVerticalAlignment={"middle"}
      handleFilter={handleFilter}
      filterValue={filterValue}
      sort={sort}
      handleSort={handleSort}
      pagination={paginationInfo}
      onActivePageChange={handlePagination}
      showPagination={true}
    />
  );
}

export default Grid;
