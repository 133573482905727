import { axiosApi } from "@common-services/redux-helper/middleware/axios-api";
import { showAlert } from "@common-services/redux-helper/store/slice/globalAlertHandler.slice";
import { configureStore, createSlice } from "@reduxjs/toolkit";

import { DOWNLOAD_DETAILS_URL } from "./constants";
import { CRUSH_FTP_DOWNLOAD } from "./crushFTPDownload";

const defaultState = {
  orderId: "",
  downloadFileName: "",
  envVars: {},
  crushFTPFileName: "",
  isSingleFileOrder: "",
};

const slice = createSlice({
  name: "downloadDetails",
  initialState: { ...defaultState },
  reducers: {
    setEnvVarsAndFileName: (state, action) => {
      state.envVars = action.payload.envVars;
      state.crushFTPFileName = action.payload.fileName;
      state.isSingleFileOrder = action.payload.reportIdList.length > 1 ? false : true;
    },
  },
});

export const { setEnvVarsAndFileName } = slice.actions;

export const downloadReport = (reportIdList, source, eVars, fName) => (dispatch, state) => {
  dispatch(setEnvVarsAndFileName({ envVars: eVars, reportIdList: reportIdList, fileName: fName }));
  const { URL, body } = getDownloadUrlAndBody(reportIdList, source, eVars, fName);

  axiosApi({
    url: URL,
    method: "POST",
    data: body,
    skipErrorHandling: true,
    responseType: "blob",
    headers: {
      responseType: "stream",
    },
  })
    .then((response) => {
      if (response.status === 200) {
        const fileName = fName;
        const isSingleFileOrder = reportIdList.length > 1 ? false : true;
        CRUSH_FTP_DOWNLOAD(fileName, isSingleFileOrder, response);
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(
        showAlert({
          timeout: 3000,
          message: "Unable to download file!",
        }),
      );
    });
};

const getDownloadUrlAndBody = (reportIdList, source, envVars, filename = "") => {
  let URL = DOWNLOAD_DETAILS_URL(envVars).DOWNLOAD_REPORT;
  let body = {
    reportIdList: reportIdList,
    source: source,
    failedCrushftpFileName: filename || "",
  };

  return { URL, body };
};

export const store = configureStore({
  reducer: slice.reducer,
});

// reducer
export const downloadDetailsReducer = slice.reducer;
