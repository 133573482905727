import ClayTable from "@clayui/table";
import React from "react";
import { useHistory } from "react-router-dom";

const TableBodyRow = (props: any) => {
  const { definitions, rowData, index, currentPage, entityLink, entityId, rowClassName } = props;
  const history = useHistory();

  const handleClick = () => {
    history.push(entityLink.replace(":id", entityId ? rowData[entityId] : rowData.id));
  };

  const tableRowProps: any = { onClick: handleClick };
  if (!entityLink) {
    delete tableRowProps.onClick;
  }

  return (
    <ClayTable.Row
      className={`scrt-table-row  ${entityLink ? "scrt-table-row-selectable" : ""} ${
        rowClassName || ""
      }`}
      {...tableRowProps}
    >
      {definitions.map((def: any) => (
        <ClayTable.Cell
          key={index + def.field}
          style={{
            width: def.width || "auto",
          }}
          className={"scrt-table-cell scrt-table-" + def.field}
        >
          {def.cellRenderer ? (
            React.cloneElement(def.cellRenderer, {
              value: rowData[def.field],
              rowData,
              index,
              currentPage,
            })
          ) : (
            <span className={"pr-2"}>{rowData[def.field]}</span>
          )}
        </ClayTable.Cell>
      ))}
    </ClayTable.Row>
  );
};

export { TableBodyRow };
