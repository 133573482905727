import { apiCallBegan } from "@common-services/redux-helper/api-action-creator";
import { DEFAULT_DELTA } from "@constants";
import { configureStore, createSlice } from "@reduxjs/toolkit";

import { ADDITIONAL_MF_REPORTING_URL } from "./constants";

const defaultState = {
  reportList: [],
  sort: { column: "", order: "" },
  pagination: {
    activeDelta: DEFAULT_DELTA,
    activePage: 0,
    ellipsisBuffer: 5,
    totalItems: 100,
  },
  filterVal: {},
  reportUploadResponse: {},
};

// State for Report list defaults and reducers
const slice = createSlice({
  name: "additionalMainframeReporting",
  initialState: { ...defaultState },
  reducers: {
    reportListReceived: (state, action) => {
      if (action.payload && action.payload.success) {
        let list = [];
        if (action.payload?.data?.result?.content) {
          action.payload?.data?.result?.content.forEach((item) => {
            list.push({
              reportId: item.id,
              ...item,
            });
          });
        }

        state.reportList = list;

        const newPageInfo = {
          activeDelta: action.payload?.data?.result?.size || DEFAULT_DELTA,
          activePage: action.payload?.data?.result?.number + 1 || 1,
          ellipsisBuffer: 5,
          totalItems: action.payload?.data?.result?.totalElements || 0,
        };
        state.pagination = newPageInfo;
      }
    },
    reportListSortReceived: (state, action) => {
      if (action.payload) {
        state.sort = action.payload;
      }
    },
    reportListFilterReceived: (state, action) => {
      if (action.payload) {
        state.sort = { column: "", order: "" };
        state.filterVal = action.payload;
      }
    },
    reportListPaginationReceived: (state, action) => {
      if (action.payload) {
        state.pagination = action.payload;
      }
    },
    resetReportListReceived: (state) => {
      state.reportList = [];
    },
    onUploadReportResponse: (state, action) => {
      state.reportUploadResponse = { ...action.payload } || {};
    },
  },
});

export const {
  reportListReceived,
  reportListSortReceived,
  reportListFilterReceived,
  reportListPaginationReceived,
  resetReportListReceived,
  onUploadReportResponse,
} = slice.actions;

// Actions

export const loadReportList =
  (siteId, sortParams, filterParams, pageParams, userName) => (dispatch, getState) => {
    // Need to dispatch events, update state and then make API call
    if (sortParams) {
      dispatch(reportListSortReceived(sortParams));
    }
    if (filterParams) {
      dispatch(reportListFilterReceived(filterParams));
    }
    if (pageParams) {
      dispatch(reportListPaginationReceived(pageParams));
    }

    const { URL, body } = getURLWithParams(siteId, getState(), userName);

    return dispatch(
      apiCallBegan({
        url: URL,
        method: "POST",
        data: body,
        onSuccess: reportListReceived.type,
        onError: resetReportListReceived.type,
      }),
    );
  };

const getURLWithParams = (siteId, state, userName) => {
  let URL = ADDITIONAL_MF_REPORTING_URL.GET_REPORT_LIST;
  let body = {
    supportSiteId: siteId,
    uploadDate: "",
    submitterEmail: "",
    submitterName: "",
    customerName: "",
  };

  if (state?.additionalMainframeReporting?.pagination) {
    URL += `?size=${state.additionalMainframeReporting.pagination.activeDelta}&page=${
      state.additionalMainframeReporting.pagination.activePage - 1
    }`;
  }

  if (state?.additionalMainframeReporting?.filterVal) {
    const filters = { ...state.additionalMainframeReporting.filterVal };
    body = { ...body, ...filters };
  }

  if (state?.additionalMainframeReporting?.sort?.column) {
    const sortColumn = state?.additionalMainframeReporting?.sort?.column;
    const sortOrder = state?.additionalMainframeReporting?.sort?.order
      ? state?.additionalMainframeReporting?.sort?.order.toUpperCase()
      : "ASC";
    URL += `&sort=${sortColumn},${sortOrder}`;
  }
  return { URL, body };
};

export const uploadReport = (data) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: ADDITIONAL_MF_REPORTING_URL.UPLOAD_REPORT,
      method: "POST",
      data: data,
      isFormData: true,
      onSuccess: onUploadReportResponse.type,
      onError: onUploadReportResponse.type,
      skipErrorHandling: true,
      config: { headers: { "Content-Type": "multipart/form-data",isFormData: true } },
    }),
  );
};

export const resetUploadReportResponse = () => (dispatch, getState) => {
  dispatch(onUploadReportResponse({}));
};

export const resetFilters = () => (dispatch, getState) => {
  dispatch(reportListFilterReceived({}));
};

export const store = configureStore({
  reducer: slice.reducer,
});

// selectors
export const getReportList = (state) => state.additionalMainframeReporting.reportList;
export const getReportSortState = (state) => state.additionalMainframeReporting.sort;
export const getReportFilterState = (state) => state.additionalMainframeReporting.filterVal;
export const getReportPageState = (state) => state.additionalMainframeReporting.pagination;
export const getReportUploadResponse = (state) =>
  state.additionalMainframeReporting.reportUploadResponse;

// reducer
export const additionalMainframeReportingReducer = slice.reducer;
