import { configureStore as configStore } from "@reduxjs/toolkit";

import { configureGlobalActions } from "../global-actions/global-action-helper";
import apiMiddleware from "../middleware/api";
import { configureAxiosApi } from "../middleware/axios-api";
import { globalAPILoaderReducer } from "./slice/globalAPILoader.slice";
import { globalAlertHandlerReducer } from "./slice/globalAlertHandler.slice";
import { initializeAppReducer } from "./slice/initializeApp.slice";

export const configureStore = (configureOptions: any) => {
  const store = configStore({
    reducer: {
      ...configureOptions.reducer,
      globalApiLoader: globalAPILoaderReducer,
      globalAlertHandler: globalAlertHandlerReducer,
      initializeApp: initializeAppReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiMiddleware),
  });

  configureGlobalActions(store);
  configureAxiosApi(store);
  return store;
};
