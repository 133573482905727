import { Footer, GlobalAPILoader, GlobalAlertHandler } from "@common-components";

import "./App.scss";
import Routes from "./Routes";

function App() {
  return (
    <div className="app-container">
      <GlobalAPILoader />
      <GlobalAlertHandler />
      <Routes />
      <Footer />
    </div>
  );
}

export default App;
