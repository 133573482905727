import { BASEPATH } from "../../utils/constants/constants";

export const USER_DETAILS_URL = {
  GET_SIGNED_IN_USER_DETAILS: `${BASEPATH}/admin/get-user-details`,
  UPDATE_USER_PROFILE: `${BASEPATH}/profile/update`,
  USER_EVENT: `${BASEPATH}/audit/addUserEvent`,
  GET_USER_EVENT: `${BASEPATH}/audit/getUserEvent`,
  IS_ENTERPRISE_URL: `${BASEPATH}/scrt/reports/isEnterpriseUser`,
  VERIFY_SITE_ID: `${BASEPATH}/usermgmt/checkFirstTimeUser`,
  SITE_ACCESS_REQUEST: `${BASEPATH}/usermgmt/siteAccessRequest`,
};
