import { GET_MONTH_YEAR_AS_OBJ } from "@utils";
import isEmpty from "lodash.isempty";
import React from "react";

export const MONTHS = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];
export const DEFAULT_LABEL = { label: "", id: "" };
export const ACTUAL_CONSUMPTION = "actualConsumption";
export const ACTUAL_CONSUMPTION_STRING = "actualConsumptionStringValue";
export const BASELINE = "baseline";
export const YAXISKEY = ACTUAL_CONSUMPTION;
const overviewHeaderMap = {
  actualConsumption: "Total Usage",
  baseline: "Baseline",
  monthYear: "Month-Year",
  lpars: "Application Development/Test LPARs",
  productionUsage: "Production Usage",
  activeSerialNumberData: "Active Serial Number Data",
};

export const sorter = (a: any, b: any) => {
  if (a[1]?.v && b[1]?.v) {
    a = GET_MONTH_YEAR_AS_OBJ(a[1].v);
    b = GET_MONTH_YEAR_AS_OBJ(b[1].v);
    if (a.year !== b.year) {
      return a.year - b.year;
    } else {
      return MONTHS.indexOf(a.month.toUpperCase()) - MONTHS.indexOf(b.month.toUpperCase());
    }
  }
};

export const lowerHeader = (headerName: string) => headerName.toLowerCase().replace(" ", "");
export const getMaxBaseline = (current: number, latest: number) => Math.max(current, latest);

export const ActiveSerialNumber = (props: any) => {
  const { value } = props;
  return (
    <div className="active-serial-number">
      {value &&
        value.length &&
        value.map((tag: string, index: number) => {
          return (
            <React.Fragment key={index}>
              {tag && (
                <>
                  <span>{tag}</span>
                  <br />
                </>
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
};

//To Format YAxis Tick value with commas
export const formatTick = (value: any) => {
  return !value ? "0" : numberWithCommas(value);
};

export const dashboardBody = (siteId: any) => {
  return {
    prompts: [
      {
        id: "SCRT_Postgres.API_FINAL.cust_support_site_id",
        column: "SCRT_Postgres.API_FINAL.cust_support_site_id",
        type: "dim",
        label: "Support Site Id",
        op: "IN",
        values: [siteId[0].id],
      },
    ],
    resolveVariables: true,
  };
};

export const serialNumBody = (siteId: any) => {
  return {
    prompts: [
      {
        id: "SCRT_Postgres.API_MACHINE_SERIAL_NUM.cust_support_site_id",
        column: "SCRT_Postgres.API_MACHINE_SERIAL_NUM.cust_support_site_id",
        type: "dim",
        label: "Support Site Id",
        op: "IN",
        values: [siteId[0].id],
      },
    ],
    resolveVariables: true,
  };
};

//@Input: 123456789
//@output 123,456,789
export const numberWithCommas = (x: number) => {
  return !x ? "-" : x.toLocaleString(); //x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

//@Input: [1, 2, 3, 4]
//@Output: 10
export const getSum = (data: any, idx: number) => {
  const values = data.map((i: any) => Number(i[idx]["rv"]));
  return numberWithCommas(values.reduce((partialSum: number, a: number) => partialSum + a, 0));
};

//@Input - 111
//@Output - 120
export const getRoundedMaxValue = (val: number) => {
  return !val
    ? val
    : Math.ceil(val / Math.pow(10, val.toString().length - 2)) *
        Math.pow(10, val.toString().length - 2);
};

//Convert object to CSV
const convertToCSV = (objArray: any, reportingPeriod: string) => {
  const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "";
  //Since reporting period is common for all rows adding as first row
  str += `Reporting Period,${reportingPeriod}\r\n`;

  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let index in array[i]) {
      if (line !== "") line += ",";
      line += array[i][index];
    }
    str += line + "\r\n";
  }
  return str;
};

//Export JSON to CSV and download
const exportCSVFile = (headers: any, items: any, fileTitle: string, reportingPeriod: string) => {
  if (headers) {
    items.unshift(headers);
  }
  // Convert Object to JSON
  const jsonObject = JSON.stringify(items);
  const csv = convertToCSV(jsonObject, reportingPeriod);
  const exportedFilenmae = fileTitle + ".csv" || "export.csv";
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    let link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

//To sort Object Keys in alpabetical order
const sortObjectKeys = (data: any) => {
  return Object.keys(data)
    .sort()
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: data[key],
      }),
      {},
    );
};

//To merge all tables data and export report
export const downloadMergedData = (
  overviewData: any,
  activeSerialNumberData: any,
  filename: string,
) => {
  let mergedData = { ...overviewData };
  Object.keys(mergedData).forEach((item) => {
    mergedData[item]["activeSerialNumberData"] =
      (!isEmpty(activeSerialNumberData) &&
        !isEmpty(activeSerialNumberData[0]) &&
        activeSerialNumberData[0][item] &&
        activeSerialNumberData[0][item].filter((i: any) => i !== "-").join(" | ")) ||
      "-";
    mergedData[item]["lpars"] =
      (!isEmpty(mergedData[item]["lpars"]) &&
        mergedData[item]["lpars"].replaceAll(",-", "").replaceAll(",", " | ")) ||
      "-";
  });
  mergedData = Object.values(mergedData);
  let reportingPeriod = "";
  //deleting keys that are not required in CSV download
  mergedData = mergedData.map((i: any, index: number) => {
    if (index === 0) {
      reportingPeriod = i["reportingField"];
    }
    delete i["actualConsumptionStringValue"];
    delete i["baselineStringValue"];
    delete i["reportingValue"];
    delete i["msuPercentage"];
    delete i["headerName"];
    delete i["reportingField"];
    return sortObjectKeys(i);
  });
  exportCSVFile(sortObjectKeys(overviewHeaderMap), mergedData, filename, reportingPeriod);
};

export const getFileName = (siteId: any, type: string) => {
  return `SCRT_${type}_${siteId}_${Date.now()}`;
};
