import ClayLayout from "@clayui/layout";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import React from "react";
import { useSelector } from "react-redux";

function Documentation(props: any) {
  const envVars = useSelector(getEnvVars);
  const TECH_DOCS_URL = envVars.REACT_APP_TECH_DOCS_URL;

  return (
    <>
      <ClayLayout.Row justify="start">
        <ClayLayout.Col className="pl-4">
          Click{" "}
          <a href={TECH_DOCS_URL} target="_blank" rel="noreferrer">
            here
          </a>{" "}
          to access the user documentation, which provides detailed instructions about using this
          site.
        </ClayLayout.Col>
      </ClayLayout.Row>
    </>
  );
}

export default Documentation;
