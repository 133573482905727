import ClayButton from "@clayui/button";
import { CrossIcon, DrawerMaterial } from "@common-components";
import { MAPPER_PREVIEW_TAB, PREVIEW_TABLE_HEADERS } from "@constants";
import { formatPreviewObject } from "@utils";
import React from "react";

import "./preview.scss";
import { PreviewTable } from "./previewTable/PreviewTable";

const Preview = (props: any) => {
  const { isDrawerOpen, handleDrawerChange, previewDetailsData } = props;

  const getSecondHeader = (header: string) => {
    if (!header) {
      return "";
    }
    return !!PREVIEW_TABLE_HEADERS.includes(header) ? "TOTAL MSUs" : "";
  };

  return (
    <div>
      <React.Fragment>
        <DrawerMaterial
          anchor="right"
          open={isDrawerOpen}
          onClose={() => handleDrawerChange(false)}
          ModalProps={{ style: { position: "absolute", width: "27rem" } }}
        >
          <div className="drawer">
            <div className="cross-icon">
              <ClayButton
                className="btn-secondary border-0"
                onClick={() => handleDrawerChange(false)}
              >
                <CrossIcon></CrossIcon>
              </ClayButton>
            </div>
            {Object.keys(previewDetailsData).map((key: string, index: number) => {
              return (
                <div key={index} className="preview-table">
                  <PreviewTable
                    data={previewDetailsData[key]}
                    header={MAPPER_PREVIEW_TAB[key] || formatPreviewObject(key)}
                    secondHeader={getSecondHeader(key)}
                  />
                </div>
              );
            })}
          </div>
        </DrawerMaterial>
      </React.Fragment>
    </div>
  );
};

export { Preview };
