import ClayLink from "@clayui/link";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import React from "react";
import { useSelector } from "react-redux";

import "./ecxRedirect.scss";

export default function ECXRedirect() {
  const envVars = useSelector(getEnvVars);

  return (
    <div className="content">
      <div>
        Welcome to Broadcom SCRT Report Management. To access the website, complete the following
        steps:
      </div>
      <div className="notes">
        1. Complete your profile by clicking{" "}
        <ClayLink href={envVars.ECX_PROFILE} target="_blank">
          {"here."}
        </ClayLink>
      </div>
      <div className="notes">
        2. When you are notified that you have been upgraded to an enterprise user, log into the
        SCRT website and request access to upload SCRT reports.
      </div>
    </div>
  );
}
