import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { useSelector } from "react-redux";

const GoogleTagManager = () => {
  const envVariables = useSelector(getEnvVars);
  const gtmId = envVariables?.GTM_ID || "";

  useEffect(() => {
    TagManager.initialize({ gtmId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default GoogleTagManager;
