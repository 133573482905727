import { FaqsList } from "@common-components";
import React from "react";

import { AMR_INSTRUCTIONS } from "../Constants";

function Instructions(props: any) {
  return (
    <React.Fragment>
      <FaqsList
        questions={AMR_INSTRUCTIONS}
        customAccordionOpen={1}
        showHeader={false}
        className="accordion-repository-summary"
      />
    </React.Fragment>
  );
}

export default Instructions;
