import React from "react";

import { Announcement } from "./annoucement/Announcement";
import { ANNOUNCEMENTS } from "./constants";
import "./home.scss";

function Home() {
  const backgroundImageUrl = require(`../../common-ui-modules/images/background/${
    new Date().getDay() + 1
  }.jpg`).default;

  return (
    <React.Fragment>
      <div
        className="image-background"
        style={{
          backgroundImage: `url(${backgroundImageUrl})`,
        }}
      >
        <div className="sign-in-image">
          <div className="overlap-text">
            <Announcement announcement={ANNOUNCEMENTS} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Home;
