import LoadingGif from "@common-images/loading.gif";
import { getLoderStatus } from "@common-services/redux-helper/store/slice/globalAPILoader.slice";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";

function GlobalAPILoader(props: any) {
  const { showLoader = false } = props;
  const loader = useSelector(getLoderStatus);
  return (
    <Fragment>
      {(loader || showLoader) && (
        <div className="global-loader-wrapper">
          <img className="global-loader-icon" src={LoadingGif} alt={"Loading ..."} />
        </div>
      )}
    </Fragment>
  );
}

export { GlobalAPILoader };
