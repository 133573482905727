import ClayIcon from "@clayui/icon";
import { ShowMoreLess } from "@common-components";
import { claySpriteImagePath } from "@common-services/util/claySpriteImagePath";
import Tooltip from "@mui/material/Tooltip";
import { capitalizeFirstLetter, getUserTime, tokenize } from "@utils";
import React from "react";

function TableCellRenderer(props: any) {
  const {
    rowData,
    type,
    fieldName,
    skipTimezone = "false",
    obj = {},
    fieldType = "",
    labelIconTitle = "",
  } = props;
  const formatData = { ...rowData };

  if (type === "date" && skipTimezone === "true") {
    formatData[fieldName] =
      (rowData && rowData[fieldName] && rowData[fieldName].split("T")[0]) || "";
  }

  const renderType = () => {
    switch (type) {
      case "active":
        if (fieldType === "boolean") {
          return (
            <div>
              <span>{formatData[fieldName] ? "Yes" : "No"}</span>
            </div>
          );
        }
        return (
          <div>
            <span>{formatData[fieldName] === "Y" ? "Active" : "Inactive"}</span>
          </div>
        );
      case "date":
        return (
          <>
            {skipTimezone === "true"
              ? formatData[fieldName]
              : getUserTime(formatData[fieldName], skipTimezone)}
          </>
        );
      case "capitalize":
        return <>{capitalizeFirstLetter(formatData[fieldName])}</>;
      case "tokenize":
        return <>{tokenize(formatData[fieldName])}</>;
      case "list":
        return <ShowMoreLess data={formatData[fieldName]?.join(", ") || ""}></ShowMoreLess>;
      case "html":
        return <span dangerouslySetInnerHTML={{ __html: formatData[fieldName] }}></span>;
      case "icon":
        if (obj[formatData[fieldName]] === "Mailbox") {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <Tooltip title="Mailbox" placement="top" arrow>
                <ClayIcon symbol="envelope-open" spritemap={claySpriteImagePath()} />
              </Tooltip>
            </div>
          );
        }
        return (
          <div className="d-flex justify-content-center align-items-center">
            <Tooltip title="Website" placement="top" arrow>
              <ClayIcon symbol="desktop" spritemap={claySpriteImagePath()} />
            </Tooltip>
          </div>
        );
      //to add label and icon with tooltip
      case "labelIcon":
        return (
          <div className="d-flex justify-content-center align-items-center">
            {formatData[fieldName]}{" "}
            <Tooltip title={labelIconTitle} placement="top" arrow>
              <ClayIcon symbol="info-panel-closed" spritemap={claySpriteImagePath()} />
            </Tooltip>
          </div>
        );
      case "booleanText":
        return (
          <span>
            {formatData[fieldName] === true ? "Y" : formatData[fieldName] === false ? "N" : "N/A"}
          </span>
        );
      default:
        return <></>;
    }
  };
  return renderType();
}

export default TableCellRenderer;
