import ClayCard from "@clayui/card";
import ClayLayout from "@clayui/layout";
import { PageTitle, Table } from "@common-components";
import React from "react";

const USER_COLUMNS = [
  {
    headerName: "Name",
    field: "name",
    sortable: false,
  },
  { headerName: "Email", field: "email", sortable: false },
];

function EnterprisePending(props: any) {
  const { adminsVOList: adminDetails } = props;
  return (
    <React.Fragment>
      <ClayLayout.ContainerFluid view size={false}>
        <ClayCard className="m-3 w-100">
          <ClayCard.Body className="p-4">
            <ClayLayout.Row justify="start" className="align-items-center">
              <ClayLayout.Col className="d-flex justify-content-center align-items-center text-center p-4">
                <PageTitle title={"Your SCRT access is pending approval."} color={"okta"} />
              </ClayLayout.Col>
            </ClayLayout.Row>
            <ClayLayout.Row justify="start" className="align-items-center px-4 pb-4">
              <b>One of the following SCRT Account Administrators must approve your access.</b>
            </ClayLayout.Row>
            <Table
              rowClassName="users-table"
              definitions={USER_COLUMNS}
              dataSource={adminDetails}
              borderedColumns={true}
              borderless={true}
              headVerticalAlignment={"middle"}
              headingNoWrap={true}
              hover={false}
              noWrap={true}
              responsive={true}
              responsiveSize={"sm"}
              striped={true}
              hideFilter={true}
              tableVerticalAlignment={"middle"}
            />
          </ClayCard.Body>
        </ClayCard>
      </ClayLayout.ContainerFluid>
    </React.Fragment>
  );
}

export default EnterprisePending;
