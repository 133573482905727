import { ROLE_SUPER_ADMIN } from "@constants";
import moment from "moment";

export const getConfirmHeaderText = (role: any) => {
  return `IMPORTANT: Some serial numbers have been updated with feedback${
    role === ROLE_SUPER_ADMIN ? "/ Comissioned-Decomissioned Date/ Comments" : ""
  } but with <b>NO change in status</b>. Select <b>Close</b> to revert to the previous screen or select <b>Confirm</b> to proceed with the same.`;
};
export const FEEDBACK_OPTIONS = [
  { label: "Select", value: "" },
  { label: "Upgrade", value: "Upgrade" },
  { label: "Upgraded to z15", value: "Upgraded to z15" },
  { label: "Upgraded to z16", value: "Upgraded to z16" },
  { label: "Replaced hardware", value: "Replaced hardware" },
  { label: "De-installed hardware", value: "De-installed hardware" },
  { label: "Others", value: "Others" },
];

export const FeedBackHeaderRenderer = (props: any) => {
  const { name = "Feedback", required = true } = props;
  return (
    <span className={"pr-1"}>
      {name}
      {!!required && <span className="text-red">*</span>}
    </span>
  );
};

//To format date
//@Input - 2022-12-29T18:30:00.000+00:00
//@Output - 2022-12-29
export const formatDate = (date: any) => {
  return !date ? date : moment(date).format("yyyy-MM-DD");
};

export const getUTCTime = (date: any) => {
  if (!date) return "";
  const now = date ? new Date(date) : new Date();
  return new Date(now.getTime() + now.getTimezoneOffset() * 60000);
};

//To compare comm/decomm dates
//Eg: Status = "A" & commissionedDate = '2022-08-02T00:00:00.915+00:00', commDecommDate = '2022-08-20'
//then commDecommDate is same and flag can be set to false as 08-20 resolves to 08-02
export const compareCommDecommDateIfSame = (commDecommData: any) => {
  if (
    commDecommData.status === "A" &&
    !!commDecommData.commissionedDate &&
    !!commDecommData.commDecommDate
  ) {
    return (
      formatDate(commDecommData.commissionedDate) !== formatDate(commDecommData.commDecommDate)
    );
  } else if (
    commDecommData.status !== "A" &&
    !!commDecommData.decommissionedDate &&
    !!commDecommData.commDecommDate
  ) {
    return (
      formatDate(commDecommData.decommissionedDate) !== formatDate(commDecommData.commDecommDate)
    );
  } else {
    return true;
  }
};

//TO Update Comm/Decomm date
//For Status = "A" Comissioned Date -> Set to 2nd of month
//For Status = "D/F" Decomissioned Date -> set to 1st of month
export const getCommDecommDate = (data: any, role: string) => {
  let newData = { ...data };
  let decomlist = newData.serialNumberDecommisionedAuditDtoList.map((item: any) => {
    if (role === ROLE_SUPER_ADMIN && item.commDecommDate) {
      item.commDecommFlag = true;
      const month = `0${new Date(item.commDecommDate).getMonth() + 1}`.slice(-2);
      const year = new Date(item.commDecommDate).getFullYear();
      if (item.status === "A") {
        item.commDecommDate = `${year}-${month}-02`;
      } else {
        //To add decomm date same as comm date if comm date > decomm date
        item.commDecommDate = `${year}-${month}-01`;
        if (item.commissionedDate && moment(item.commissionedDate) > moment(item.commDecommDate)) {
          item.commDecommDate = item.commissionedDate;
        }
      }
    } else if (role === ROLE_SUPER_ADMIN && !item.commDecommDate) {
      item.commDecommFlag = false;
      //For super admins and no date changed pass comm (A)/decomm date (D/F) based on status
      if (item.status === "A") {
        item.commDecommDate = item.commissionedDate;
      } else {
        item.commDecommDate = item.decommissionedDate;
      }
    } else if (role !== ROLE_SUPER_ADMIN) {
      //For non-super admins pass null always
      item.commDecommDate = "";
      item.commDecommFlag = false;
    }
    return item;
  });
  decomlist = decomlist.map((i: any) => {
    i["feedbackChanged"] = i["feedbackChangedInternal"] || i["feedbackChanged"];
    delete i["feedbackChangedInternal"];
    return i;
  });
  //To send only feedback or any value changed serial numbers
  decomlist = decomlist.filter((i: any) => {
    //If Super Admin Date Changed and commDecomm is same as Comissioned/Decomissioned Date
    //then just close modal
    const commDecommSameDate =
      role === ROLE_SUPER_ADMIN && !!i.commDecommFlag && !i.feedbackChanged && !i.changed
        ? !!compareCommDecommDateIfSame(i)
        : true;
    return !!commDecommSameDate && (!!i.changed || !!i.feedbackChanged || !!i.commDecommFlag);
  });
  if (!decomlist.length) {
    return null;
  }
  newData["serialNumberDecommisionedAuditDtoList"] = decomlist;
  return newData;
};
