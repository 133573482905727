//import { store } from "@store/configureStore";
import { getAuthToken } from "@utils";

function authToken(url: string, data: any, method: string) {
  //const disableOkta = store.getState().initializeApp.envVars.DISABLE_OKTA;
  // let token;
  // let oktaStorage;
  // if (disableOkta === "true") {
  //   token = localStorage.getItem("email");
  // } else {

  return { Authorization: `Bearer ${getAuthToken()}` };
}

export default authToken;
