import ClayCard from "@clayui/card";
import ClayLayout from "@clayui/layout";
import { PageTitle } from "@common-components";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import { ROUTE_PATHS } from "@constants";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

function GettingStarted() {
  const envVars = useSelector(getEnvVars);
  const pageTitle = `Getting Started`;
  const TECH_DOC_URL = "" + envVars.REACT_APP_TECH_DOCS_URL;
  const SUPPORT_URL = "" + envVars.REACT_APP_SUPPORT_URL;
  const USERS_ADMINISTRATION = "" + envVars.REACT_APP_ECX_USERS_ADMINISTRATION;

  const isOktaEnabled = envVars.DISABLE_OKTA !== "true";
  const isValidUser = localStorage.getItem("okta-valid-user") || "";

  useEffect(() => {
    if (isOktaEnabled && isValidUser !== "true") {
      window.location.assign(ROUTE_PATHS.OKTA_REDIRECT);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <ClayLayout.ContainerFluid view size={false}>
        <ClayLayout.Row justify="start" className="align-items-center">
          <ClayLayout.Col className="d-flex align-items-center">
            <PageTitle title={pageTitle} />
          </ClayLayout.Col>
        </ClayLayout.Row>
        <ClayLayout.Row>
          <ClayCard className="m-3 w-100">
            <ClayCard.Body className="p-4">
              <p>
                <i>Welcome to Broadcom SCRT Report Management!</i>
              </p>
              <div className="p-mb-2"></div>
              <p>This website is used to submit and manage your Broadcom ISV SCRT reports.</p>
              <p>
                <b>Need help?</b> The following resources are available:
              </p>
              <div className="p-mb-2"></div>
              <ul>
                <li>
                  To review a list of frequently asked questions (FAQs) or look up error messages,
                  select the <a href="./help">Need Help</a> tab.
                </li>
                <li>
                  For complete instructions on using this site, click{" "}
                  <a href={TECH_DOC_URL} target="_blank" rel="noreferrer">
                    here
                  </a>
                  .
                </li>
                <li>
                  To contact Support, click{" "}
                  <a href={SUPPORT_URL} target="_blank" rel="noreferrer">
                    here
                  </a>
                  .
                </li>
              </ul>
              <div className="p-mb-3"></div>
              <p>
                Go to the <a href="./repository">Repository</a> page to upload SCRT reports to a
                secure, central repository. From this page, you can also:
              </p>
              <ul>
                <li>
                  Preview a report to see relevant information, such as LPARs and MSUs for each
                  machine.
                </li>
                <li>
                  Download reports to review and validate your site's usage against your contract.
                </li>
                <li>Delete and resubmit reports that require changes.</li>
              </ul>
              <div className="p-mb-3"></div>
              <p>
                Go to the{" "}
                <a href="./additional-mainframe-reporting">Additional Mainframe Reporting </a> page
                to upload other documents, such as reports for non-z/OS product usage. You can also
                download documents from this page to review previous submissions.
              </p>
              <p>
                Users with SCRT Account Administrator permissions can perform the following tasks:
              </p>
              <ul>
                <li>
                  Manage user access on the{" "}
                  <a href={USERS_ADMINISTRATION} target="_blank" rel="noreferrer">
                    SCRT User Administration
                  </a>{" "}
                  page.
                </li>
                <li>
                  Manage the status of your site's machines on the <a href={"./sites"}>Sites</a>{" "}
                  page.
                </li>
                <li>
                  View activity logs on the <a href="./repository">Repository</a> page.
                </li>
              </ul>
              <div className="p-mb-3"></div>
              <p>
                Mainframe Consumption License (MCL) customers can view their monthly MSU consumption
                on the <a href={"./overview"}>Overview</a> page.
              </p>
              <div className="p-mb-2"></div>
            </ClayCard.Body>
          </ClayCard>
        </ClayLayout.Row>
      </ClayLayout.ContainerFluid>
    </React.Fragment>
  );
}

export default GettingStarted;
