import ClayForm, { ClayCheckbox } from "@clayui/form";
import { ErrorMessage, Field } from "formik";
import React from "react";

import TextError from "./TextError";

function Checkbox(props: any) {
  const { label, name } = props;
  return (
    <div>
      {/* <label htmlFor={name}>{label}</label> */}
      <Field name={name}>
        {({ form, field }: { form: any; field: any }) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <ClayForm.Group
              className={
                "form-group-sm " +
                (form.errors[name] && form.touched[name] !== undefined ? "has-error" : "")
              }
            >
              <ClayCheckbox
                aria-label={label}
                checked={value}
                label={label}
                onChange={(e) => {
                  setFieldValue(name, e.target.checked);
                }}
                {...field}
                {...props}
              />
              <ErrorMessage name={name} component={TextError} />
            </ClayForm.Group>
          );
        }}
      </Field>
    </div>
  );
}

export default Checkbox;
