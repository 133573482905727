import { ClayButtonWithIcon } from "@clayui/button";
import Sprite from "@common-images/clay/sprite.svg";
import Tooltip from "@mui/material/Tooltip";

import "./iconButton.scss";

const IconButton = (props: any) => {
  const { title, symbol, show, onClick, buttonText, hidden = false, className = "" } = props;
  return (
    <>
      {show && (
        <>
          <Tooltip title={title} placement="top" arrow>
            <div>
              <ClayButtonWithIcon
                className={`clay-button-icon btn-secondary ${className}`}
                spritemap={Sprite}
                symbol={symbol}
                onClick={onClick}
                hidden={hidden}
                value={buttonText}
              ></ClayButtonWithIcon>
            </div>
          </Tooltip>
        </>
      )}
    </>
  );
};

export { IconButton };
