import ClayLayout from "@clayui/layout";
import ArrowForwardIosSharp from "@mui/icons-material/ArrowForwardIosSharp";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";

import "./accordion.scss";

function Panel(props: any) {
  return (
    <ClayLayout.Row key={props.data} justify="start">
      <ClayLayout.Col>
        <Accordion
          defaultExpanded={props.customAccordionOpen === props.id}
          disableGutters
          elevation={0}
          className="accordion-border"
        >
          <AccordionSummary
            expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
            className={`${props.className || "accordian-summary"}`}
          >
            <Typography className="accordion-typography">
              <span dangerouslySetInnerHTML={{ __html: props.data.question }}></span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="accordion-details">
            <Typography dangerouslySetInnerHTML={{ __html: props.data.answer }}></Typography>
          </AccordionDetails>
        </Accordion>
      </ClayLayout.Col>
    </ClayLayout.Row>
  );
}
export default Panel;
