import { IconButton } from "@common-components";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import { downloadReport } from "@store/download/slice";
import { useDispatch, useSelector } from "react-redux";

const DownloadComponent = (props: any) => {
  const { reportIdList, source, toolTip, fileName, handleClose } = props;
  const dispatch = useDispatch();
  const envVars = useSelector(getEnvVars);

  const handleDownload = (reportIdList: number[], source: string) => {
    if (handleClose) {
      handleClose();
    }
    dispatch(downloadReport(reportIdList, source, envVars, fileName));
  };

  return (
    <div className="d-flex">
      <IconButton
        show={true}
        title={toolTip || "Download Report"}
        symbol="download"
        className="scrt-repo-action-button"
        onClick={() => handleDownload(reportIdList, source)}
        buttonText="DOWNLOAD ALL"
      ></IconButton>
    </div>
  );
};
export { DownloadComponent };
