import ClayButton from "@clayui/button";
import ClayDropDown from "@clayui/drop-down";
import ClayForm, { ClayCheckbox, ClayInput } from "@clayui/form";
import ClayLabel from "@clayui/label";
import { claySpriteImagePath } from "@common-services/util/claySpriteImagePath";
import { ErrorMessage, Field } from "formik";
import React, { useEffect, useState } from "react";

import TablePagination from "../../tables/TablePagination/TablePagination";
import TextError from "./TextError";

const DropdownWithCheckbox = (props: any) => {
  const {
    label,
    id,
    name,
    placeholder,
    validate,
    required,
    count,
    items,
    selected,
    searchable,
    filteredList,
    parentPagination,
    handleParentPagination,
    enterpriseFilterList,
    onFilterCheckboxChange,
    searchString,
    setSearchString,
    handleSelectAllEnterpriceFilter,
    isAllEnterpriseFilterSelected,
  } = props;
  let checkedFilteredList: Set<string> = new Set();
  const [listToDisplay, setListToDisplay] = useState([]);

  const [active, setActive] = useState(false);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState(
    parentPagination
      ? parentPagination
      : {
          activeDelta: 25,
          activePage: 1,
          ellipsisBuffer: 1,
          totalItems: 0,
        },
  );

  useEffect(() => {
    if (filteredList.length > 0) {
      if (parentPagination) {
        setPaginationInfo(parentPagination);
      } else {
        paginationInfo.totalItems = filteredList.length;
      }
      setListToDisplay(filteredList);
    } else {
      if (parentPagination) {
        setPaginationInfo(parentPagination);
      } else {
        paginationInfo.totalItems = items.length;
      }
      setListToDisplay(items);
    }
  }, [items, filteredList]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkBoxClicked = (val: boolean, item: any) => {
    props.onChange(val, item);
  };
  const closeLabel = (val: any) => {
    props.onCloseLabel(val);
  };

  const handlePageChange = (activePage: any) => {
    const newPageInfo = { ...paginationInfo, activePage };
    setPaginationInfo(newPageInfo);
    if (parentPagination) {
      handleParentPagination(newPageInfo);
    }
  };

  const handleActiveDeltaChange = (delta: any) => {
    const newPageInfo = { ...paginationInfo, activeDelta: delta };
    setPaginationInfo(newPageInfo);
    if (parentPagination) {
      handleParentPagination(newPageInfo);
    }
  };

  return (
    <div>
      {label && (
        <label htmlFor={name}>
          {required && <span className="text-red">* </span>}
          {label}
        </label>
      )}
      <Field name={name} validate={validate}>
        {({ form, field }: { form: any; field: any }) => {
          return (
            <ClayForm.Group
              className={
                "dropdown-checkbox form-group-sm " +
                (form.errors[name] && form.touched[name] !== undefined ? "has-error" : "")
              }
            >
              <ClayDropDown
                trigger={
                  <ClayInput
                    type="text"
                    placeholder={placeholder || label}
                    {...field}
                    id={id}
                    value={count}
                    autoComplete={"off"}
                  />
                }
                active={active}
                onActiveChange={setActive}
                menuElementAttrs={{ className: "overflow-hidden menu-width menu-height" }}
              >
                <ClayDropDown.ItemList style={{ width: "37rem" }}>
                  {searchable && (
                    <React.Fragment>
                      <div className="m-2">
                        <ClayInput
                          className="input-group-inset-item-before"
                          type="text"
                          id="search"
                          value={searchString}
                          onChange={(event) => setSearchString(event.target.value)}
                          autoComplete={"off"}
                          placeholder={props.selectAll ? "" : "Search..."}
                          readOnly={props.selectAll}
                        />
                      </div>
                      {
                        <ClayDropDown.ItemList className="d-flex justify-content-start align-items-center">
                          <div className="d-flex pl-2">
                            <ClayDropDown
                              trigger={
                                <ClayInput
                                  className="d-flex mh-10"
                                  type="text"
                                  placeholder="Filter by"
                                  id={id}
                                  value={count}
                                  autoComplete={"off"}
                                  readOnly
                                />
                              }
                              active={dropdownActive}
                              onActiveChange={setDropdownActive}
                            >
                              <ClayDropDown.ItemList>
                                <ClayDropDown.Divider></ClayDropDown.Divider>
                                <ClayDropDown.Group>
                                  <ClayDropDown.Item
                                    onClick={(event) => {
                                      handleSelectAllEnterpriceFilter &&
                                        handleSelectAllEnterpriceFilter(
                                          !isAllEnterpriseFilterSelected,
                                        );
                                    }}
                                  >
                                    <div className="d-flex">
                                      <ClayCheckbox
                                        checked={isAllEnterpriseFilterSelected}
                                        onChange={(event) => {
                                          //Empty method
                                        }}
                                      />
                                      <div className="ml-2">{"Select All"}</div>
                                    </div>
                                  </ClayDropDown.Item>
                                </ClayDropDown.Group>
                                <ClayDropDown.Divider></ClayDropDown.Divider>
                                <ClayDropDown.Group>
                                  {enterpriseFilterList.map((item: any, i: number) => {
                                    item.checked && checkedFilteredList.add(item.label);
                                    return (
                                      <ClayDropDown.Item
                                        onClick={(event) => {
                                          onFilterCheckboxChange &&
                                            onFilterCheckboxChange(i, !item.checked);
                                        }}
                                        key={i}
                                      >
                                        <div className="d-flex">
                                          <ClayCheckbox
                                            checked={item.checked}
                                            onChange={(event) => {
                                              //Empty method
                                            }}
                                          />
                                          <div className="ml-2">{item.label}</div>
                                        </div>
                                      </ClayDropDown.Item>
                                    );
                                  })}
                                </ClayDropDown.Group>
                              </ClayDropDown.ItemList>
                            </ClayDropDown>
                          </div>
                          {listToDisplay.length > 0 && (
                            <ClayDropDown.Item
                              style={{ paddingTop: "2px", paddingBottom: "1px" }}
                              onClick={(e: any) => {
                                props.handleSelectAll(!props.selectAll);
                              }}
                            >
                              <div className="d-flex">
                                <ClayCheckbox
                                  checked={props.selectAll}
                                  onChange={(e: any) => {
                                    props.handleSelectAll(e.target.checked);
                                  }}
                                />
                                <div className="ml-2">Select All Users</div>
                              </div>
                            </ClayDropDown.Item>
                          )}
                        </ClayDropDown.ItemList>
                      }
                    </React.Fragment>
                  )}

                  <div className="d-flex pl-2 mt-1">
                    {isAllEnterpriseFilterSelected ? (
                      <ClayLabel spritemap={claySpriteImagePath()} displayType="secondary">
                        {"All Filters"}
                      </ClayLabel>
                    ) : (
                      <>
                        {checkedFilteredList.size > 0 &&
                          Array.from(checkedFilteredList)
                            .slice(0, 4)
                            .map((item) => (
                              <ClayLabel spritemap={claySpriteImagePath()} displayType="secondary">
                                {item}
                              </ClayLabel>
                            ))}
                        {checkedFilteredList.size > 4 && (
                          <ClayLabel spritemap={claySpriteImagePath()} displayType="secondary">
                            {`+ ${checkedFilteredList.size - 4} more`}
                          </ClayLabel>
                        )}
                      </>
                    )}
                  </div>

                  <ClayDropDown.Divider />
                  <TablePagination
                    elementAttrs={{ className: "pagination-sm" }}
                    style={{ "padding-top": "0 !important", "margin-left": "1rem" }}
                    pagination={paginationInfo}
                    onActiveDeltaChange={handleActiveDeltaChange}
                    onActivePageChange={handlePageChange}
                  />
                  <ClayDropDown.Divider />
                  {listToDisplay.length > 0 ? (
                    <div className="overflow-auto" style={{ height: "15rem" }}>
                      <ClayDropDown.ItemList>
                        {active &&
                          (!parentPagination
                            ? listToDisplay.slice(
                                paginationInfo.activeDelta * (paginationInfo.activePage - 1),
                                paginationInfo.activeDelta * paginationInfo.activePage,
                              )
                            : listToDisplay
                          ).map((item: any, i: number) => (
                            <ClayDropDown.Item
                              disabled={props.selectAll}
                              key={i + item.id}
                              onClick={(e) => {
                                checkBoxClicked(!item.checked, item);
                              }}
                            >
                              <div className="d-flex">
                                <ClayCheckbox
                                  checked={item.checked}
                                  onChange={(event) => {
                                    if (!item.checked) {
                                      checkBoxClicked(event.target.checked, item);
                                    }
                                  }}
                                />
                                <div className="ml-2">{item.label}</div>
                              </div>
                            </ClayDropDown.Item>
                          ))}
                      </ClayDropDown.ItemList>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center text-muted">
                      <span style={{ color: "#b2b2b2" }}>No records found</span>
                    </div>
                  )}
                  <ClayDropDown.Divider />
                  {selected.length > 0 && (
                    <div className="d-flex flex-row-reverse mr-2">
                      <ClayButton displayType="secondary" onClick={() => setActive(false)}>
                        OK
                      </ClayButton>
                    </div>
                  )}
                </ClayDropDown.ItemList>
              </ClayDropDown>
              {selected.slice(0, 3).map((val: any, idx: number) => (
                <ClayLabel
                  key={idx + val.id}
                  closeButtonProps={{ onClick: () => closeLabel(val) }}
                  spritemap={claySpriteImagePath()}
                  displayType="secondary"
                >
                  {val.label}
                </ClayLabel>
              ))}
              {selected.length > 3 && (
                <>
                  <ClayLabel spritemap={claySpriteImagePath()} displayType="secondary">
                    {`+ ${selected.length - 3} more`}
                  </ClayLabel>
                </>
              )}
              <ErrorMessage name={name} component={TextError} />
            </ClayForm.Group>
          );
        }}
      </Field>
    </div>
  );
};

export default DropdownWithCheckbox;
