import { ClayRadio, ClayRadioGroup, ClaySelectWithOption } from "@clayui/form";
import React from "react";
import DatePicker from "react-date-picker";

import { getUTCTime } from "./constants";
import "./sites.scss";

function SiteModalTableRenderer(props: any) {
  const { type, value, rowData, onChange, index, feedbackOptions } = props;

  const isActiveRow = rowData.status === "A";

  //Show commissioned date when active and decommissioned date when D/F
  const commDecomDate = isActiveRow
    ? rowData.commissionedDate || ""
    : rowData.decommissionedDate || "";

  //Trueup Period Start Date <= Commissioned Date <= Current date
  //Latest Commissioned date <= Decommissioned date <= Latest TrueUp End date
  const minDate = isActiveRow
    ? props.trueUpStartDate
    : rowData.commissionedDate || props.trueUpStartDate;

  //Trueup Period Start Date <= Commissioned Date <= Current date
  //Latest Commissioned date <= Decommissioned date <= Latest TrueUp End date
  const maxDate = isActiveRow ? new Date() : props.trueUpEndDate;

  const datePickerVal = rowData.commDecommDate || getUTCTime(commDecomDate);

  const renderComponent = () => {
    switch (type) {
      case "status":
        return (
          <ClayRadioGroup
            defaultValue="Y"
            inline
            onSelectedValueChange={(value) => {
              onChange(value, rowData.serialNumber, index, "status");
              onChange("", rowData.serialNumber, index, "commDecommDate");
            }}
            selectedValue={value}
            key={rowData.status + value + index}
          >
            <ClayRadio label="Active" name="status" value="A" />
            <ClayRadio label="Decommissioned" name="status" value="D" />
            <ClayRadio label="DR/Failover/Swapped" name="status" value="F" />
          </ClayRadioGroup>
        );
      case "feedback":
        return (
          <>
            <ClaySelectWithOption
              aria-label={"Feedback"}
              id={`feedback ${index} ${value}`}
              className="feedback-width"
              value={isActiveRow ? "" : value}
              options={feedbackOptions}
              onChange={(e: any) => {
                onChange(e.target.value, rowData.serialNumber, index, "feedback");
              }}
              disabled={!!isActiveRow}
              key={value + index}
            />
            {!isActiveRow && value === "Others" && (
              <textarea
                placeholder={`${
                  value === "Others" ? "* Required " : "Add"
                } additional comments ...`}
                className="modal-feedback form-control"
                onChange={(e) => onChange(e.target.value, rowData.serialNumber, index, "comments")}
                value={rowData["comments"]}
              ></textarea>
            )}
          </>
        );
      case "datepicker":
        return (
          <DatePicker
            onChange={(e) => {
              onChange(e, rowData.serialNumber, index, "commDecommDate");
            }}
            value={datePickerVal ? new Date(datePickerVal) : null}
            minDate={getUTCTime(minDate)}
            maxDate={getUTCTime(maxDate)}
            format="MM-dd-yyyy"
            clearIcon={null}
            dayPlaceholder="DD"
            monthPlaceholder="MM"
            yearPlaceholder="YYYY"
            className="date-picker-sites"
          />
        );
      case "textarea":
        return (
          <textarea
            placeholder={`Enter comments for commission/ decommission date`}
            className="modal-feedback form-control"
            onChange={(e) =>
              onChange(e.target.value, rowData.serialNumber, index, "internalComments")
            }
            value={rowData.internalComments}
          ></textarea>
        );
    }
  };
  return <div>{renderComponent()}</div>;
}

export default SiteModalTableRenderer;
