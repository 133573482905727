import { apiCallBegan } from "@common-services/redux-helper/api-action-creator";
import { createSlice } from "@reduxjs/toolkit";

//import data from "../../../../../local-env.json";

const CONFIG_URL = "/config/";

const slice = createSlice({
  name: "initializeApp",
  initialState: {
    envVars: {},
    envVarsSet: false,
  },
  reducers: {
    envVarsListReceived(initializeApp, action) {
      if (action.payload) {
        initializeApp.envVars = setEnvVariables(action.payload);
        initializeApp.envVarsSet = true;
      }
    },
  },
});

export const { envVarsListReceived } = slice.actions;

// Set Variables dynamically
export const setEnvVariables = (vars) => {
  return {
    ...vars,
    OKTA_URL: vars.REACT_APP_OKTA_URL,
    OKTA_CLIENT_ID: vars.REACT_APP_OKTA_CLIENT_ID,
    DISABLE_OKTA: vars.REACT_APP_DISABLE_OKTA,
    REGISTRATION_URL: vars.REACT_APP_REGISTRATION_URL,
    ECX_PROFILE: vars.REACT_APP_ECX_PROFILE,
    USERS_ADMINISTRATION: vars.REACT_APP_ECX_USERS_ADMINISTRATION,
  };
};

// selectors
export const getEnvVars = (state) => state.initializeApp.envVars;
export const getEnvVarsSet = (state) => state.initializeApp.envVarsSet;

// reducer
export const initializeAppReducer = slice.reducer;

//API Call
export const updateEnvironmentVariables = () => (dispatch) => {
  // if (process.env.NODE_ENV === "development") {
  //   return dispatch(envVarsListReceived({ ...data }));
  // } else {
  return dispatch(
    apiCallBegan({
      url: CONFIG_URL,
      method: "GET",
      onSuccess: envVarsListReceived.type,
      skipErrorHandling: true,
    }),
  );
  // }
};
