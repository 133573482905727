export const FIRST_TIME_USER = [
  "You are the first user from your company that has requested access to Broadcom SCRT Report Management.",
  "Since you are the first user to request access to Broadcom SCRT Report Management from your company, you will be made an SCRT Account Administrator.",
  "For more information about what SCRT Account Administrators can do, see <a href='https://techdocs.broadcom.com/us/en/ca-mainframe-software/intelligent-operations/broadcom-scrt-report-management/1-0/administrating.html' rel='noopener noreferrer' target='_blank'>Administrating</a> in the Broadcom SCRT Report Management documentation.",
];

export const NOT_FIRST_TIME = [
  "Broadcom SCRT Report Management access is restricted to users that are approved by company's SCRT Administrator.",
  "Please complete this form to request access to the Broadcom SCRT Report Management website. The SCRT Account Administrators in the 'To' field will be notified of your request.",
];

export const enterprisePending = (envVars: any) => {
  return `Your Request to Broadcom SCRT Report website is pending approval. You can check the request status <a href=${envVars.ECX_PROFILE} target='_blank'>here.</a>`;
};

export const NOT_FIRST_TIME_ASA = [
  "You must be assigned to a Broadcom Support Site ID before you can submit, view, download, or delete Broadcom SCRT reports for that site. To request access to a site ID, complete the following form and select Send.",
];
