import { ClayRadio, ClayRadioGroup } from "@clayui/form";
import ClayIcon from "@clayui/icon";
import ClayLayout from "@clayui/layout";
import { claySpriteImagePath } from "@common-services/util/claySpriteImagePath";
import { IAlertMessage } from "@interfaces";
import {
  getReportUploadResponse,
  resetUploadReportResponse,
  uploadReport,
} from "@store/additionalMainframeReporting/slice";
import { getUserSiteDetails } from "@store/userDetails/slice";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Alert from "./../../../Alert";
import "./uploadReport.scss";

function UploadReport(props: any) {
  const { hanldeReloadListFlag } = props;
  const dispatch = useDispatch();
  const [uploadAlerts, setUploadAlerts] = useState([] as IAlertMessage[]);
  const userSiteDetails = useSelector(getUserSiteDetails);
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedSiteId, setSelectedSiteId] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();
  const reportUploadResponse = useSelector(getReportUploadResponse);
  const [disableUpload, setDisableUpload] = useState(false);
  useEffect(() => {
    if (userSiteDetails.length > 0) {
      if (userSiteDetails.length > 1 && userSiteDetails[0].siteId === "-1") {
        setSelectedSiteId(userSiteDetails[1].siteId);
        setSelectedCustomer(userSiteDetails[1].companyName);
      } else {
        setSelectedSiteId(userSiteDetails[0].siteId);
        setSelectedCustomer(userSiteDetails[0].companyName);
      }
      // Disable Upload if only one site Id -1 is mapped
      userSiteDetails.length === 1 && userSiteDetails[0].siteId === "-1"
        ? setDisableUpload(true)
        : setDisableUpload(false);
    }
  }, [userSiteDetails]);
  useEffect(() => {
    if (reportUploadResponse && reportUploadResponse.hasOwnProperty("success")) {
      setUploadAlerts([
        {
          type: reportUploadResponse.success ? "success" : "error",
          message: reportUploadResponse.success
            ? reportUploadResponse?.data?.message
            : reportUploadResponse.errors?.[0]?.details?.[0] ||
              reportUploadResponse.errors?.[0]?.errorMessage ||
              "Failed to upload the report. Contact your security administrator.",
        },
      ]);
      if (reportUploadResponse.success) {
        hanldeReloadListFlag(true);
      }
    }
  }, [reportUploadResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  // For drag/drop of reports
  const handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    handleFileUpload(event, "dragdrop");
  };
  const handleUploadAlertClose = () => {
    dispatch(resetUploadReportResponse());
    setUploadAlerts([]);
  };
  const handleFileUpload = (event: any, elementType = "") => {
    const fileObj =
      elementType === "input" ? event.currentTarget.files[0] : event.dataTransfer.files[0];
    if (!fileObj) {
      return;
    }
    const data = {
      additionalMainframeReport: fileObj,
      customerName: selectedCustomer,
      siteId: selectedSiteId,
    };

    if (uploadAlerts.length > 0) {
      handleUploadAlertClose();
    }
    dispatch(uploadReport(data));
  };
  return (
    <React.Fragment>
      <div className="pl-3 pr-3">
        {disableUpload && (
          <Alert report={{ type: "error", message: "Upload is disabled for Superadmin!" }} />
        )}
        {!disableUpload && (
          <div id="uploadPanel">
            <ClayLayout.Row justify="start">
              <ClayLayout.Col className={"d-flex align-items-center"}>
                <span>Select Site ID:</span>
                <ClayRadioGroup
                  inline={true}
                  defaultValue={selectedSiteId}
                  selectedValue={selectedSiteId}
                  onSelectedValueChange={(val: any) => {
                    if (uploadAlerts.length > 0) {
                      handleUploadAlertClose();
                    }
                    setSelectedSiteId(val);
                    setSelectedCustomer(
                      userSiteDetails.filter((obj: any) => obj.siteId === val)[0].companyName,
                    );
                  }}
                  className={"align-items-center"}
                >
                  {userSiteDetails &&
                    userSiteDetails
                      .filter((val: any) => val.siteId !== "-1")
                      .map((obj: any, index: any) => (
                        <ClayRadio
                          key={index}
                          label={`${obj.companyName} (${obj.siteId})`}
                          value={obj.siteId}
                        />
                      ))}
                </ClayRadioGroup>
              </ClayLayout.Col>
            </ClayLayout.Row>
            <hr />
            <ClayLayout.Row justify="start">
              <ClayLayout.Col className={"d-flex align-items-center pt-3"}>
                <div
                  className="autofit-col autofit-col-expand dropzone"
                  onDrop={(e) => handleDrop(e)}
                  onDragOver={(e) => handleDragOver(e)}
                  onDragEnter={(e) => handleDragEnter(e)}
                  onDragLeave={(e) => handleDragLeave(e)}
                >
                  <p className="list-group-title text-truncate d-flex draggable-container">
                    <label htmlFor="files" className="btn btn-primary">
                      <ClayIcon symbol="plus" spritemap={claySpriteImagePath()} />
                      &nbsp;Upload Report
                    </label>
                    <input
                      id="files"
                      className="d-none"
                      type="file"
                      name="file"
                      accept=".txt, .docx, .xlsx, .pdf"
                      onChange={(e) => handleFileUpload(e, "input")}
                      ref={inputRef}
                      onClick={() => {
                        if (uploadAlerts.length > 0) {
                          handleUploadAlertClose();
                        }
                        if (inputRef.current) {
                          inputRef.current.value = "";
                        }
                      }}
                    ></input>
                  </p>
                  <p className="list-group-subtitle text-truncate">Or drag your report here</p>
                  <div>
                    {uploadAlerts && uploadAlerts.length ? (
                      <div className="row">
                        {uploadAlerts.map((report: IAlertMessage, key: number) => {
                          return (
                            <div
                              key={key}
                              className={`${
                                uploadAlerts.length > 1 ? "col-md-6" : "col-md-12"
                              } col-sm-12`}
                            >
                              {report.type && report.message && (
                                <div className="c-mt-2" key={report.message}>
                                  <Alert handleClose={handleUploadAlertClose} report={report} />
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
              </ClayLayout.Col>
            </ClayLayout.Row>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
export default UploadReport;
