import React from "react";

import AutoComplete from "./controls/AutoComplete";
import Checkbox from "./controls/Checkbox";
import Date from "./controls/Date";
import DropdownWithCheckbox from "./controls/DropdownWithCheckbox";
import Email from "./controls/Email";
import Select from "./controls/Select";
import Tags from "./controls/Tags";
import Text from "./controls/Text";
import TextArea from "./controls/TextArea";
import TextLarge from "./controls/TextLarge";
import TextWithSearchIcon from "./controls/TextWithSearchIcon";

const FormControl = (props: any) => {
  const { control } = props;
  switch (control) {
    case "text":
      return <Text {...props} />;
    case "textarea":
      return <TextArea {...props} />;
    case "select":
      return <Select {...props} />;
    case "radio":
    case "checkbox":
      return <Checkbox {...props} />;
    case "date":
      return <Date {...props} />;
    case "autocomplete":
      return <AutoComplete {...props} />;
    case "tags":
      return <Tags {...props} />;
    case "textwithsearchicon":
      return <TextWithSearchIcon {...props} />;
    case "email":
      return <Email {...props} />;
    case "textLarge":
      return <TextLarge {...props} />;
    case "dropdownWithCheckbox":
      return <DropdownWithCheckbox {...props} />;
    default:
      return null;
  }
};

export { FormControl };
