import ClayCard from "@clayui/card";
import ClayLayout from "@clayui/layout";
import { PageTitle, Table } from "@common-components";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import TableCellRenderer from "@components/cellRenderer/TableCellRenderer";
import { ResetFilter } from "@components/resetFilter/ResetFilter";
import { DEFAULT_DELTA, ROLE_USER, ROUTE_PATHS } from "@constants";
import { ISiteDetails } from "@interfaces";
import {
  getSiteFilterState,
  getSiteList,
  getSiteObj,
  getSitePageState,
  getSiteSortState,
  handlePaginationState,
  loadSiteDetails,
  resetFilters,
  resetSiteState,
  siteListReceived,
} from "@store/siteDetails/slice";
import { GET_USER_ROLE } from "@utils";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const SiteList = (props: any) => {
  const pageTitle = "Sites";
  const dispatch = useDispatch();
  const data = useSelector(getSiteList);

  const sort = useSelector(getSiteSortState);
  const filterValue = useSelector(getSiteFilterState);
  const paginationInfo = useSelector(getSitePageState);
  const siteObj = useSelector(getSiteObj);

  const envVars = useSelector(getEnvVars);
  const isOktaEnabled = envVars.DISABLE_OKTA !== "true";
  const isValidUser = localStorage.getItem("okta-valid-user") || "";

  //Check if Valid user
  useEffect(() => {
    if (isOktaEnabled && isValidUser !== "true") {
      window.location.assign(ROUTE_PATHS.OKTA_REDIRECT);
    } else if (GET_USER_ROLE() === ROLE_USER) {
      window.location.href = ROUTE_PATHS.NO_ACCESS;
    }
    return () => {
      dispatch(resetSiteState());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newData: ISiteDetails[] = siteObj?.content?.map((row: ISiteDetails) => ({
      ...row,
      isChecked: false,
    }));
    dispatch(siteListReceived(newData));
    const newPageInfo = {
      activeDelta: siteObj?.size || DEFAULT_DELTA,
      activePage: siteObj?.number + 1 || 1,
      ellipsisBuffer: 5,
      totalItems: siteObj?.totalElements || 0,
    };
    dispatch(handlePaginationState(newPageInfo));
  }, [siteObj]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSiteData = (
    sortInfo: any = sort,
    filter: any = filterValue,
    pageInfo: any = paginationInfo,
  ) => {
    const sortState = sortInfo && sortInfo.column ? sortInfo : null;
    dispatch(loadSiteDetails(sortState, filter, pageInfo));
  };

  const handleFilter = (val: any) => {
    const newPagination = { ...paginationInfo, activePage: 1, activeDelta: DEFAULT_DELTA };
    getSiteData(null, val, newPagination);
  };

  const handleSort = (val: any) => {
    const newPagination = { ...paginationInfo, activePage: 1 };
    getSiteData(val, null, newPagination);
  };

  const handlePagination = (page: any, delta: number) => {
    const newPagination = { ...paginationInfo, activePage: page, activeDelta: delta };
    getSiteData(null, null, newPagination);
  };

  const handleResetFilter = () => {
    let isExistingFilter = false;

    // check if any filters are present
    if (Object.keys(filterValue).length === 0) {
      isExistingFilter = false;
    } else {
      for (let key in filterValue) {
        if (filterValue.hasOwnProperty(key)) {
          isExistingFilter = filterValue[key] !== "" ? true : false;
          break;
        }
      }
    }

    if (sort.column !== "" && sort.order !== "") {
      isExistingFilter = true;
    }

    // reset filters only if there are any filters
    if (isExistingFilter) {
      dispatch(resetFilters());
    }
  };

  const SITE_COLUMNS = [
    {
      headerName: "Site Name",
      field: "formattedSiteName",
      sortable: true,
      filter: { show: true },
    },
    {
      headerName: "Serial Number [Active]",
      field: "siteIdDetailsNotDecommissioned",
      sortable: true,
      filter: { show: true },
      cellRenderer: <TableCellRenderer fieldName="siteIdDetailsNotDecommissioned" type="list" />,
    },
    {
      headerName: "Serial Number [Decommissioned]",
      field: "siteIdDetailsDecommissioned",
      sortable: true,
      filter: { show: true },
      cellRenderer: <TableCellRenderer fieldName="siteIdDetailsDecommissioned" type="list" />,
    },
    {
      headerName: "Serial Number [DR/Failover/Swapped]",
      field: "siteIdDetailsFailed",
      sortable: true,
      filter: { show: true },
      cellRenderer: <TableCellRenderer fieldName="siteIdDetailsFailed" type="list" />,
    },
    {
      headerName: "Updated",
      field: "updatedDate",
      sortable: true,
      type: "datePicker",
      filter: { show: true, type: "date", range: true },
      cellRenderer: <TableCellRenderer fieldName="updatedDate" type="date" />,
    },
    {
      headerName: "Action",
      field: "action",
      sortable: false,
      filter: { show: true },
      filterRenderer: <ResetFilter handleResetFilter={handleResetFilter} />,
    },
  ];

  return (
    <div className="siteDetails ml-0">
      <ClayLayout.ContainerFluid view size={false}>
        <ClayLayout.Row justify="start" className="align-items-center">
          <ClayLayout.Col className="d-flex align-items-center">
            <PageTitle title={pageTitle} />
          </ClayLayout.Col>
        </ClayLayout.Row>

        <ClayLayout.Row>
          <ClayCard className="m-3 w-100">
            <ClayCard.Body className="p-0 pt-4">
              <Table
                definitions={SITE_COLUMNS}
                dataSource={data}
                borderedColumns={false}
                borderless={true}
                headVerticalAlignment={"middle"}
                headingNoWrap={true}
                noWrap={true}
                responsive={true}
                responsiveSize={"sm"}
                striped={true}
                tableVerticalAlignment={"middle"}
                handleFilter={handleFilter}
                filterValue={filterValue}
                sort={sort}
                handleSort={handleSort}
                pagination={paginationInfo}
                onActivePageChange={handlePagination}
                showPagination={true}
                entityLink={"/sites/:id"}
                entityId="siteId"
              />
            </ClayCard.Body>
          </ClayCard>
        </ClayLayout.Row>
      </ClayLayout.ContainerFluid>
    </div>
  );
};

export { SiteList };
