import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "globalApiLoader",
  initialState: {
    isLoading: false,
    activeCalls: 0,
  },
  reducers: {
    startLoading: (globalApiLoader) => {
      globalApiLoader.isLoading = true;
      ++globalApiLoader.activeCalls;
    },
    stopLoading: (globalApiLoader) => {
      if (globalApiLoader.activeCalls > 0) {
        --globalApiLoader.activeCalls;
      }
      if (!globalApiLoader.activeCalls) {
        globalApiLoader.isLoading = false;
      }
    },
  },
});

export const { startLoading, stopLoading } = slice.actions;

export const getLoderStatus = (state: any) => state.globalApiLoader.isLoading;

export const globalAPILoaderReducer = slice.reducer;
