import { BASEPATH } from "../../utils/constants/constants";

export const MANAGE_URL = {
  GET_EMAIL_LIST: `${BASEPATH}/reports/search`,
  SEND_EMAIL: `${BASEPATH}/reports/sendmail`,
  GET_USER_LIST: `${BASEPATH}/admin/get-user-list`,
  GET_CUSTOMER_LIST: `${BASEPATH}/reports/mcl/customer-numbers`,
  UPDATE_CUSTOMER: `${BASEPATH}/reports/mcl/site-id`,
  GET_EXISTING_CUSTOMER_LIST: `${BASEPATH}/reports/mcl/mappedCustomerNumbers`,
  UPDATE_EXISTING_CUSTOMER_LIST: `${BASEPATH}/reports/mcl/updateSiteId`,
  GET_ENTERPRISES: `${BASEPATH}/reports/get-enterprises`,
  CHECK_BILLING_NON_BILL: `${BASEPATH}/reports/mcl/billingNonBilling`,
  AUDIT_CUSTOMER_LIST: `${BASEPATH}/reports/mcl/audit-customer-numbers`,
  UPDATE_AUDIT_LIST: `${BASEPATH}/reports/mcl/audit-site-id`,
};
