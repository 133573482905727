import ClayButton from "@clayui/button";
import ClayIcon from "@clayui/icon";
import ClayModal, { useModal } from "@clayui/modal";
import { claySpriteImagePath } from "@common-services/util/claySpriteImagePath";

export function ConfirmSendModal(props: any) {
  const { modalClose, confirmSend, body, closeText = "", confirmText = "" } = props;
  const { observer } = useModal({
    onClose: () => modalClose(),
  });

  return (
    <ClayModal
      observer={observer}
      spritemap={claySpriteImagePath()}
      disableAutoClose={true}
      status="info"
    >
      <ClayModal.Header>Confirmation</ClayModal.Header>
      <ClayModal.Body dangerouslySetInnerHTML={{ __html: body }}></ClayModal.Body>
      <ClayModal.Footer
        last={
          <ClayButton.Group spaced>
            <ClayButton onClick={() => modalClose()} borderless>
              <ClayIcon symbol="times" spritemap={claySpriteImagePath()} />
              &nbsp;{`${closeText || "No"}`}
            </ClayButton>
            <ClayButton onClick={() => confirmSend()} borderless>
              <ClayIcon symbol="check" spritemap={claySpriteImagePath()} />
              &nbsp;{`${confirmText || "Yes"}`}
            </ClayButton>
          </ClayButton.Group>
        }
      />
    </ClayModal>
  );
}
