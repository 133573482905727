import { Header } from "@common-components";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import Home from "@components/home/Home";
import PageNotFound from "@components/pageNotFound/PageNotFound";
import { ROUTE_PATHS, checkIfSCRTAccess, routesConfig } from "@constants";
import { IRoute } from "@interfaces";
import {
  getAuthToken,
  isECXRedirect,
  isLoginRedirect,
  isLogoutRedirect,
  isNoAccessRedirect,
  isOktaRedirect,
  isRepoLogsRedirect,
} from "@utils";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, useLocation } from "react-router-dom";

// Returns Secure Routes
function OktaAuthentication() {
  const envVars = useSelector(getEnvVars);

  const SecureRoutes = routesConfig.map((route: IRoute, index: number) => (
    <Route path={route.path} key={index} exact component={route.component} />
  ));

  const location = useLocation();
  let hideNavbar =
    isECXRedirect(location.pathname) ||
    isOktaRedirect(location.pathname) ||
    isNoAccessRedirect(location.pathname) ||
    isLoginRedirect(location.pathname) ||
    isLogoutRedirect(location.pathname);
  if (!checkIfSCRTAccess()) {
    hideNavbar = !hideNavbar ? isRepoLogsRedirect(location.pathname) : true;
  }

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    window.location.href = originalUri || ROUTE_PATHS.OKTA_REDIRECT;
  };

  useEffect(() => {
    if (location.pathname === "/" && !getAuthToken()) {
      localStorage.clear();
      sessionStorage.clear();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BrowserRouter>
      <Header showNavbar={!hideNavbar} />
      <Route path={ROUTE_PATHS.HOME} exact={true} component={Home} />
      {SecureRoutes}
      <Route component={PageNotFound} />
    </BrowserRouter>
  );
}

export default OktaAuthentication;
