import ClayButton from "@clayui/button";
import React from "react";

function TableFilterCheckbox(props: any) {
  const { handleAllCheckboxChange, isSelectAll } = props;
  return (
    <ClayButton
      type="submit"
      className="ml-2 scrt-table-select-all"
      displayType={"secondary"}
      style={{ fontSize: "small" }}
      onClick={() => {
        handleAllCheckboxChange(!isSelectAll);
      }}
    >
      {isSelectAll ? "Deselect All" : "Select All"}
    </ClayButton>
  );
}

export { TableFilterCheckbox };
