import { saveAs } from "file-saver";

export const CRUSH_FTP_DOWNLOAD = (fileName: string, isSingleFileOrder: string, response: any) => {
  const getFilename = (response: any) => {
    let filename = "";
    const disposition = response.headers["content-disposition"];
    if (disposition && disposition.indexOf("attachment") !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }

    return filename;
  };

  const downloadFile = () => {
    const blob = new Blob([response.data], { type: "application/octet-stream; charset=UTF-8" });
    const filename = isSingleFileOrder
      ? fileName
        ? fileName
        : getFilename(response)
      : `scrt_reports.zip`;
    saveAs(blob, filename);
  };

  downloadFile();
};
