import ClayForm, { ClayInput } from "@clayui/form";
import { claySpriteImagePath } from "@common-services/util/claySpriteImagePath";
import { ErrorMessage, Field } from "formik";
import React from "react";

import TextError from "./TextError";

function Text(props: any) {
  const {
    label,
    id,
    name,
    placeholder,
    validate,
    required,
    size = "sm",
    hasSuccess,
    infoMsg,
    infoShow,
    onTypeShow = false,
  } = props;
  return (
    <div>
      {label && (
        <label htmlFor={name}>
          {required && <span className="text-red">* </span>}
          {label}
        </label>
      )}
      <Field name={name} validate={validate}>
        {({ form, field }: { form: any; field: any }) => {
          const { value } = field;
          return (
            <ClayForm.Group
              className={`form-group-${size} ${
                form.errors[name] && form.touched[name] !== undefined
                  ? "has-error"
                  : hasSuccess
                  ? "has-success"
                  : infoShow
                  ? "has-error"
                  : ""
              }`}
            >
              <ClayInput
                type="text"
                id={id}
                value={value}
                placeholder={placeholder || label}
                {...field}
                {...props}
              />
              {!onTypeShow && <ErrorMessage name={name} component={TextError} />}
              {onTypeShow && infoShow ? (
                hasSuccess ? (
                  <div className="form-feedback-item">
                    <span className="form-feedback-indicator inline-item-before">
                      <ClayForm.FeedbackIndicator
                        spritemap={claySpriteImagePath()}
                        symbol="check-circle-full"
                      />
                    </span>
                    {infoShow && infoMsg}
                  </div>
                ) : (
                  <div className="form-feedback-item">
                    <span className="form-feedback-indicator inline-item-before">
                      <ClayForm.FeedbackIndicator
                        spritemap={claySpriteImagePath()}
                        symbol="exclamation-full"
                      />
                    </span>
                    {infoShow && infoMsg}
                  </div>
                )
              ) : null}
            </ClayForm.Group>
          );
        }}
      </Field>
    </div>
  );
}

export default Text;
