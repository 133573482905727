import ClayButton from "@clayui/button";
import ClayIcon from "@clayui/icon";
import ClayModal, { useModal } from "@clayui/modal";
import React from "react";

import claySpriteImagePath from "./../../../common-ui-modules/images/clay/sprite.svg";

const CustomTooltip = (props: any) => {
  const { data } = props;

  if (data) {
    return (
      <div
        style={{
          display: "block",
          background: "#FFFFFF",
          border: "1px solid grey",
          padding: "10px",
        }}
      >
        <p>
          <b>{data.headerName}</b>
        </p>
        {/* <p
          style={{ color: "#015c8a" }}
          dangerouslySetInnerHTML={{
            __html: `<b>Baseline :</b> ${data.baselineStringValue}`,
          }}
        ></p> */}
        <p>
          <span style={{ color: "#c3c3c3" }}>
            <b>Total Usage : </b>
          </span>
          <span
            style={{
              color: `${data.actualConsumptionStringValue === "-" ? "#ff0000" : "#c3c3c3"}`,
            }}
          >
            {data.actualConsumptionStringValue === "-"
              ? "NO SCRT"
              : data.actualConsumptionStringValue}
          </span>
        </p>
        <p
          style={{
            wordWrap: "break-word",
          }}
          dangerouslySetInnerHTML={{
            __html: `<b>Excluded LPAR(s) on record: </b> ${data.lpars}`,
          }}
        ></p>
      </div>
    );
  }

  return null;
};
function OverviewModal(props: any) {
  const { observer } = useModal({
    onClose: () => "",
  });

  const { modalData, onModalClose } = props;

  return (
    <div>
      {modalData.isOpen && (
        <>
          <ClayModal
            observer={observer}
            size="full-screen"
            spritemap={claySpriteImagePath}
            disableAutoClose={true}
          >
            <ClayModal.Body>
              <CustomTooltip data={modalData.data} />
            </ClayModal.Body>
            <ClayModal.Footer
              last={
                <ClayButton.Group spaced>
                  <ClayButton displayType="secondary" onClick={() => onModalClose()}>
                    <ClayIcon symbol="times" spritemap={claySpriteImagePath} />
                    &nbsp;{"Close"}
                  </ClayButton>
                </ClayButton.Group>
              }
            />
          </ClayModal>
        </>
      )}
    </div>
  );
}

export default OverviewModal;
