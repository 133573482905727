import ClayTable from "@clayui/table";
import React from "react";

import { TableBodyRow } from "./table-body-row/TableBodyRow";

const TableBody = (props: any) => {
  const {
    info,
    resultSet,
    currentPage,
    uniqueKeyProp,
    showCheckbox,
    handleCheckboxChange,
    entityLink,
    entityId,
    rowClassName,
  } = props;

  return (
    <ClayTable.Body>
      {resultSet.map((rowData: any, idx: number) => (
        <TableBodyRow
          rowClassName={rowClassName}
          key={uniqueKeyProp ? rowData[uniqueKeyProp] : idx + currentPage}
          index={idx + currentPage}
          rowData={rowData}
          definitions={info}
          currentPage={currentPage}
          showCheckbox={showCheckbox}
          handleCheckboxChange={handleCheckboxChange}
          entityLink={entityLink}
          entityId={entityId}
        />
      ))}
    </ClayTable.Body>
  );
};

export { TableBody };
