import ClayButton from "@clayui/button";
import ClayIcon from "@clayui/icon";
import Sprite from "@common-images/clay/sprite.svg";

const ResetFilter = (props: any) => {
  const { handleResetFilter } = props;

  return (
    <div className="d-flex">
      <ClayButton displayType="secondary" onClick={() => handleResetFilter()}>
        <span className="inline-item inline-item-before">
          <ClayIcon spritemap={Sprite} symbol="reset" />
        </span>
        {"Clear Filters"}
      </ClayButton>
    </div>
  );
};

export { ResetFilter };
