import ClayCard from "@clayui/card";
import ClayLayout from "@clayui/layout";
import { Table } from "@common-components";
import TableCellRenderer from "@components/cellRenderer/TableCellRenderer";
import { ROLE_USER, ROUTE_PATHS } from "@constants";
import { getSiteUsers, handleSortState, loadSiteUsers } from "@store/siteDetails/slice";
import { GET_USER_ROLE } from "@utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

function SitesUser(props: any) {
  const { siteId } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState<any[]>([]);
  const [sort, setSort] = useState({ column: "", order: "" });

  useEffect(() => {
    if (GET_USER_ROLE() === ROLE_USER) {
      window.location.href = ROUTE_PATHS.NO_ACCESS;
    }
    dispatch(loadSiteUsers(siteId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const rowData = useSelector(getSiteUsers);

  useEffect(() => {
    setData(rowData);
  }, [rowData]);

  const handleSort = (val: any) => {
    setSort(val);
    // modify as single dispatch
    dispatch(handleSortState(val));
    dispatch(loadSiteUsers(siteId));
  };

  const USER_COLUMNS = [
    {
      headerName: "First Name",
      field: "firstName",
      sortable: false,
    },
    { headerName: "Last Name", field: "lastName", sortable: false },
    { headerName: "Email", field: "userId", sortable: false },
    {
      headerName: "Scrt Access",
      field: "isScrtAccess",
      sortable: false,
      cellRenderer: <TableCellRenderer fieldName="isScrtAccess" type="active" />,
    },
    {
      headerName: "Admin Access",
      field: "isScrtAdmin",
      sortable: false,
      cellRenderer: <TableCellRenderer fieldName="isScrtAdmin" type="active" />,
    },
  ];

  return (
    <React.Fragment>
      <ClayLayout.Row>
        <ClayCard className="m-3 w-100">
          <ClayCard.Body className="p-4 overflow-users">
            <Table
              rowClassName="users-table"
              definitions={USER_COLUMNS}
              dataSource={data}
              borderedColumns={false}
              borderless={true}
              headVerticalAlignment={"middle"}
              headingNoWrap={true}
              hover={false}
              noWrap={true}
              responsive={true}
              responsiveSize={"sm"}
              striped={true}
              hideFilter={true}
              tableVerticalAlignment={"middle"}
              sort={sort}
              handleSort={handleSort}
            />
          </ClayCard.Body>
        </ClayCard>
      </ClayLayout.Row>
    </React.Fragment>
  );
}
export default SitesUser;
