function SkeletonLoader() {
  return (
    <div className="skeleton-wrapper" id="portlet-skeleton-loader">
      <div className="row align-items-center">
        <div className="skeleton-title skeleton-shape-animate"></div>
        <div className="skeleton-button skeleton-shape-animate ml-auto"></div>
      </div>

      <div className="row align-items-center mt-4">
        <div className="col-3 px-0 d-flex">
          <div className="skeleton-tab-title skeleton-shape-animate"></div>
          <div className="skeleton-tab-title skeleton-shape-animate"></div>
        </div>
        <div className="col-9 d-flex justify-content-end pr-0">
          <div className="skeleton-control skeleton-shape-animate"></div>
          <div className="skeleton-control skeleton-shape-animate"></div>
          <div className="skeleton-control skeleton-shape-animate"></div>
          <div className="skeleton-control skeleton-shape-animate"></div>
          <div className="skeleton-control skeleton-shape-animate mr-0"></div>
        </div>
      </div>

      <div className="row mt-4 px-0">
        <div className="col-4 pl-0">
          <div className="col-12 pl-0 skeleton-card skeleton-shape-animate"></div>
        </div>
        <div className="col-4">
          <div className="col-12 skeleton-card skeleton-shape-animate"></div>
        </div>
        <div className="col-4 pr-0">
          <div className="col-12 pr-0 skeleton-card skeleton-shape-animate"></div>
        </div>
      </div>
    </div>
  );
}

export default SkeletonLoader;
