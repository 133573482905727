import authToken from "@common-services/util/authToken";
import axios from "axios";

import { startLoading, stopLoading } from "../store/slice/globalAPILoader.slice";
import { showAlert } from "../store/slice/globalAlertHandler.slice";

let dispatch: any = null;

export const axiosApi = (action: any) => {
  const { method, headers, hideLoader, skipErrorHandling, errorConfig } = action;
  const { url, data } = action;
  if (!hideLoader) {
    dispatch(startLoading());
  }

  const errorHandler = (message: string, additionalInfo: any) => {
    if (skipErrorHandling) {
      return;
    }

    dispatch(
      showAlert({
        timeout: 5000,
        message,
        additionalInfo,
        ...errorConfig,
      }),
    );
  };

  const authHeaders = authToken(url, data, method);
  const reqHeaders = { ...headers, ...authHeaders };

  return axios
    .request({
      url,
      method,
      data,
      headers: reqHeaders,
      responseType: action.responseType ? action.responseType : "",
    })
    .then((response) => {
      // Specific
      if (!hideLoader) {
        dispatch(stopLoading());
      }

      if (!skipErrorHandling) {
        //error handling
        let payload;
        if (response && response.data) {
          payload = response.data;
        }

        if (!payload) {
          errorHandler("Something went wrong, Please try again later", null);
        } else if (!payload.success) {
          let message = "";
          if (payload.errors && payload.errors.length) {
            payload.errors.forEach((error: any) => {
              message += error.errorMessage || error.message;
              message += "\n";
            });
          } else {
            message = "Something went wrong, please try again!";
          }

          errorHandler(message, payload.correlationId);
        }
        //error handling end
      }

      return response;
    })
    .catch((error) => {
      if (!hideLoader) {
        dispatch(stopLoading());
      }
      errorHandler(error.message || "Something went wrong, Please try again later", null);
      throw error;
    });
};

//should be called by the store configurer, by passing in the store
export const configureAxiosApi = (storeConfig: any) => {
  dispatch = storeConfig.dispatch;
};
